import React from "react";
import logo from "../../Images/logo_green_transparent.png";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  InvoiceDeclartion,
  InvoicSubject,
  InvoiceBilltyNote,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  Address,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import Loader from "./Loader";
// import InvoicePrint from "../../Components/UI/InvoicePrint";
import moment from "moment";

const InvoiceView = (props) => {
  return (
    <>
      <div style={{ minWidth: "1240px" }} className="">
        {props.data.createdAt ? (
          <div className="m-4  border-double border-4 border-cyan-200 block">
            <div className="flex  flex-row justify-between font-bold sm:mx-8 mx-2 mb-3 text-xs">
              <h5>{InvoicSubject}</h5>
              <h5>{InvoiceDeclartion}</h5>
            </div>
            {/* Logo and Title Start */}
            <div className="m-2 items-center space-x-3 flex flex-row">
              <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                <div className="flex flex-row justify-between">
                  <div className="w-80 ml-2">
                    <img src={logo} alt="Kirti Roadlines" />
                  </div>
                  <div className=" flex flex-col ">
                    <span className="text-9xl text-primary-kirtiroadways font-extrabold ">
                      {props.profileData.tagLine}
                    </span>
                    <span className="text-4xl font-serif text-black">
                      {props.profileData.subTagLine}
                    </span>
                  </div>
                </div>
                {/* Address Start */}
                <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                  {props.profileData.address !== " " && (
                    <p className="flex  text-md justify-center flex-row items-start">
                      <span className="text-md w-5">{address_svg}</span>{" "}
                      <span className="text-md ml-1">
                        {props.profileData.address}
                      </span>
                    </p>
                  )}
                  <div className="  flex space-x-4 justify-center flex-row items-start ">
                    {(props.secondaryPhoneNo.length) !== 0 && (
                      <div className="flex space-x-1 text-md justify-center flex-row items-center ">
                        <span>{call_svg} </span>{" "}
                        <span className="flex flex-row">
                          {props.secondaryPhoneNo.map((number, i) => (
                            <span
                              key={number}
                              className="text-sm font-semibold tracking-wider flex flex-row"
                            >
                              {(i ? ", " : "") + number}
                            </span>
                          ))}
                        </span>
                      </div>
                    )}
                    {props.profileData.email !== " " && (
                      <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                        <span>{email_svg} </span>
                        <span>{props.profileData.email}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Address End */}
              </div>
            </div>
            {/* Logo and Title End */}

            <div className="font-md flex flex-row p-4">
              <div
                className="
                shadow-sm
                border 
                flex
                flex-row rounded-x-md w-full divide-x border-neutral-400 divide-neutral-400"
              >
                <div className="w-1/3 p-2">
                  <h1 className="font-bold underline text-lg">{CAUTION}</h1>
                  <ul className="text-justify font-serif text-xs ml-5 mr-5 list-outside">
                    <li className="mt-2">{CAUTIONContnet}</li>
                    <li>{receiptDeclaration}</li>
                  </ul>
                </div>

                <div className="w-2/3 flex flex-col divide-y border-neutral-400 divide-neutral-400 ">
                  <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                    <div className="w-1/2 self-center font-bold text-lg">
                      <h1 className="underline text-center font-bold text-lg">
                        {PANNo} {props?.data?.pan}
                      </h1>
                      <div className="self-center font-bold text-lg">
                        {props.copyFor} COPY
                      </div>
                    </div>
                    <div className="w-1/2">
                      <h1 className="font-bold text-lg underline">
                        {OwnerRiskInsurance}
                      </h1>
                      <h4 className="text-xs">{CustomerDeclaration}</h4>
                    </div>
                  </div>

                  <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                    <div className="w-1/2 self-center font-bold text-lg">
                      <h1 className="underline font-bold text-lg text-primary-kirtiroadways">
                        {CONSIGNMENTNOTE}
                      </h1>
                    </div>
                    <div className="w-1/2 text-left text-md">
                      <ul className="divide-y divide-neutral-400 text-md">
                        <li className="p-2 text-md">
                          {CompanyName} :{""}
                          {/* {props.profileData.companyName.toUpperCase()} */}
                        </li>
                        <li className="p-2 text-md">
                          Policy No : {""}
                          {/* {props.profileData.policyNo} */}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="h-4/5 flex flex-row divide-x  border-neutral-400 divide-neutral-400">
                    <div className="w-1/2">
                      <div className="w-full ">
                        <table className="border-collapse text-base w-full">
                          <thead>
                            <tr>
                              <td className="w-1/2 p-2">
                                <span className="float-left">Fin. Year :</span>
                                <span className="font-bold">
                                  {props?.data?.financialYear}
                                </span>
                              </td>
                              <td className="border-l border-neutral-400 p-2">
                                <span className="float-left">LR No. :</span>
                                <span className="font-bold">
                                  {props?.data?.lrNo}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-t border-neutral-400 p-2">
                                <span className="float-left">LR Date : </span>
                                <span className="font-bold">
                                  {/* {props?.data?.lrDate
                                    ? props?.data?.lrDate.split("T")[0]
                                    : props?.data?.loadingDate.split("T")[0]} */}

                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : " "}
                                </span>
                              </td>
                              <td className="border-l border-t border-neutral-400 p-2">
                                <span className="float-left">Lorry No. :</span>
                                <span className="font-bold">
                                  {props?.data?.vehicleNo}
                                </span>
                              </td>
                            </tr>
                            <tr className="border-t border-neutral-400 text-left">
                              <td colSpan={2} className="p-2">
                                <span className="float-left">
                                  Delivery At. :
                                </span>
                                <span className="font-bold pl-1">
                                  {props?.data?.to}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="w-1/2">
                      <div className="w-full ">
                        <table className="border-collapse text-base w-full">
                          <thead>
                            <tr>
                              <td
                                className="border-y border-t-0 border-neutral-400 p-2"
                                colSpan="2"
                              >
                                <span className="float-left">
                                  Date :{" "}
                                  {/* {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]} */}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                className="w-1/2 border-neutral-400 p-2"
                              >
                                <span className="float-left">
                                  Amount :{" "}
                                  {/* {props?.data?.amountToPayOrPaid === null
                                    ? ""
                                    : props?.data?.amountToPayOrPaid} */}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              {/* <td className="w-1/2 border-t border-r border-neutral-400 p-2">
                                <span className="float-left">
                                  Date :{" "}
                                  {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]}
                                </span>
                              </td>
                              <td className="border-t  border-neutral-400 p-2">
                                <span className="float-left">
                                  Amount :{" "}
                                  {props?.data?.amountToPayOrPaid === null
                                    ? ""
                                    : props?.data?.amountToPayOrPaid}
                                </span>
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Consignore and consignee Start*/}
            <div
              className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
            >
              <table className="border-collapse text-base">
                <tbody>
                  <tr>
                    <td className="w-1/2  border-b border-r border-neutral-400 p-1 text-lg">
                      <span className="float-left font-bold">
                        <span className="text-primary-kirtiroadways font-bold ">
                          Consignor's Name :
                        </span>{" "}
                        {props?.data?.consignerName}
                      </span>
                    </td>
                    <td className="w-1/2 font-bold border-b border-neutral-400 p-1 text-lg">
                      <span className="float-left">
                        <span className="text-primary-kirtiroadways">
                          Consignee's Name :
                        </span>{" "}
                        {props?.data?.consigneeName}
                      </span>
                    </td>
                  </tr>

                  <tr className="align-top text-left h-20">
                    <td className="w-1/2  border-b border-r border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">{Address}</span>{" "}
                        {props?.data?.consignerAddress}
                      </span>
                    </td>
                    <td className="w-1/2 border-b align-top text-left border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">{Address}</span>{" "}
                        {props?.data?.consigneeAddress}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-1/2 border-r border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">GST No :</span>{" "}
                        {props?.data?.consignerGst === null
                          ? "NA"
                          : props?.data?.consignerGst.toUpperCase()}
                      </span>
                    </td>
                    <td className="w-1/2 p-1">
                      <span className="float-left">
                        <span className="">GST No :</span>{" "}
                        {props?.data?.consigneeGst === null
                          ? "NA"
                          : props?.data?.consigneeGst.toUpperCase()}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Consignore and consignee End*/}

            {/* from and to Start*/}
            <div
              className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
            >
              <table className="border-collapse text-base">
                <tbody>
                  <tr>
                    <td className="w-1/2 border-r border-neutral-400 p-2">
                      <span className="float-left font-bold">
                        <span className="text-primary-kirtiroadways font-bold ">
                          From:-
                        </span>{" "}
                        {props?.data?.from}
                      </span>
                    </td>
                    <td className="w-1/2 font-bold p-1">
                      <span className="float-left">
                        <span className="text-primary-kirtiroadways">To:-</span>{" "}
                        {props?.data?.to}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* from and to End*/}

            {/* Table */}
            <div className=" mx-4 mt-4">
              {/* Table Start */}
              <table className="w-full">
                <thead>
                  <tr className="border ">
                    <th className="border-2 border-neutral-300">Packages</th>
                    <th className="border-2  border-neutral-300">
                      Description (said to contains)
                    </th>
                    <th className="border-2 border-neutral-300">
                      <h1 className="border-b-2 border-neutral-300">Weight</h1>
                      <div className="w-full  flex flex-row justify-evenly text-center">
                        <h1>Actual</h1>
                        <h1>Charged</h1>
                      </div>
                    </th>
                    <th className="border-2 border-neutral-300">Rate</th>
                    <th className="border-2 border-neutral-300">
                      <h1 className="border-b-2 border-neutral-300">
                        Amount to Pay/Paid
                      </h1>
                      <h1>Rs.</h1>
                    </th>
                  </tr>
                </thead>
                <tbody className="align-top font-light text-center">
                  <tr className="border-2 font-light border-neutral-300">
                    <td
                      rowSpan="2"
                      className="border-2 font-semibold border-neutral-300 items-center"
                    >
                      {props?.data?.packages !== null && (
                        <>
                          <h1 className="">{props?.data?.packages}</h1>
                          {/* <h1 className="">NOS</h1> */}
                        </>
                      )}
                    </td>
                    <td className="font-semibold flex flex-col space-y-24 align-middle">
                      <h1 className="font-semibold">
                        {props?.data?.description}
                      </h1>
                      <h1 className="font-semibold">AS PER INVOICE</h1>
                    </td>
                    <td className="border-2 border-neutral-300">
                      <div className="flex flex-col space-y-8">
                        <div className="flex flex-row justify-evenly">
                          <div>
                            <h1 className="font-semibold">
                              {props?.data?.actualWeight}{" "}
                              {props?.data?.weightType}
                            </h1>
                          </div>
                          <div>
                            <h1 className="font-semibold">
                              {props?.data?.chargedWeight}{" "}
                              {props?.data?.weightType}
                            </h1>
                          </div>
                        </div>
                        <div className="font-semibold space-y-8">
                          <h1 className="font-semibold border-y-2 border-neutral-300">
                            Company Invoice No.
                          </h1>
                          <div className="font-semibold mt-4">
                            {props.index === 0
                              ? props?.data?.invoiceNo.toUpperCase()
                              : " "}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="flex flex-col space-y-4">
                      <h1 className="font-semibold">Freight</h1>
                      <h1 className="font-semibold">Sur. Ch.</h1>
                      <h1 className="font-semibold">St. Ch.</h1>
                      <h1 className="font-semibold">Risk Ch.</h1>
                    </td>
                    <td className="border-2 border-neutral-300 text-black">
                      <div className=" mt-14 font-semibold flex flex-row justify-center items-center">
                        {props?.information.toUpperCase() ||
                          props?.data?.amountToPayOrPaid}
                        {/* {props?.data?.amountToPayOrPaid === null
                               ? "NA"
                          : props?.data?.amountToPayOrPaid} */}
                      </div>
                      {/* <div className="uppercase pt-16 font-semibold  bg-red self-bottom">
                          To Pay {props?.data?.to}
                        </div> */}
                      {/* <h1 className="uppercase font-semibold absolute bottom-0">
                      TO PAY {props?.data?.to}
                    </h1> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold border-t-2 text-left border-b-2 border-neutral-300">
                      <h1 className="ml-2">Private Marks:</h1>
                    </td>
                    <td className="border-x-2 border-b-2 border-neutral-300">
                      <h1 className="font-semibold">
                        {props?.data?.privateMarks}
                      </h1>
                    </td>
                    <td className=" text-md border-r-2 border-y-2  border-neutral-300 text-center justify-center  font-bold bg-primary-kirtiroadways text-white">
                      <h1>TOTAL</h1>
                    </td>
                    <td className="border-r-2 border-b-2 border-neutral-300 font-mono text-md whitespace-nowrap	 text-center justify-center  font-bold bg-primary-kirtiroadways text-white">
                      <h1>&#8377; {props?.data?.total}</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="w-full flex flex-row justify-center mt-2">
                <div className="font-bold text-xs border-b-0 border w-full border-neutral-400">
                  <span>To Pay :</span> <span>{props?.data?.paidBy}</span>
                </div>
              </div>
              <div className="font-bold text-xs border border-neutral-400">
                <h1>{InvoiceBilltyNote}</h1>
                <h1>{CompanyResponsible}</h1>
              </div>
            </div>

            {/* Table End */}
            <div className="mx-6 mt-5">
              {/* bottom left and right start */}
              <div className="flex flex-row space-y-0 justify-between text-left">
                <div className="max-w-xl">
                  <h1 className="font-bold underline border-neutral-400  text-sm">
                    {TermsandCondition}
                  </h1>
                  <h6 className="	text-xs text-justify">
                    {TermsandConditionNote}
                  </h6>
                </div>
                <div className="font-semibold text-sm text-right">
                  <h3>Value: AS PER INVOICE</h3>
                  <h3>{ServiceTaxLiabilityConsignor}</h3>
                  <h3 className="mt-8 pt-4">For {props.profileData.tagLine}</h3>
                </div>
              </div>
              {/* bottom left and right end */}

              {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
            </div>

            <div className="border-t-0 border-neutral-400 font-serif	bg-primary-kirtiroadways py-1 text-white  w-full mt-2 font-bold rounded-md">
              <h6>{THANKYOUFORBUSSINESSWITHUS}</h6>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default InvoiceView;
