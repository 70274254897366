import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment,  useState } from "react";
import axiosInstance from "../../Utils/API";
import {
  DELETE_BOOKING,
  DELETE_COMPANY,
  DELETE_TRANSPORTER,
  DELETE_VEHICLE_OWNER,
  DELETE_COMMISSION,
} from "../../Utils/ApiGlobal";
import { REMOVE_CONFORM } from "../../Utils/Constants";
import { delete_svg } from "../../Utils/SVGListing";
import APILoader from "./APILoader";
import ButtonWithBG from "./ButtonMain";
import Button from "./ButtonMain";
import { ConfirormationModal } from "./ConfirormationModal";
// import Notification from "./Notification";

export default function Modal(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // const [notif, setNotif] = useState(false);
  // const [notifOpts, setNotifOpts] = useState({});

  const openModal = (props) => {
    setIsOpen(true);
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };


  const handleRemoveBtn = () => {
    // handle all the action in the main view
    setLoader({ ...loader, loading: true });
    let API_CALL;
    let REMOVE_ID = {};
    if (localStorage.getItem("component") === "Booking") {
      API_CALL = DELETE_BOOKING;
      REMOVE_ID = { BookingId: localStorage.getItem("id") };
    } else if (localStorage.getItem("component") === "Company") {
      API_CALL = DELETE_COMPANY;
      REMOVE_ID = { CompanyId: localStorage.getItem("id") };
    } else if (localStorage.getItem("component") === "Transporter") {
      API_CALL = DELETE_TRANSPORTER;
      REMOVE_ID = { TransporterId: localStorage.getItem("id") };
    } else if (localStorage.getItem("component") === "Commission") {
      API_CALL = DELETE_COMMISSION;
      REMOVE_ID = { CommissionId: localStorage.getItem("id") };
    } else if (localStorage.getItem("component") === "Vehicle Owner") {
      API_CALL = DELETE_VEHICLE_OWNER;
      REMOVE_ID = { OwnerId: localStorage.getItem("id") };
    }
    axiosInstance
      .post(API_CALL, REMOVE_ID)
      .then((response) => {
        setLoader({
          loading: false,
          message: response.data.data.message,
          icon: "success",
        });
        ConfirormationModal(response.data.data.message, "success", 2000);
        props.data();
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
        props.data();
      });
  };

  return (
    <>
      <ButtonWithBG
        tooltipContent={
          props.component ? `${props.component} Remove` : "Remove"
        }
        onClick={openModal}
        theme="secondary"
        svg={delete_svg}
      ></ButtonWithBG>
      <APILoader loader={loader} />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30  flex justify-center  items-center bg-gray-100 bg-opacity-75 rounded-lg shadow-md"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen ml-4 px-4 text-center">
            <Dialog.Overlay className="fixed inset-0" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {/* <Notification
              show={notif}
              setShow={setNotif}
              {...notifOpts}
            ></Notification> */}
            <div className="border-2 border-primary-kirtiroadways inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900 mb-4"
              >
                {REMOVE_CONFORM} {props.title} ?
              </Dialog.Title>

              <div className=" mt-4 space-x-2 flex justify-center">
                <Button onClick={handleRemoveBtn} theme={`primary`}>
                  Remove
                </Button>
                <Button onClick={handleButtonClick} theme="secondary">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          {/* </>
        )} */}
        </Dialog>
      </Transition>
    </>
  );
}
