import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../Utils/API";
import { GET_TRANSPORTERS_LIST } from "../../Utils/ApiGlobal";
// import Loader from "../UI/Loader";

const animatedComponents = makeAnimated();

export default function TransporterName({
  handleOnChange,
  name,
  defaultValue,
}) {
  const [optionsTransporter, setOptions] = useState({});
  useEffect(() => {
    axiosInstance
      .get(`${GET_TRANSPORTERS_LIST}?search=&pageNo=&pageSize=`)
      .then((response) => {
        //  console.log(response?.data?.data?.transporterDetails);
        if (response?.data?.data?.transporterDetails.length > 0) {
          const options = response?.data?.data?.transporterDetails.map(
            ({ transporterName, transporterId }) => ({
              transporterName,
              transporterId,
            })
          );
          setOptions(options);
        }
      });
  }, []);

  return (
    <>
      {optionsTransporter.length > 0 && (
        <Select
          components={animatedComponents}
          defaultValue={{ label: defaultValue, value: defaultValue }}
          options={optionsTransporter.map((el) => ({
            label: el.transporterName,
            value: el.transporterId,
          }))}
          name={name}
          onChange={handleOnChange}
          placeholder="Select Transporter Name"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#8cd7de",
              primary: "#179eaa",
            },
          })}
        />
      ) 
      // : (
      //   <Loader />
      // )
      }
    </>
  );
}
