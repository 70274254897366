// import Link from 'next/link'
import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-simple-tooltip";

// const LinkWrapper = ({ href, children }) => href ? <Link href={href}>{children}</Link> : children;

const themes = {
  bg: {
    common:
      "px-4 py-2  border border-transparent text-sm font-medium  rounded-md focus:outline-none focus:ring-2 focus:outline-kirtiroadways-300 focus:ring-offset-2 text-center",
    primary:
      "border-transparent text-white bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover ",
    secondary:
      "text-primary-kirtiroadways border-gray-300  bg-gray-50 hover:bg-gray-100",
    danger:
      "border border-gray-300 text-gray-50 bg-red-600 hover:bg-red-700 focus:outline-none",
    disabled:
      "border border-gray-300 text-gray-50 bg-gray-300 focus:outline-none",
  },
};

const ButtonWithBG = ({
  label,
  svg,
  href,
  children,
  handleClick,
  theme,
  fullButton,
  disabled,
  tooltipContent,
  ...rest
}) => {
  const className = `${themes.bg.common} ${
    themes.bg[(disabled && "disabled") || theme || "primary"]
  } ${fullButton && "w-full"} `;

  return (
    // <LinkWrapper href={href}>
    <>
      {tooltipContent ? (
        <Tooltip
        style={{fontWeight:"600",letterSpacing:0.5}}
          border="#179eaa"
          color="black"
          background="#d1ecee"
          content={tooltipContent}
          radius={5}
          placement="bottom"
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <button
            type="button"
            onClick={handleClick}
            className={className}
            disabled={disabled}
            {...rest}
          >
            {label || children || svg}
          </button>
        </Tooltip>
      ) : (
        <button
          type="button"
          onClick={handleClick}
          className={className}
          disabled={disabled}
          {...rest}
        >
          {label || children || svg}
        </button>
      )}
    </>
    // </LinkWrapper>
  );
};

export default ButtonWithBG;

ButtonWithBG.propTypes = {
  label: PropTypes.string, // text for the Button if shortcode used
  link: PropTypes.string, // NextJS href wrapper
  handleClick: PropTypes.func, // handler for click event
  theme: PropTypes.string, //PropTypes.oneOf['primary', 'secondary', 'danger'], // one of the themes bg choices, default: "primary"
  fullButton: PropTypes.bool, // button width will fill container
  disabled: PropTypes.bool, // if button is disabled
};
