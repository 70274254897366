import React, { useEffect, useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import axiosInstance from "../../Utils/API";
import { ADD_COMMISSION_DATA } from "../../Utils/ApiGlobal";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import SearchVehicleNo from "../../Components/Functionality/SearchVehicleNo";
import moment from "moment";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import { ChevronDownIcon } from "@heroicons/react/solid";
import TransporterName from "../../Components/Functionality/TransporterName";
import Notification from "../../Components/UI/Notification";
import Tooltip from "react-simple-tooltip";
import Loader from "../../Components/UI/Loader";
import APILoader from "../../Components/UI/APILoader";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";

// import { CompanyOptions } from "../../Utils/Constants";

// const theme = (theme) => ({
//   ...theme,
//   spacing: {
//     ...theme.spacing,
//     controlHeight: 10,
//     baseUnit: 0,
//   },
// });

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
const error_notification = (props) => ({
  mode: "failure",
  timeout: 5000,
  top: 10,
  title: props.title,
});

export default function AddEditCommision({ props }) {
  const location = useLocation();
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [LRDate, setLRDate] = useState(new Date());
  const [newvehicleOwnerName, setNewVehicleOwnerName] = useState();
  // const [startDate, setStartDate] = useState(new Date());
  const [transporter, setTransporter] = useState();
  const [transporterId, setTransporterId] = useState();
  const [isNullValueAvailable, setIsNullValueAvailable] = useState(true);
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [vehicleOwnerCommission, setVehicleOwnerCommission] = useState();
  const [vehicleOwnerCommissionAmount, setVehicleOwnerCommissionAmount] =
    useState();
  const [vehicleOwnerName, setVehicleOwnerName] = useState();
  const [vehicleNumber, setVehicleNumber] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [addFormValues, setAddFormValues] = useState({
    TransporterFreight: 0,
    TransporterAdvance: 0,
    VehicleFreight: 0,
    VehicleAdvance: 0,
  });
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [balanceCal, setBalanceCal] = useState(0);
  const [VehBalanceCal, setVehBalanceCal] = useState(0);
  const [trantotalCal, settrantotalCal] = useState();
  const [vehtotalCal, setvehtotalCal] = useState();
  const [showTransportation, setShowTransportation] = useState(true);
  const [showVehicle, setShowVehicle] = useState(false);
  const [notif, setNotif] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [notifOpts, setNotifOpts] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Commission");
    } else {
      setHeading("Edit Commission");
    }
  }, [location]);

  // For Loading
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, []);


  // Select handle Vehicle Number
    const handleVehicleOnChange = (e) => {
      // console.log(e.label)
      // console.log(e.value)
      // console.log(e.ownerId)
      setVehicleNumber(e.label);
      setVehicleOwnerId(e.ownerId)
      setNewVehicleOwnerName(e.vehicleOwner);
    };
  // Select Vehicle Owner
  // const handleOnChange = (e) => {
  //   console.log(e)
  //   console.log(e.label)
  //   console.log(e.value)
  //   setVehicleOwnerId(e.value);
  //   setVehicleOwnerName(e.label);
  //   setVehicleOwnerCommission(e.commissionType);
  //   setVehicleOwnerCommissionAmount(e.amount);
  // };
  // Select handle Transporter
  const handleTransporterOnChange = (e) => {
    setTransporter(e.label);
    setTransporterId(e.value);
  };
    // Get Vehicle Owner Name By ID
    useEffect(() => {
        axiosInstance
          .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
          .then((response) => {
            // console.log(response)
            // console.log(response?.data?.data[0])
            setVehicleOwnerName(response?.data?.data[0].ownerName);
            setVehicleOwnerCommission(response?.data?.data[0].ownerCommissionType);
            setVehicleOwnerCommissionAmount(response?.data?.data[0].ownerCommissionType)
            })
          },[vehicleOwnerId]);
  
  // Null value check on add vehicle details btn
  const checkNullValue = (value) => {
    value.preventDefault();
    if (!transporter) {
      setFormErrors({ TransporterName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Transporter Name` })
      );
      return;
    } else if (!vehicleOwnerName) {
      setFormErrors({ vehicleOwnerName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Owner Name` })
      );
      return;
    } else if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (!from) {
      setFormErrors({ from: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter From Value` }));
      return;
    } else if (!to) {
      setFormErrors({ to: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter To Value` }));
      return;
    }
    setIsNullValueAvailable(false);
    setShowTransportation(false);
    setShowVehicle(true);
  };
  //Onchange values handleClick event
  const values_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newValue;
    if (typeof value === "string" && value.trim().length === 0) {
      newValue = 0;
    } else {
      newValue = value;
    }
    const newValues = {
      ...addFormValues,
      [name]: newValue,
    };
    setAddFormValues(newValues);
    if (name === "TransporterFreight" || name === "TransporterAdvance") {
      calTotal(newValues, "transporter");
    }
    if (name === "VehicleFreight" || name === "VehicleAdvance") {
      calTotal(newValues, "vehicle");
    }
  };

  // Total Calculation (Freight - Advance)
  const calTotal = (addFormValues, type) => {
    if (type === "transporter") {
      const { TransporterFreight, TransporterAdvance } = addFormValues;
      const balance =
        parseInt(TransporterFreight) - parseInt(TransporterAdvance);
      setBalanceCal(parseInt(balance));
      settrantotalCal(balance);
    } else if (type === "vehicle") {
      const { VehicleFreight, VehicleAdvance } = addFormValues;
      const balance = parseInt(VehicleFreight) - parseInt(VehicleAdvance);
      setVehBalanceCal(parseInt(balance));
      setvehtotalCal(balance);
    }
  };

  //   Form Submit click event(Commission Add API )
  const FormSubmitHandler = async (ev) => {
    // console.log(vehicleOwnerName)
    // console.log("hi")
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    if (!transporter) {
      setFormErrors({ TransporterName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Transporter Name` })
      );
      return;
    } else if (!vehicleOwnerName) {
      setFormErrors({ vehicleOwnerName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Owner Name` })
      );
      return;
    } else if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (!from) {
      setFormErrors({ from: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter From Value` }));
      return;
    } else if (!to) {
      setFormErrors({ to: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter To Value` }));
      return;
    }
    let ADDCOMMISSION_DATA = {
      LoadingDate: moment(LRDate).toDate(),
      TransporterName: transporter.toUpperCase(),
      TransporterId: transporterId,
      VehicleOwnerId: vehicleOwnerId,
      VehicleOwnerName: vehicleOwnerName.toUpperCase(),
      VehicleNo: vehicleNumber.toUpperCase(),
      From: from.toUpperCase(),
      To: to.toUpperCase(),
      TransporterFreigth: parseInt(addFormValues.TransporterFreight),
      TransporterAdvance: parseInt(addFormValues.TransporterAdvance),
      TransporterBalance: parseInt(balanceCal),
      TransporterTotal: trantotalCal,
      VehicleFreight: parseInt(addFormValues.VehicleFreight),
      VehicleAdvance: parseInt(addFormValues.VehicleAdvance),
      VehicleBalance: parseInt(VehBalanceCal),
      VehicleTotal: vehtotalCal,
      // IsBilltyReceive: isBuilltyAvailable,
      CreatedBy: localStorage.getItem("userId"),
    };
    // console.log(ADDCOMMISSION_DATA)
    await axiosInstance
      .post(ADD_COMMISSION_DATA, ADDCOMMISSION_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/commission-entry",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  return (
    <DashBoardLayout pageTitle={heading}>
      <APILoader loader={loader}/>
      <Notification
        show={notif}
        setShow={setNotif}
        {...notifOpts}
        supportContent={true}
      ></Notification>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
          <form className="space" onSubmit={FormSubmitHandler}>
            <div className="text-left space-y-6">
              {/* Loading date and Transporter Name */}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setShowTransportation(!showTransportation);
                }}
                className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
              >
                Transportation Information
                <ChevronDownIcon className="h-5 w-5 ui-open:rotate-90 ui-open:transform" />
              </div>
              {showTransportation && (
                <>
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="L. Date" isRequired="true" />
                      <div className="flex flex-row justify-center ">
                        {/* loadingDate */}
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={LRDate}
                          className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          onChange={(date) => setLRDate(date)}
                          name="L_date"
                        />
                      </div>
                    </div>
                    {/* Transporter Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Name" isRequired="true" />
                      <TransporterName
                        defaultValue={transporter}
                        handleOnChange={handleTransporterOnChange}
                      />
                      {formErrors.TransporterName === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please select Transporter Name
                        </p>
                      )}
                    </div>
                    {/* Vehicle Number. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle No." isRequired="true"/>
                      <SearchVehicleNo
                        defaultValue={vehicleNumber}
                        // vehicleOwnerId={vehicleOwnerId}
                        handleOnChange={handleVehicleOnChange}
                      />
                      {formErrors.vehicleNumber === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter vehicle Number
                        </p>
                      )}

                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                   {/* Vehicle Owner Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Owner Name" isRequired="true" />
                      {/* <SearchVelicleOwner
                        defaultValue={vehicleOwnerName}
                        handleOnChange={handleOnChange}
                        newvehicleOwnerName={newvehicleOwnerName}
                        vehicleOwnerId = {vehicleOwnerId}
                      /> */}
                       <Input
                        name="vehicleOwnerName"
                        defaultValue={vehicleOwnerName}
                        required
                      />
                      {formErrors.vehicleOwnerName === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter vehicle Owner Name
                        </p>
                      )}
                    </div>
                    {/* From */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="From" isRequired="true" />
                      <Input
                        name="From"
                        defaultValue={from}
                        onChange={(e) => setFrom(e.target.value)}
                        required
                      />
                      {formErrors.from === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter From Value
                        </p>
                      )}
                    </div>
                    {/* To */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="To" isRequired="true" />
                      <Input
                        name="To"
                        defaultValue={to}
                        onChange={(e) => setTo(e.target.value)}
                        required
                      />
                      {formErrors.to === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter To Value
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Transporter Fright, advance and balance */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Freight */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Transporter Freight" isRequired="true" />
                      <Input
                        defaultValue={addFormValues.TransporterFreight}
                        type="number"
                        name="TransporterFreight"
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Advance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Advance" />
                      <Input
                        defaultValue={addFormValues.TransporterAdvance}
                        type="number"
                        name="TransporterAdvance"
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Balance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Balance" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {balanceCal}
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <ButtonWithBG
                      onClick={(e) => checkNullValue(e)}
                      theme="secondary"
                      label="Add Vehicle Information"
                    />
                  </div>
                </>
              )}

              {/* ---Vehicle Information --- */}
              {isNullValueAvailable ? (
                <Tooltip
                  className="cursor-not-allowed	 w-full "
                  style={{ fontWeight: "600", letterSpacing: 0.5 }}
                  border="#179eaa"
                  color="black"
                  fontSize="12px"
                  background="#d1ecee"
                  content="Please fill Transportation Information to enable"
                  radius={5}
                  placement="top"
                  zIndex={100}
                  padding={4}
                  arrow={5}
                >
                  <div
                    // onClick={() => setShowVehicle(!showVehicle)}
                    className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
                  >
                    Vehicle Information
                    <ChevronDownIcon className="h-5 w-5 ui-open:rotate-90 ui-open:transform" />
                  </div>
                </Tooltip>
              ) : (
                <div
                  onClick={() => setShowVehicle(!showVehicle)}
                  className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
                >
                  Vehicle Information
                  <ChevronDownIcon className="h-5 w-5 ui-open:rotate-90 ui-open:transform" />
                </div>
              )}

              {showVehicle && (
                <>
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="L. Date" isRequired="true" />
                      <div className="flex flex-row justify-center ">
                        {/* loadingDate */}
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={LRDate}
                          className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          onChange={(date) => setLRDate(date)}
                          name="L_date"
                        />
                      </div>
                    </div>
                    {/* Transporter Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Name" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        truncate 
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {transporter}
                      </p>
                    </div>
                    {/* Vehicle Number. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle No." isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleNumber}
                      </p>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Vehicle Owner Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Owner Name" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleOwnerName || newvehicleOwnerName}
                      </p>
                    </div>
                    {/* From */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="From" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {from}
                      </p>
                    </div>
                    {/* To */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="To" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {to}
                      </p>{" "}
                    </div>
                  </div>

                  {/* Vehicle Fright, advance and balance */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Freight */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Vehicle Freight" isRequired="true" />
                      <Input
                        type="number"
                        name="VehicleFreight"
                        defaultValue={addFormValues.VehicleFreight}
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Advance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Advance" />
                      <Input
                        type="number"
                        name="VehicleAdvance"
                        defaultValue={addFormValues.VehicleAdvance}
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Balance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Balance" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {VehBalanceCal}
                      </p>
                    </div>
                  </div>

                  {/* Vehicle commission type,amount */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Vehicle commission type */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label
                        label="Vehicle Commission Type"
                        isRequired="true"
                      />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleOwnerCommission}
                      </p>
                    </div>
                    {/* Commission Amount */}
                    {vehicleOwnerCommissionAmount && (
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label
                          label="Vehicle Manual Commission Amount"
                          isRequired="true"
                        />
                        <p
                          className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        >
                          {vehicleOwnerCommissionAmount}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="items-ceter pt-8">
              <ButtonWithBG theme="primary" label="SAVE" type="submit" />
            </div>
          </form>
        </div>
      )}
    </DashBoardLayout>
  );
}
