import React, { useEffect, useRef } from "react";
import { useState } from "react";
import logo from "../../Images/logo_green_transparent.png";
import logo_green_oposity from "../../Images/logo_green_oposity.png";
// import table_bg from "../../Images/table_bg.png";
// import Scrollbars from "react-custom-scrollbars-2";
import Loader from "../../Components/UI/Loader";
import BillingInvoicePrint from "./BillingInvoicePrint";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import { GET_BANK_LIST } from "../../Utils/ApiGlobal";
import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
  // AmountChargeableInWords,
  OURBANKDETAILS,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import Checkbox from "../../Components/UI/Checkbox";
import { GETVIEWBOOKINGBYID } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Button from "../../Components/UI/ButtonMain";
import { GET_COMPANY_INFORMATION_DATA } from "../../Utils/ApiGlobal";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
const BillingView = () => {
  // const [bankDetails, setBankDetails] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [bookingData, setBookingData] = useState([]);
  const [invoiceTo, setInvoiceTo] = useState();
  const [otherName, setOtherName] = useState();
  const [otherAdd, setOtherAdd] = useState();
  const [otherGST, setOtherGST] = useState();
  const [open, setOpen] = useState(true);
  const [sentInvoice, setSentInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [bankBranch, setBankBranch] = useState({
    bank: "",
    branch: "",
    accNo: "",
    ifsc: "",
  });
  const [Dropdown, setDropdown] = useState(false);
  const [optionsBank, setOptions] = useState({});
  const [Rate, SetRate] = useState()
  const ref = useRef(null);
  const location = useLocation();
  var loadingDate = location?.data?.data?.lrDate;
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };
  // Access Bank Details
  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        setOptions(options);
        // console.log(options);
      }
    });
  };

  // Invoice TO
  const InvoiceToValue = (val) => {
    setInvoiceTo(val.target.value);
    if (val.target.value === "Consignor") {
      setSentInvoice({
        name: location?.data?.data?.consignerName,
        address: location?.data?.data?.consignerBillToAddress,
        gst: location?.data?.data?.consignerGst,
      });
    } else if (val.target.value === "Consignee") {
      setSentInvoice({
        name: location?.data?.data?.consigneeName,
        address: location?.data?.data?.consigneeBillToAddress,
        gst: location?.data?.data?.consigneeGst,
      });
    } else if (val.target.value === "Other") {
      setSentInvoice({
        name: location?.data?.data?.otherName,
        address: location?.data?.data?.otherAdd,
        gst: location?.data?.data?.otherGST,
      });
    }
    // console.log("foo", val.target.value);
  };
  // Builty check button click
  const headerClick = async (e) => {
    setShowHeader(e.target.checked);
  };

  useEffect(() => {
    _FetchProfileAPI();
  }, []);
  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        console.log(response?.data?.data?.secondaryPhoneNo);
        setProfileData(data);
        const arr = response?.data?.data?.secondaryPhoneNo;
        const noEmptyStrings = arr.filter((str) => str !== '');
        setsecondaryPhoneNo(noEmptyStrings);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

 

  useEffect(() => {
    // console.log(location);
    const BookingId = location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        // console.log(response);
        setBookingData(response?.data?.data?.bookingDetails);
        setIsLoading(false);
      });
  };

  var Loading_Date = moment(loadingDate).format("DD-MM-YYYY");
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ minWidth: "1240px" }}>
          {open && (
            <Transition show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={ref}
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
                </Transition.Child>

                <div className=" fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                        <div className="bg-white px-8 pt-4 pb-4">
                          <div className="">
                            <div className=" text-center ">
                              <div className="mt-2">
                                <p className="text-xl text-center align-center font-medium  text-gray-900">
                                  Invoice To
                                </p>
                                <hr />
                                <div className="flex flex-row justify-between space-x-4 mt-4">
                                  <div className="w-1/3  space-y-2">
                                    <div className="space-x-2 flex items-center">
                                      <input
                                        id="Consignor"
                                        type="radio"
                                        value="Consignor"
                                        name="invoice-to"
                                        className="w-4 h-4 accent-cyan-600	text-white"
                                        onChange={(val) => InvoiceToValue(val)}
                                      />
                                      <label
                                        htmlFor="Consignor"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        Consignor
                                      </label>
                                    </div>
                                    <div className="text-sm  space-y-1  flex flex-col text-left items-start">
                                      <div className="flex text-sm flex-row space-x-2">
                                        <span className="w-12 text-xs	">
                                          Name:
                                        </span>
                                        <span className="text-xs	">
                                          {bookingData?.consignerName}
                                        </span>
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <span className="w-12 text-xs pr-2">
                                          Address:
                                        </span>
                                        <span className="text-xs	">
                                          {bookingData?.consignerBillToAddress}
                                        </span>
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <span className="w-12 text-xs">
                                          GST:
                                        </span>
                                        <span className="text-xs	">
                                          {bookingData?.consignerGst}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-1/3   space-y-2">
                                    <div className="space-x-2  flex items-center">
                                      <input
                                        id="Consignee"
                                        type="radio"
                                        value="Consignee"
                                        name="invoice-to"
                                        className="w-4 h-4 accent-cyan-600	text-white"
                                        onChange={(val) => InvoiceToValue(val)}
                                      />
                                      <label
                                        htmlFor="Consignee"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        Consignee
                                      </label>
                                    </div>
                                    <div className="text-sm   text-left space-y-1 flex flex-col items-start">
                                      <div className="flex flex-row space-x-2">
                                        <span className="w-12 text-xs">
                                          Name:
                                        </span>{" "}
                                        <p className=" text-xs">
                                          {bookingData?.consigneeName}
                                        </p>
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <span className="w-12 pr-2 text-xs">
                                          Address:
                                        </span>{" "}
                                        <p className=" text-xs">
                                          {bookingData?.consigneeBillToAddress}
                                        </p>
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <span className="w-12 text-xs">
                                          GST:
                                        </span>{" "}
                                        <p className=" text-xs">
                                          {bookingData?.consigneeGst}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className= "space-y-2 w-20">
                                    <div className="space-x-2  flex items-center">
                                      <label
                                        htmlFor="Rate"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        Rate
                                      </label>
                                    </div>
                                    <div className="text-sm  text-left space-y-1 flex flex-col items-start">
                                      <div className="flex flex-row space-x-2">
                                      <input
                                          onChange={(val) =>
                                            SetRate(val.target.value.toUpperCase())
                                          }
                                          className="peer w-full  px-3 py-2 border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="w-full mt-4 space-y-2">
                                  <div className="space-x-2 flex items-center">
                                    <input
                                      id="Other"
                                      type="radio"
                                      value="Other"
                                      name="invoice-to"
                                      className="w-4 h-4 accent-cyan-600	text-white"
                                      onChange={(val) => InvoiceToValue(val)}
                                    />
                                    <label
                                      htmlFor="Other"
                                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                      Other
                                    </label>
                                  </div>
                                  <div className="text-sm w-full space-y-2 flex flex-col items-start">
                                    <div className="space-x-2 w-full flex flex-row">
                                      <div className="flex w-full flex-col items-start space-x-1">
                                        <p>Name:</p>
                                        <input
                                          onChange={(val) =>
                                            setOtherName(val.target.value)
                                          }
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                      </div>
                                      <div className="flex w-full flex-col items-start space-x-1">
                                        <p>GST:</p>
                                        <input
                                          onChange={(val) =>
                                            setOtherGST(val.target.value)
                                          }
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="flex w-full flex-col items-start space-x-1">
                                      <p>Address:</p>
                                      <textarea
                                        onChange={(val) =>
                                          setOtherAdd(val.target.value)
                                        }
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                        name="note"
                                        id=""
                                        cols="30"
                                        rows="3"
                                        type="text"
                                        required
                                      ></textarea>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" px-4 py-3 flex justify-center  sm:px-6">
                            {/* <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        OK
                      </button> */}
                            <Button
                              onClick={() => setOpen(false)}
                              // ref={ref }
                              disabled={(!invoiceTo || !Rate)}
                              theme="primary"
                            >
                              OK
                            </Button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          )}
          <div style={{ minWidth: "1024px" }}>
            <div
              className=" block min-h-screen align-top text-center bg-center bg-no-repeat  bg-[length:65%]"
              style={{
                backgroundImage: "url(" + logo_green_oposity + ")",
              }}
            >
              <div className="flex flex-row mt-2 space-x-2 justify-center text-center">
                <Label label="Header" />
                <Checkbox
                  defaultChecked={showHeader}
                  handleClick={(e) => headerClick(e)}
                />
              </div>

              <div className="block my-2 mx-5 border-double border-4 border-cyan-200">
                {showHeader && (
                  <div className="">
                    <div className="flex  flex-row justify-center font-bold sm:mx-8 mx-2 mb-3 sm:text-sm text-xs">
                      {/* <h5>{InvoicSubject}</h5> */}
                      <h5>Tax Invoice</h5>
                      {/* <h5> </h5> */}
                    </div>
                    {/* Logo and Title Start */}
                    <div className="m-2 items-center space-x-3 flex flex-row">
                      <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                        <div className="flex flex-row justify-between">
                          <div className="w-80 ml-2">
                            <img src={logo} alt="Kirti Roadlines" />
                          </div>
                          <div className=" flex flex-col ">
                            <span className="text-9xl text-primary-kirtiroadways font-extrabold ">
                              {profileData.tagLine}
                            </span>
                            <span className="text-4xl font-serif text-black">
                              {profileData.subTagLine}
                            </span>
                          </div>
                        </div>
                        {/* Address Start */}
                        <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                        {profileData.address !== " " && 
                           (<p className="flex  text-md justify-center md:flex-row items-start">
                            <span className="text-md w-5">{address_svg}</span>{" "}
                            <span className="text-md ml-1">
                              {profileData.address}
                            </span>
                          </p>
                          )}
                          <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                           { (secondaryPhoneNo.length !== 0) && 
                              (<div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                              <span>{call_svg} </span>{" "}
                              <span className="flex flex-row">
                                {secondaryPhoneNo.map((number, i) => (
                                  <span
                                    key={number}
                                    className="text-sm font-semibold tracking-wider flex flex-row"
                                  >
                                    {(i ? ", " : "") + number}
                                  </span>
                                ))}
                              </span>
                            </div>)}
                            {profileData.email !== " " && 
                              (<div className="flex text-md space-x-1 justify-center flex-row items-center ">
                              <span>{email_svg} </span>
                              <span>{profileData.email}</span>
                            </div>)}
                          </div>
                        </div>
                        {/* Address End */}
                      </div>
                    </div>
                    {/* Logo and Title End */}
                  </div>
                )}
                {/* Invoice  No and details*/}
                <div className="p-2 flex font-sans flex-row justify-between">
                  <div className="flex flex-col text-left w-1/2">
                    <div className="font-bold uppercase text-md	py-1 mb-0 px-5 w-36 text-white rounded-lg bg-primary-kirtiroadways">
                      Invoice to
                    </div>
                    <div>
                      <h1 className="text-left uppercase font-bold font-sans text-2xl	subpixel-antialiased tracking-wide mt-0	">
                        {invoiceTo === "Consignor" &&
                          location?.data?.data?.consignerName}
                        {invoiceTo === "Consignee" &&
                          location?.data?.data?.consigneeName}
                        {invoiceTo === "Other" && otherName}
                      </h1>
                    </div>
                    <div className="text-sm text-left">
                      <div className="flex  flex-row mt-1 ">
                        <p className="">
                          Address :{" "}
                          {invoiceTo === "Consignor" &&
                            location?.data?.data?.consignerBillToAddress}
                          {invoiceTo === "Consignee" &&
                            location?.data?.data?.consigneeBillToAddress}
                          {invoiceTo === "Other" && otherAdd}
                        </p>
                        {/* <p className="w-1/2 ">
                    {invoiceTo === "Consignor" &&
                      location?.data?.data?.consignerBillToAddress}
                    {invoiceTo === "Consignee" &&
                      location?.data?.data?.consigneeBillToAddress}
                    {invoiceTo === "Other" && otherAdd}
                  </p> */}
                      </div>
                    </div>
                    <div className="text-sm mt-1">
                      <div className="flex flex-row text-left">
                        <p className="">
                          GST :{" "}
                          {invoiceTo === "Consignor" &&
                            location?.data?.data?.consignerGst}
                          {invoiceTo === "Consignee" &&
                            location?.data?.data?.consigneeGst}
                          {invoiceTo === "Other" && otherGST}
                        </p>
                        {/* <p className="w-1/2">
                    {invoiceTo === "Consignor" &&
                      location?.data?.data?.consignerGst}
                    {invoiceTo === "Consignee" &&
                      location?.data?.data?.consigneeGst}
                    {invoiceTo === "Other" && otherGST}
                  </p> */}
                      </div>
                    </div>
                  </div>

                  {/* Right side */}
                  <div className="flex  flex-col text-right justify-end items-end">
                    <div className="font-bold uppercase text-base	py-1 mb-0 px-5 w-34 text-white rounded-lg bg-primary-kirtiroadways">
                      OUR Details
                    </div>
                    <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
                      <p className="flex flex-col text-md">
                        <span>Service : {profileData.service}</span>
                        <span>Pan No. : {profileData.panNo}</span>
                        <span>State Code : {profileData.stateCode}</span>
                      </p>
                      {/* <p className="flex flex-col">
                  <span>Transportation</span>
                  <span>AJGPD9182R</span>
                  <span>24</span>
                </p> */}
                    </div>

                    <div className=" uppercase font-semibold	pt-1 mt-2 border-t-2 border-primary-kirtiroadways">
                      <div className="flex flex-row text-sm text-right space-x-12">
                        <span className="flex flex-col ">
                          <p className="text-right min-w-1/2">
                            Invoice No : {location?.data?.data?.invoiceNo}
                          </p>
                          <p className="text-right min-w-1/2">
                            Bill OF Loading : 065/RE/2022-23
                          </p>
                          <p className="text-right min-w-1/2">
                            Date : {Today_Date}
                          </p>
                        </span>
                        {/* <span className="flex flex-col ">
                    <p className="text-left min-w-1/2">
                      {location?.data?.data?.invoiceNo}
                    </p>
                    <p className="text-left min-w-1/2">065/RE/2022-23</p>
                    <p className="text-left min-w-1/2">{Today_Date}</p>
                  </span> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Table start*/}
                <div
                  // style={{ minWidth: "768px" }}
                  className="p-2 w-full md:mb-0 mb-2 border-t-0 border-neutral-900"
                >
                  <table className="border-spacing-y-2 border-separate w-full border-neutral-900 text-sm font-sans">
                    <thead style={{ maxHeight: "5px" }} className="mb-4">
                      <tr className=" border-neutral-900 text-white ">
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900 py-3"
                        >
                          Loading Date
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          LR No.
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          Vehicle No.
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          From
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          To
                        </th>
                        {/* <th
                    className=" bg-no-repeat"
                    style={{
                      backgroundImage: "url(" + table_bg + ")",
                    }}
                  ></th> */}
                        <th
                          className="bg-primary-kirtiroadways corner border-neutral-900"
                          // style={{
                          // backgroundImage: "url(" + table_whitered + ")",
                          // }}
                        >
                          Weight
                        </th>
                        {/* <th className="bg-primary-kirtiroadways border-neutral-900">
                    Charged Weight
                  </th> */}
                        <th className="bg-primary-kirtiroadways border-neutral-900">
                          Rate
                        </th>
                        <th className="bg-primary-kirtiroadways border-neutral-900 w-28">
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      className="align-top font-bold text-center bg-bottom bg-no-repeat bg-[length:80%_90%]"
                      // style={{
                      //   backgroundImage: "url(" + logo_green_oposity + ")",
                      // }}
                    >
                      <tr height="20px" className="mt-6">
                        <td
                          height="20px"
                          className="items-center w-32 border-r-2 border-gray-300"
                        >
                          <h1 className="mt-2">{Loading_Date}</h1>
                        </td>
                        <td className="w-28 border-r-2 border-gray-300">
                          <h1 className="mt-2">{location?.data?.data?.lrNo}</h1>
                        </td>
                        <td className="w-32 border-r-2 border-gray-300">
                          <h1 className="mt-2">
                            {location?.data?.data?.vehicleNo}
                          </h1>
                        </td>
                        <td className="w-48 border-r-2 border-gray-300">
                          <h1 className="mt-2">{location?.data?.data?.from}</h1>
                        </td>
                        <td className="border-l w-48 border-r-2 border-gray-300">
                          <h1 className="ml-2 mt-2 text-center">
                            {location?.data?.data?.to}
                          </h1>
                        </td>
                        <td className="w-32 border-r-2 border-gray-300">
                          <h1 className="mt-2 text-center">
                            {location?.data?.data?.actualWeight}{" "}{" "}{location?.data?.data?.weightType}
                          </h1>
                        </td>
                        {/* <td className="border-l border-neutral-900">
                    <h1 className="mt-2 text-center">
                      {location?.data?.data?.chargedWeight}
                    </h1>
                  </td> */}
                        <td className="w-24 border-r-2 border-gray-300">
                          <h1 className="mt-2 text-center">{Rate}</h1>
                        </td>
                        <td className="">
                          <h1 className="mt-2 text-center">
                            {location?.data?.data?.balance}
                          </h1>
                        </td>
                      </tr>
                      <tr style={{ height: "250px" }}></tr>
                    </tbody>
                  </table>

                  {/* Bannk Detail Start */}
                  <div
                    className="flex flex-row justify-between  font-semibold text-left text-sm
             font-sans"
                  >
                    <div className="mt-4 ">
                      <div className="flex flex-col space-x-1 ">
                        <h5 className="px-5 py-2 mb-2 text-md tracking-wide w-48 rounded-lg uppercase bg-primary-kirtiroadways text-white">
                          {OURBANKDETAILS}
                        </h5>
                        {optionsBank.length > 0 && (
                          <div
                            style={{ minWidth: "350px", maxWidth: "350px", marginLeft: "inherit"}}
                            className="flex flex-col  text-left w-1/2 z-80"
                          >
                            <Select
                              options={optionsBank.map((el) => ({
                                label: el.bankName,
                                value: el.accountNo,
                                branch: el.branch,
                                billAdd: el.billToAddress,
                                ifscCode: el.ifscCode,
                              }))}
                              onChange={(e) => {
                                setBankBranch({
                                  ...bankBranch,
                                  bank: e.label,
                                  branch: e.branch,
                                  accNo: e.value,
                                  ifsc: e.ifscCode,
                                });
                                // console.log(e);
                                setDropdown(true);
                              }}
                              placeholder="Bank Branch"
                              className="z-80 "
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#8cd7de",
                                  primary: "#179eaa",
                                },
                              })}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row space-x-1 mt-2">
                        <div className="space-y-1">
                          <h5>Bank Name</h5>
                          <h5>Branch</h5>
                          <h5>A/c. No</h5>
                          <h5>IFSC Code</h5>
                        </div>
                        {Dropdown === false ? (
                          <div className="space-y-1">
                            <h5>: State Bank of India</h5>
                            <h5>: Prahlad Nagar Ahmedabad</h5>
                            <h5>: 005167983000351</h5>
                            <h5>: SBIT0CKMNSB (FIFT CHARACTER IS ZERO)</h5>
                          </div>
                        ) : (
                          <div className="space-y-1">
                            <h5>: {bankBranch.bank}</h5>
                            <h5>: {bankBranch.branch}</h5>
                            <h5>: {bankBranch.accNo}</h5>
                            <h5>: {bankBranch.ifsc}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row  justify-end mt-4 border-t-2 w-64 border-primary-kirtiroadways">
                      <div className="space-y-1  mt-2  text-right w-32">
                        <h5 className="pr-2">TOTAL :</h5>
                        <div className="h-16">
                          {location?.data?.data?.hamali > 0 && (
                            <h5 className=" pr-2">Hamali :</h5>
                          )}
                          {location?.data?.data?.detention > 0 && (
                            <h5 className="pr-2">Detention :</h5>
                          )}
                          {location?.data?.data?.extraCharge > 0 && (
                            <h5 className=" pr-2">Extra Charge :</h5>
                          )}
                        </div>
                        <h5 className="pr-2 text-sm	 px-2 py-2 tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase bg-primary-kirtiroadways text-white">
                          Grand Total
                        </h5>
                      </div>
                      <div className="space-y-1 mt-2 text-right w-28">
                        <h5>&#8377; {location?.data?.data?.balance}</h5>
                        <div className="h-16">
                          {location?.data?.data?.hamali > 0 && (
                            <h5>&#8377; {location?.data?.data?.hamali}</h5>
                          )}
                          {location?.data?.data?.detention > 0 && (
                            <h5>&#8377; {location?.data?.data?.detention}</h5>
                          )}
                          {location?.data?.data?.detention > 0 && (
                            <h5>
                              {" "}
                              &#8377; {location?.data?.data?.extraCharge}
                            </h5>
                          )}
                        </div>
                        <h5 className="border-l-2 text-md whitespace-nowrap rounded-r-lg border-white pr-2 px-5 py-2 tracking-wide b-r-2 w-full uppercase bg-primary-kirtiroadways text-white">
                          &#8377; {location?.data?.data?.total}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* Bannk Detail End */}

                  {/* Extra Information */}
                  <div className="flex flex-col justify-start items-start font-sans border-t-2 border-primary-kirtiroadways mt-2">
                    <p>Amount Chargeable (in words)</p>
                    <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                      {/* Four Thousand Four Hundred Fifty */}
                      {location?.data?.data?.totalInWords}{" "}ONLY
                    </p>
                  </div>
                  {/* terms and condition */}
                  <div className="flex pb-6 flex-row justify-start items-start mt-2 font-sans">
                    <div className="w-1/2 text-left flex flex-col">
                      <p className="border-black border-b-4 w-36 pb-1">
                        Terms & Conditions
                      </p>
                      <p>{InvoicSubject}</p>
                    </div>
                    <div className="w-1/2 text-left text-sm">E. & O.E.</div>
                    <div className="w-1/2 text-left text-sm ">FOR,</div>
                    <div className="w-1/3 text-lg font-bold text-right ">
                      {profileData.tagLine}
                    </div>
                  </div>

                  {/* last line */}
                  <div className="flex border-black border-b-2 flex-row mt-6 justify-start items-start mb-2 font-sans">
                    <div className="w-2/3 text-left flex flex-col font-sans">
                      <p>{PaymentbypayeeCchequeDrafs}</p>
                    </div>
                    <div className="w-1/2 text-sm text-center ">
                      {profileData.tagLine}
                    </div>
                    <div className="w-1/2 text-right  font-bold ">
                      <h5>{AuthorizedSignatory}</h5>
                    </div>
                  </div>

                  {/* printing */}
                  <h2 className="text-xs mt-0 font-bold text-center font-sans bg-primary-kirtiroadways rounded-md p-2 text-white">
                    {computerGeneratedInvoice}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <BillingInvoicePrint
            sentInvoice={sentInvoice}
            bankBranch={bankBranch}
            Dropdown={Dropdown}
            ShowHeader={showHeader}
            profileData={profileData}
            secondaryPhoneNo={secondaryPhoneNo}
            Rate = {Rate}
          />
        </div>
      )}
    </>
  );
};

export default BillingView;
