import React, { useEffect, useState } from "react";
import { useRef } from "react";
import InvoicePrint from "../../Components/UI/InvoicePrint";
import InvoiceView from "../../Components/UI/InvoiceView";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import Button from "../../Components/UI/ButtonMain";
import ReactToPrint from "react-to-print";
import { GETVIEWBOOKINGBYID, GET_COMPANY_INFORMATION_DATA, UPDATE_BUILTY } from "../../Utils/ApiGlobal";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import Loader from "../../Components/UI/Loader";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
// import Scrollbars from "react-custom-scrollbars-2";

const BilltyInvoiceView = (props) => {
  const pageStyle = `@page {
    size: A4;
    margin: 10pt;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }
`;
  let componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [builltyData, setBuilltyData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const ref = useRef(null);
  const [open, setOpen] = useState(location?.openModel);
  const [information, setInformation] = useState("");
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [builltyUpdateData, setBuilltyUpdatedata] = useState();
  // let open = location?.openModel;
  // let setOpen = location?.setOpenModel


  useEffect(() => {
    _FetchProfileAPI();
  }, []);
  const _FetchProfileAPI = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        const arr = response?.data?.data?.secondaryPhoneNo;
        const noEmptyStrings = arr.filter((str) => str !== '');
        setsecondaryPhoneNo(noEmptyStrings);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  // Window Back btn path set
  useEffect(() => {
    const BookingId =
      location?.state?.bookingId || location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.location.pathname = "/pending-booking";
        history.replace("/pending-booking");
      }
    };
  });
  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    setIsLoading(true)
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        setIsLoading(false)
        // console.log(response?.data?.data?.billtyDetails)
        if (response?.data?.data?.billtyDetails.length > 0) {
          setBuilltyData(response?.data?.data?.billtyDetails);
        } else {
          setBookingData(response?.data?.data?.bookingDetails);
        }
      });
  };
  //Update Billty Handler

  const PopUpSubmitHandler = () => {
    for (let item = 0; item < builltyData.length; item++) {
      let UPDATE_BUILLTY_DATA = {
        BilltyId: builltyData[item].billtyId,
        FinancialYear: builltyData[item].financialYear,
        LrNo: builltyData[item].lrNo,
        LrDate: builltyData[item].lrDate,
        VehicleNo: builltyData[item].vehicleNo,
        DeliveryAt: builltyData[item].deliveryAt,
        ConsignerName: builltyData[item].consignerName,
        ConsignerGST: builltyData[item].consignerGst,
        ConsignerAddress: builltyData[item].consignerAddress,
        ConsignerBillToAddress: builltyData[item].consignerBillToAddress,
        ConsigneeName: builltyData[item].consigneeName,
        ConsigneeGst: builltyData[item].consigneeGst,
        ConsigneeAddress: builltyData[item].consigneeAddress,
        ConsigneeBillToAddress: builltyData[item].consigneeBillToAddress,
        From: builltyData[item].from,
        To: builltyData[item].to,
        Packages: builltyData[item].packages,
        Description: builltyData[item].description,
        ActualWeight: builltyData[item].actualWeight,
        ChargedWeight: builltyData[item].chargedWeight,
        WeightType: builltyData[item].weightType,
        Fright: builltyData[item].fright,
        Advance: builltyData[item].advance,
        Balance: builltyData[item].balance,
        Detention: builltyData[item].detention,
        DriverPaidBalance: builltyData[item].driverPaidBalance,
        ExtraCharge: builltyData[item].extraCharge,
        Hamali: builltyData[item].hamali,
        Note: builltyData[item].note,
        PrivateMarks: builltyData[item].privateMarks,
        BilltyNo: builltyData[item],
        AmountToPayOrPaid: information.toUpperCase(),
        Total: builltyData[item].total,
        UpdatedBy: builltyData[item].updatedBy
      }
      axiosInstance
        .post(UPDATE_BUILTY, UPDATE_BUILLTY_DATA)
        .then((response) => {
          // console.log(response)
          // console.log("GOOD")
          setOpen(false)
        })
        .catch((error) => {
          // console.log("Error")
          return;
        })
    }
  }
  useEffect(() => {
    const BookingId =
      location?.state?.bookingId || location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {open && (
            <Transition show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={ref}
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
                </Transition.Child>

                <div className=" fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                        <div className="bg-white px-8 pt-4 pb-4">
                          <div className="">
                            <div className=" text-center ">
                              <div className="mt-2">
                                <p className="text-xl text-center align-center font-medium  text-gray-900">
                                  Amount to Pay/Paid
                                </p>
                                <hr />

                                <div className="w-full mt-4 space-y-2">
                                  <div className="text-sm w-full space-y-2 flex flex-col items-start">
                                    <div className="flex w-full flex-col items-start space-x-1">
                                      <textarea
                                        onChange={(val) =>
                                          setInformation(val.target.value)
                                        }
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                        name="note"
                                        id=""
                                        cols="30"
                                        rows="3"
                                        type="text"
                                        required
                                      ></textarea>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" px-4 py-3 flex justify-center  sm:px-6">
                                <Button
                                  onClick={PopUpSubmitHandler}
                                  // ref={ref }
                                  theme="primary"
                                >
                                  OK
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          )}

          {/* <Scrollbars
        universal
        autoHeight
        autoHeightMax={1000}
        className="shadow border-b border-gray-200  w-full"
      > */}

          <div
            // style={{ minWidth: "1100px" }}
            className="overflow-x-scroll w-full"
          >
            {builltyData.length > 0 ? (
              <>
                {" "}
                {builltyData.map((subData, i) => (
                  <span key={subData.billtyId}>
                    <InvoiceView
                      i={0}
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="CONSIGNOR"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                    <hr className="my-12 border-gray-400" />
                    <InvoiceView
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="CONSIGNEE"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                    <hr className="my-12 border-gray-400" />
                    <InvoiceView
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="DRIVER"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                  </span>
                ))}
              </>
            ) : (
              <span>
                <InvoiceView
                  information={information}
                  i={0}
                  data={bookingData}
                  copyFor="CONSIGNOR"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                />
                <hr className="my-12 border-gray-400" />
                <InvoiceView
                  information={information}
                  data={bookingData}
                  copyFor="CONSIGNEE"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                />
                <hr className="my-12 border-gray-400" />
                <InvoiceView
                  information={information}
                  data={bookingData}
                  copyFor="DRIVER"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                />
              </span>
            )}

            <div className="hidden">
              <div ref={(el) => (componentRef = el)}>
                {builltyData.length > 0 ? (
                  <>
                    {builltyData.map((subData, i) => (
                      <div key={subData.billtyId}
                        className="">
                        <InvoicePrint
                          information={information}
                          i={i}
                          data={subData}
                          copyFor="CONSIGNOR"
                          profileData={profileData}
                          secondaryPhoneNo={secondaryPhoneNo}
                        />
                        <InvoicePrint
                          information={information}
                          i={i}
                          data={subData}
                          copyFor="CONSIGNEE"
                          profileData={profileData}
                          secondaryPhoneNo={secondaryPhoneNo}
                        />
                        <div className="">
                          <InvoicePrint
                            information={information}
                            i={i}
                            data={subData}
                            copyFor="DRIVER"
                            profileData={profileData}
                            secondaryPhoneNo={secondaryPhoneNo}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>
                    <InvoicePrint
                      information={information}
                      i={0}
                      data={bookingData}
                      copyFor="CONSIGNOR"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                    <InvoicePrint
                      information={information}
                      data={bookingData}
                      copyFor="CONSIGNEE"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                    <InvoicePrint
                      information={information}
                      data={bookingData}
                      copyFor="DRIVER"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                    />
                  </span>
                )}
              </div>
            </div>

            <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
              <style>{pageStyle}</style>
              <ReactToPrint
                pageStyle={pageStyle}
                documentTitle={
                  "Billty invoice of " + builltyData.length > 0
                    ? builltyData.consigneeName
                    : bookingData.consigneeName
                }
                trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
                content={() => componentRef}
              />
            </div>
          </div>

          {/* </Scrollbars> */}
        </div>
      )}
    </>
  );
};

export default BilltyInvoiceView;
