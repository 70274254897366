import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ResponsiveNav from "../../Components/UI/Nav/ResponsiveNav";
import PageHeader from "../../Components/UI/PageHeader";
import { DASHBOARD_SIDE_NAV } from "../../Utils/Constants";
import logo1 from "../../Images/logo1.png";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  commison_svg,
  // CompanyName_svg,
  SVG_PENDING,
  COMPANY_SVG,
  TRANSPORTER_SVG,
  // Vehicle_Owner_Name_Svg,
} from "../../Utils/SVGListing";

const DashBoardLayout = ({ children, pageTitle, toggleButton, ...prop }) => {
  const [open, setOpen] = useState(true);

  let history = useHistory();

  // Logout function
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("LoggedIn", false);
    localStorage.removeItem("token", null);
    history.push("/");
  };

  return (
    <>
      <div className="h-screen  z-100 flex bg-white">
        <div className="md:flex   h-screen w-screen max-h-screen">
          {/* Desktop nav */}

          {/* {/ Static sidebar for desktop /} */}
          {open && (
            <nav
              id="collapseExample"
              className={` z-40 shadow-lg lg:block md:block hidden ${
                open
                  ? " w-64  bg-white  text-primary-kirtiroadways top-0 fixed "
                  : " w-0  h-0 hidden"
              }   collapse p-5 pt-0 relative duration-700`}
            >
              <div className="w-full   flex place-content-between ">
                <div className="flex   h-50 gap-x-4 items-center">
                  {/* {/ logo /} */}
                  <div className=" shrink pt-3 pl-2 text-2xl font-bold  text-gray-900  sm:truncate">
                    <div>
                      <img
                        src={logo1}
                        alt="Transporter"
                        width={150}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="w-full relative font-bold "></div> */}
              </div>

              <div className="w-34">
                {/* {/ Nav Items /} */}
                <ul className=" lg:block  md:block hidden">
                  {DASHBOARD_SIDE_NAV.map((item, index) => {
                    return (
                      <li key={item.id}>
                        {item.title !== "Booking" &&
                          item.title  !== "Transporter" && (
                            <NavLink
                              to={item.route}
                              key={item.id}
                              className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                              ${item.gap ? "mt-9" : "mt-2"} ${
                                index === 0 && ""
                              } `}
                              // className={({ isActive }) => isActive? "text-black bg-primary-kirtiroadways-hover": ''}
                              style={(isActive) => ({
                                backgroundColor: isActive ? "#8cd7de" : "",
                                color: isActive ? "black" : "",
                              })}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d={item.path}
                                />
                              </svg>
                              <span>{item.title}</span>
                            </NavLink>
                          )}

                        {item.title === "Booking" && (
                          <Menu
                            key={item.id}
                            as="div"
                            className="text-left w-full"
                          >
                            <Menu.Button
                              className={`flex mx-auto w-full max-w-md rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                         ${item.gap ? "mt-9" : "mt-2"} ${
                                index === 0 && ""
                              } `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                {item.path}
                              </svg>
                              <span>{item.title}</span>
                              <ChevronDownIcon
                                className="ml-2 -mr-1 h-5 w-5 "
                                aria-hidden="true"
                              />
                            </Menu.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="pl-2">
                                  <NavLink
                                    className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                    ${item.gap ? "mt-9" : "mt-2"} ${
                                      index === 0 && ""
                                    } `}
                                    style={(isActive) => ({
                                      backgroundColor: isActive ? "#8cd7de" : "",
                                      color: isActive ? "black" : "",
                                    })}
                                    to="/company"
                                  >
                                    {COMPANY_SVG}
                                    <span>Company</span>
                                  </NavLink>
                                <NavLink
                                  className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                  ${item.gap ? "mt-9" : "mt-2"} ${
                                    index === 0 && ""
                                  } `}
                                  style={(isActive) => ({
                                    backgroundColor: isActive ? "#8cd7de" : "",
                                    color: isActive ? "black" : "",
                                  })}
                                  to="/pending-booking"
                                >
                                  {SVG_PENDING}
                                  <span>Booking Details</span>
                                </NavLink>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                        {/* Transporter */}
                        {item.title === "Transporter" && (
                          <Menu
                            key={item.id}
                            as="div"
                            className="text-left w-full"
                          >
                            <Menu.Button
                              className={`flex mx-auto w-full max-w-md rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                         ${item.gap ? "mt-9" : "mt-2"} ${
                                index === 0 && ""
                              } `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                {item.path}
                              </svg>
                              <span>{item.title}</span>
                              <ChevronDownIcon
                                className="ml-2 -mr-1 h-5 w-5 "
                                aria-hidden="true"
                              />
                            </Menu.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="pl-2">
                                <NavLink
                                  to="/transporter"
                                  className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                       ${item.gap ? "mt-9" : "mt-2"} ${
                                    index === 0 && ""
                                  } `}
                                  style={(isActive) => ({
                                    backgroundColor: isActive ? "#8cd7de" : "",
                                    color: isActive ? "black" : "",
                                  })}
                                >
                                  {TRANSPORTER_SVG}
                                  <span>Transporter</span>
                                </NavLink>
                                <NavLink
                                  to="/commission-entry"
                                  className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                       ${item.gap ? "mt-9" : "mt-2"} ${
                                    index === 0 && ""
                                  } `}
                                  style={(isActive) => ({
                                    backgroundColor: isActive ? "#8cd7de" : "",
                                    color: isActive ? "black" : "",
                                  })}
                                >
                                  {commison_svg}
                                  <span>Commission</span>
                                </NavLink>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                      </li>
                    );
                  })}
                  {/* {/ Logout /} */}
                  <li>
                    <Link
                      to="/login"
                      onClick={handleLogout}
                      className={`mt-2 flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                      } `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <span
                        className={`${
                          !{ open } && "hidden"
                        } origin-left duration-700`}
                      >
                        Logout
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          )}

          {/* {/ Mobile Menu /} */}
          <ResponsiveNav />

          {/* {/ Main view /} */}
          <div className=" z-30 overflow-y-auto  w-full">
            <div className="">
              {/* {/ Header /} */}
              <PageHeader
                openToggle={() => setOpen(!open)}
                toggleButton={open}
                pageTitle={pageTitle}
              />
              {/* {/ Component /} */}
              <div className="  overflow-y-auto overflow-x-auto  md:pt-14 pt-32">
                <div className="m-3">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardLayout;
