import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import Checkbox from "../../Components/UI/Checkbox";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Notification from "../../Components/UI/Notification";
import Loader from "../../Components/UI/Loader";
import axiosInstance from "../../Utils/API";
import { ADD_BOOKING, UPDATE_BOOKING } from "../../Utils/ApiGlobal";
import moment from "moment";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

// const error_notification = (props) => ({
//   mode: "failure",
//   timeout: 5000,
//   top: 10,
//   title: props.title,
// });
// const theme = (theme) => ({
//   ...theme,
//   spacing: {
//     ...theme.spacing,
//     controlHeight: 10,
//     baseUnit: 0,
//     width: 100,
//   },
// });

export default function AddEditBooking(update, setActiveTab) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [transferData, setTransferData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(location?.state?.data?.data?.loadingDate).toDate()
  );
  // const [checked, setChecked] = useState();
  const [showHamali, setShowHamali] = useState(false);
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  const [balanceCalculate, setBalanceCalculate] = useState();
  const [totalCalculate, setTotalCalculate] = useState();
  const [totalValues, setTotalValues] = useState({
    Freight : 0,
    advance: 0,
    Hamali : 0,
    Detention : 0,
    Extra_Charge : 0,
  });
  // const [isBuillty, setIsBuillty] = useState(false);

  // To fetch Data from previous route
  useEffect(() => {
    // console.log(setActiveTab)
    // console.log(location?.state);
    if (location?.state?.label === "Add") {
      setHeading("Add Booking");
    } else {
      setHeading("Edit Booking");
      setBalanceCalculate(parseInt(location?.state?.data?.data?.balance));
      setTotalCalculate(location?.state?.data?.data?.total)
      setTotalValues({
        Freight : location?.state?.data?.data?.freigth,
        advance: location?.state?.data?.data?.advance,
        Hamali :location?.state?.data?.data?.hamali,
        Detention : location?.state?.data?.data?.detention,
        Extra_Charge : location?.state?.data?.data?.extraCharge,
      });
    }
    // console.log(location?.state?.data);
  }, [location]);

  const GetValueforTotal = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name, value);
    const newValues = {
      ...totalValues,
      [name]: parseInt(value),
    };
    setTotalValues(newValues);
    // Calling the method to sum the value
    calc_total(newValues);
  };

  const calc_total = (newValues) => {
    const { Freight, advance, Hamali , Detention , Extra_Charge  } = newValues;
    const newTotal =
      parseInt(Freight) +
      parseInt(advance) +
      (parseInt(Freight) - parseInt(advance)) +
      parseInt(Hamali) +
      parseInt(Detention) +
      parseInt(Extra_Charge);
    setTotalCalculate(newTotal);
  };
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  //   Form Submit click event
  const submitForms = async (ev) => {
    ev.preventDefault();

    var obj = formValueConvertor(getFormJSON(ev.target));
    // console.log(obj, "obj");
    //  API
    if (location?.state?.label === "Add") {
      let ADD_BOOKING_DATA = {
        LoadingDate: obj.startDate,
        VehicleNo: obj.Vehicle_No,
        ConsignerName: obj.Consignor_Name,
        ConsignerAddress: obj.Consignor_Address,
        ConsignerGst: obj.Consignor_GST_No,
        ConsigneeName: obj.Consignee_Name,
        ConsigneeAddress: obj.Consignee_Address,
        ConsigneeGst: obj.Consignee_GST_No,
        From: obj.From,
        To: obj.To,
        LrNo: obj.LR_No,
        BillNo: obj.Bill_No,
        BookingAmount: +obj.Booking_Amount,
        Freigth: parseFloat(obj.Freight).toFixed(2),
        Advance: +obj.advance,
        Balance:
          parseInt(transferData.Freight) - parseInt(transferData.advance),
        Hamali: obj.Hamali,
        Detection: obj.Detention,
        ExtraCharge: obj.Extra_Charge,
        Total: totalCalculate,
        IsDriverPaidBalance: +obj.Driver_paid_bal,
        balanceInvoiceId: obj.Balance_Paid_Bill_No,
        Note: obj.note,
        IsBiltyReceive: false,
      };
      await axiosInstance
        .post(ADD_BOOKING, ADD_BOOKING_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/pending-booking",
            state: { data: obj },
          });
          setIsLoading(true);
          return;
        })
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          return;
        });
    } else {
      let EDIT_BOOKING_DATA = {
        BookingId: location?.state?.data?.data?.bookingId,
        LoadingDate: obj.startDate,
        VehicleNo: obj.Vehicle_No,
        ConsignerName: obj.Consignor_Name,
        ConsignerAddress: obj.Consignor_Address,
        ConsignerGst: obj.Consignor_GST_No,
        ConsigneeName: obj.Consignee_Name,
        ConsigneeAddress: obj.Consignee_Address,
        ConsigneeGst: obj.Consignee_GST_No,
        From: obj.From,
        To: obj.To,
        LrNo: obj.LR_No,
        BillNo: obj.Bill_No,
        BookingAmount: +obj.Booking_Amount,
        Freigth: obj.Freight,
        Advance: +obj.advance,
        Balance: parseInt(obj.Freight) - parseInt(obj.advance),
        // Hamali: +(obj.Hamali === "" ? 0 : parseInt(obj.Hamali)),
        // Detention: obj.Detention === "" ? 0.0 : parseInt(obj.Detention),
        // ExtraCharge: +(obj.Extra_Charge === "" ? 0 : parseInt(obj.Detention)),
        Total: totalCalculate,
        IsDriverPaidBalance: +obj.Driver_paid_bal,
        balanceInvoiceId: obj.Balance_Paid_Bill_No,
        Note: obj.note,
        IsBiltyReceive: false,
      };
      await axiosInstance
        .post(UPDATE_BOOKING, EDIT_BOOKING_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/pending-booking",
            state: { data: obj },
          });
          setIsLoading(true);
          return;
        })
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          ConfirormationModal(error.message, "error", 2000);
          return;
        });
    }
  };
  // Builty check button click
  const builltyClick = async (e) => {
    if (e.target.checked === true) {
      //  API
      // console.log(transferData.balance);
      if (location?.state?.label === "Add") {
        let ADD_BOOKING_DATA = {
          LoadingDate: transferData.date,
          VehicleNo: transferData.Vehicle_No,
          ConsignerName: transferData.Consignor_Name,
          ConsignerAddress: transferData.Consignor_Address,
          ConsignerGst: transferData.Consignor_GST_No,
          ConsigneeName: transferData.Consignee_Name,
          ConsigneeAddress: transferData.Consignee_Address,
          ConsigneeGst: transferData.Consignee_GST_No,
          From: transferData.From,
          To: transferData.To,
          LrNo: transferData.LR_No,
          BillNo: transferData.Bill_No,
          BookingAmount: +transferData.Booking_Amount,
          Freigth: transferData.Freight,
          Advance: +transferData.advance,
          Balance:
            +parseInt(transferData.Freight) - parseInt(transferData.advance),
          Hamali: +0,
          Detection: 0.0,
          ExtraCharge: 0,
          Total: totalCalculate,
          IsDriverPaidBalance: +transferData.Driver_paid_bal,
          balanceInvoiceId: transferData.Balance_Paid_Bill_No,
          Note: transferData.note,
          // IsBiltyReceive: true,
        };
        await axiosInstance
          .post(ADD_BOOKING, ADD_BOOKING_DATA)
          .then((response) => {
            // console.log(response.data)
            setNotif(true);
            setNotifOpts({
              mode: "success",
              timeout: 10000,
              top: 10,
              title: "Success",
              content: response.data.data.message,
            });
            ConfirormationModal(response.data.data.message, "success", 2000);
            history.push({
              pathname: "/bilty/add-edit-billty",
              state: {
                data: ADD_BOOKING_DATA,
                label: "Add",
                bookindData: response.data.data,
              },
            });
            setIsLoading(true);
            return;
          })
          .catch((error) => {
            ConfirormationModal(error.message, "error", 2000);
            setNotifOpts({
              mode: "failure",
              timeout: 10000,
              top: 10,
              title: "Error",
              content: "Something Went Wrong",
            });
            return;
          });
      } else {
        let EDIT_BOOKING_DATA = {
          BookingId: location?.state?.data?.data?.bookingId,
          LoadingDate: transferData.L_date
            ? transferData.L_date
            : location?.state?.data?.data?.loadingDate,
          VehicleNo: transferData.Vehicle_No
            ? transferData.Vehicle_No
            : location?.state?.data?.data?.vehicleNo,
          ConsignerName: transferData.Consignor_Name
            ? transferData.Consignor_Name
            : location?.state?.data?.data?.consignerName,
          ConsignerAddress: transferData.Consignor_Address
            ? transferData.Consignor_Address
            : location?.state?.data?.data?.consignerAddress,
          ConsignerGst: transferData.Consignor_GST_No
            ? transferData.Consignor_GST_No
            : location?.state?.data?.data?.consignerGst,
          ConsigneeName: transferData.Consignee_Name
            ? transferData.Consignee_Name
            : location?.state?.data?.data?.consigneeName,
          ConsigneeAddress: transferData.Consignee_Address
            ? transferData.Consignee_Address
            : location?.state?.data?.data?.consigneeAddress,
          ConsigneeGst: transferData.Consignee_GST_No
            ? transferData.Consignee_GST_No
            : location?.state?.data?.data?.consigneeGst,
          From: transferData.From
            ? transferData.From
            : location?.state?.data?.data?.from,
          To: transferData.To
            ? transferData.To
            : location?.state?.data?.data?.to,
          LrNo: transferData.LR_No
            ? transferData.LR_No
            : location?.state?.data?.data?.lrNo,
          BillNo: transferData.Bill_No
            ? transferData.Bill_No
            : location?.state?.data?.data?.billNo,
          BookingAmount: transferData.Booking_Amount
            ? +transferData.Booking_Amount
            : +location?.state?.data?.data?.bookingAmount,
          Freigth: transferData.Freight
            ? transferData.Freight
            : location?.state?.data?.data?.freigth,
          Advance: +transferData.advance
            ? transferData.advance
            : location?.state?.data?.data?.advance,
          Balance: +transferData.Freight
            ? transferData.Freight - transferData.advance
            : location?.state?.data?.data?.balance,
          Hamali: 0,
          //  +transferData.Hamali  && location?.state?.data?.data?.hamali === 0
          //   ? 0: parseInt(transferData.Hamali),
          Detection: 0,
          // transferData.Detention && location?.state?.data?.data?.detention === 0.0
          //   ? 0.0
          //   : parseInt(transferData.Detention),
          ExtraCharge: 0,
          // +transferData.Extra_Charge  && location?.state?.data?.data?.extraCharge === 0
          //   ? 0
          //   : parseInt(transferData.Extra_Charge),
          Total: +transferData.Total
            ? transferData.Total
            : location?.state?.data?.data?.total,
          IsDriverPaidBalance: +transferData.Driver_paid_bal
            ? transferData.Driver_paid_bal
            : location?.state?.data?.data?.isDriverPaidBalance,
          balanceInvoiceId: transferData.Balance_Paid_Bill_No
            ? transferData.Balance_Paid_Bill_No
            : location?.state?.data?.data?.balanceInvoiceId,
          Note: transferData.note
            ? transferData.note
            : location?.state?.data?.data?.note,
          // IsBiltyReceive: true,
        };
        await axiosInstance
          .post(UPDATE_BOOKING, EDIT_BOOKING_DATA)
          .then((response) => {
            setNotif(true);
            setNotifOpts({
              mode: "success",
              timeout: 10000,
              top: 10,
              title: "Success",
              content: response.data.data.message,
            });
            ConfirormationModal(response.data.data.message, "success", 2000);
            history.push({
              pathname: "/bilty/add-edit-billty",
              state: {
                data: EDIT_BOOKING_DATA,
                label: "Edit",
                bookindId: response.bookingId,
                bookindData: response.data.data,
                builty: "NA",
              },
            });
            setIsLoading(true);
            return;
          })
          .catch((error) => {
            setNotifOpts({
              mode: "failure",
              timeout: 10000,
              top: 10,
              title: "Error",
              content: "Something Went Wrong",
            });
            ConfirormationModal(error.message, "error", 2000);
            return;
          });
      }
      // submitForms(e)
    }
  };
  return (
    <>
      {!update.update ? (
        <DashBoardLayout pageTitle={heading}>
          <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
          {isLoading ? (
            <Loader />
          ) : (
            <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
              <form onSubmit={submitForms}>
                <div className="">
                  <div className=" text-left space-y-6">
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="L. Date" isRequired="true"/>
                        <div className="flex flex-row justify-center ">
                          {/* loadingDate */}
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            // selected={moment(
                            //   location?.state?.data?.data?.loadingDate
                            // ).toDate()}
                            selected={startDate}
                            name="L_date"
                            onChange={(date) => {
                              setStartDate(date);
                              setTransferData({
                                ...transferData,
                                date: date,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Vehicle No." isRequired="true" />
                        <Input
                          defaultValue={location?.state?.data?.data?.vehicleNo}
                          name="Vehicle_No"
                          type="text"
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Vehicle_No: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's Name" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerName
                          }
                          name="Consignor_Name"
                          type="text"
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_Name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    {/* Consignor */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's Address" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerAddress
                          }
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_Address: e.target.value,
                            })
                          }
                          name="Consignor_Address"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's GST No."  />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_GST_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.consignerGst
                          }
                          name="Consignor_GST_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Consignee's Name" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consigneeName
                          }
                          name="Consignee_Name"
                          type="text"
                          required
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    {/* Consignee */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's Address" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerAddress
                          }
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_Address: e.target.value,
                            })
                          }
                          name="Consignee_Address"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's GST No." />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_GST_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.consigneeGst
                          }
                          name="Consignee_GST_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="From" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              From: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.from}
                          name="From"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    {/* From To Booking_Amount */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="To" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              To: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.to}
                          name="To"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Booking Amount" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Booking_Amount: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.bookingAmount
                          }
                          name="Booking_Amount"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="LR No." isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              LR_No: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.lrNo}
                          name="LR_No"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    {/* Select Vehicle Owner */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Bill No." isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Bill_No: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.billNo}
                          name="Bill_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Freight" isRequired="true" />
                        <Input
                          onChange={(e) => {
                            GetValueforTotal(e);
                            if (
                              location?.state?.label === "Add" &&
                              !transferData.advance
                            ) {
                              setBalanceCalculate(parseInt(e.target.value));
                            } else if (transferData.advance) {
                              setBalanceCalculate(
                                parseInt(e.target.value) -
                                  parseInt(transferData.advance)
                              );
                            } else {
                              setBalanceCalculate(
                                parseInt(e.target.value) -
                                  parseInt(location?.state?.data?.data?.advance)
                              );
                            }
                            setTransferData({
                              ...transferData,
                              Freight: e.target.value,
                            });
                          }}
                          defaultValue={location?.state?.data?.data?.freigth}
                          name="Freight"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Advance" isRequired="true" />
                        <Input
                          onChange={(e) => {
                            // if(location?.state?.label === "Edit" && !transferData.Freight){
                            //   setBalanceCalculate(parseInt(e.target.value) - parseInt(location?.state?.data?.data?.freigth));
                            // }
                            // else
                            GetValueforTotal(e);
                            if (transferData.Freight) {
                              setBalanceCalculate(
                                parseInt(transferData.Freight) -
                                  parseInt(e.target.value)
                              );
                            } else {
                              setBalanceCalculate(parseInt(e.target.value));
                            }
                            // setBalanceCalculate(parseInt(transferData.Freight)-parseInt(e.target.value));
                            setTransferData({
                              ...transferData,
                              advance: e.target.value,
                            });
                          }}
                          defaultValue={location?.state?.data?.data?.advance}
                          name="advance"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                    </div>
                    {/* Balance */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Balance" isRequired="true" />
                        <Input
                          // value={balanceCalculate}
                          onChange={(e) => {
                            setTransferData({
                              ...transferData,
                              balance: e.target.value,
                            });
                          }}
                          defaultValue={balanceCalculate}
                          name="balance"
                          required
                        />
                      </div>
                      {heading === "Edit Booking" && (
                        <div className=" flex flex-row space-x-5 text-left ">
                          <button
                            className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md mt-5 text-white "
                            type="button"
                            onClick={() => {
                              setShowHamali(true);
                            }}
                          >
                            +
                          </button>
                          <button
                            className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md mt-5 text-white "
                            type="button"
                            onClick={() => {
                              setShowHamali(false);
                            }}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </div>
                    {/* Hanmali, Detention & Extra Charge  */}
                    {showHamali && (
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Hamali" isRequired="true" />
                          <Input
                            onChange={(e) => {
                              GetValueforTotal(e);
                              setTransferData({
                                ...transferData,
                                Hamali: e.target.value,
                              });
                            }}
                            defaultValue={location?.state?.data?.data?.hamali}
                            name="Hamali"
                            type="number"
                            step="any"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Detention" isRequired="true" />
                          <Input
                            onChange={(e) => {
                              GetValueforTotal(e);
                              setTransferData({
                                ...transferData,
                                Detention: e.target.value,
                              });
                            }}
                            defaultValue={
                              location?.state?.data?.data?.detention
                            }
                            name="Detention"
                            type="number"
                            step="any"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Extra Charge" isRequired="true" />
                          <Input
                            onChange={(e) => {
                              GetValueforTotal(e);
                              setTransferData({
                                ...transferData,
                                Extra_Charge: e.target.value,
                              });
                            }}
                            defaultValue={
                              location?.state?.data?.data?.extraCharge
                            }
                            name="Extra_Charge"
                            type="number"
                            step="any"
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                        <Label label="Total" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Total: e.target.value,
                            })
                          }
                          defaultValue={totalCalculate}
                          name="Total"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex flex-col md:w-1/3 text-left w-full">
                        <Label label="Driver Paid Balance" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Driver_paid_bal: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.isDriverPaidBalance
                          }
                          name="Driver_paid_bal"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                        <Label
                          label="Balance Paid Bill No."
                          isRequired="true"
                        />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Balance_Paid_Bill_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.balanceInvoiceId
                          }
                          name="Balance_Paid_Bill_No"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      {/* Note */}
                      <div className=" w-full">
                        <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-4 space-y-4">
                          <div className=" flex flex-col  text-left md:w-1/2">
                            <Label label="Note" isRequired="true" />
                            <textarea
                              onChange={(e) =>
                                setTransferData({
                                  ...transferData,
                                  note: e.target.value,
                                })
                              }
                              className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              name="note"
                              id=""
                              cols="30"
                              rows="3"
                              defaultValue={location?.state?.data?.data?.note}
                              type="text"
                              required
                            ></textarea>
                          </div>
                          <div className=" flex md:w-1/2  flex-col  text-left">
                            <Label label="Bilty" isRequired="true" />
                            <Checkbox
                              defaultChecked={
                                location?.state?.data?.data?.isBiltyReceive
                              }
                              handleClick={(e) => builltyClick(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mt-4">
                  <ButtonWithBG theme="primary" label="SAVE" type="submit" />
                </div>
              </form>
            </div>
           )}
        </DashBoardLayout>
      ) : (
        <>
          <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
          {isLoading ? (
            <Loader />
          ) : (
            <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
              <form onSubmit={submitForms}>
                <div className="">
                  <div className=" text-left space-y-6">
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="L. Date" isRequired="true" />
                        <div className="flex flex-row justify-center ">
                          {/* loadingDate */}
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            // selected={moment(
                            //   location?.state?.data?.data?.loadingDate
                            // ).toDate()}
                            selected={startDate}
                            name="L_date"
                            onChange={(date) => {
                              setStartDate(date);
                              setTransferData({
                                ...transferData,
                                date: date,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Vehicle No." isRequired="true" />
                        <Input
                          defaultValue={location?.state?.data?.data?.vehicleNo}
                          name="Vehicle_No"
                          type="text"
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Vehicle_No: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's Name" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerName
                          }
                          name="Consignor_Name"
                          type="text"
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_Name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    {/* Consignor */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's Address" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerAddress
                          }
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_Address: e.target.value,
                            })
                          }
                          name="Consignor_Address"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's GST No." />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignor_GST_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.consignerGst
                          }
                          name="Consignor_GST_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Consignee's Name" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consigneeName
                          }
                          name="Consignee_Name"
                          type="text"
                          required
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    {/* Consignee */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's Address" isRequired="true" />
                        <Input
                          defaultValue={
                            location?.state?.data?.data?.consignerAddress
                          }
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_Address: e.target.value,
                            })
                          }
                          name="Consignee_Address"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's GST No."/>
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Consignee_GST_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.consigneeGst
                          }
                          name="Consignee_GST_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="From" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              From: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.from}
                          name="From"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    {/* From To Booking_Amount */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="To" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              To: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.to}
                          name="To"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Booking Amount" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Booking_Amount: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.bookingAmount
                          }
                          name="Booking_Amount"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="LR No." isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              LR_No: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.lrNo}
                          name="LR_No"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    {/* Select Vehicle Owner */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Bill No." isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Bill_No: e.target.value,
                            })
                          }
                          defaultValue={location?.state?.data?.data?.billNo}
                          name="Bill_No"
                          type="text"
                          required
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Freight" isRequired="true" />
                        <Input
                          onChange={(e) => {
                            GetValueforTotal(e);
                            if (
                              location?.state?.label === "Add" &&
                              !transferData.advance
                            ) {
                              setBalanceCalculate(parseInt(e.target.value));
                            } else if (transferData.advance) {
                              setBalanceCalculate(
                                parseInt(e.target.value) -
                                  parseInt(transferData.advance)
                              );
                            } else {
                              setBalanceCalculate(
                                parseInt(e.target.value) -
                                  parseInt(location?.state?.data?.data?.advance)
                              );
                            }
                            setTransferData({
                              ...transferData,
                              Freight: e.target.value,
                            });
                          }}
                          defaultValue={location?.state?.data?.data?.freigth}
                          name="Freight"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Advance" isRequired="true" />
                        <Input
                          onChange={(e) => {
                            GetValueforTotal(e);
                            if (
                              location?.state?.label === "Edit" &&
                              !transferData.Freight
                            ) {
                              setBalanceCalculate(
                                  parseInt(location?.state?.data?.data?.freigth) - parseInt(e.target.value)
                              );
                            } else if (transferData.Freight) {
                              setBalanceCalculate(
                                parseInt(transferData.Freight) -
                                  parseInt(e.target.value)
                              );
                            } else {
                              setBalanceCalculate(parseInt(e.target.value));
                            }
                            setTransferData({
                              ...transferData,
                              advance: e.target.value,
                            });
                          }}
                          defaultValue={location?.state?.data?.data?.advance}
                          name="advance"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                    </div>
                    {/* Balance */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Balance" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              balance: e.target.value,
                            })
                          }
                          defaultValue={balanceCalculate}
                          name="balance"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex flex-row space-x-5 text-left ">
                        <button
                          className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md mt-5 text-white "
                          type="button"
                          onClick={() => {
                            setShowHamali(true);
                          }}
                        >
                          +
                        </button>
                        <button
                          className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md mt-5 text-white "
                          type="button"
                          onClick={() => {
                            setShowHamali(false);
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>
                    {/* Hanmali, Detention & Extra Charge  */}
                    {showHamali && (
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Hamali" isRequired="true" />
                          <Input
                            onChange={(e) =>
                              {
                                GetValueforTotal(e);
                                setTransferData({
                                  ...transferData,
                                  Hamali: e.target.value,
                                })
                              }
                            }
                            defaultValue={location?.state?.data?.data?.hamali}
                            name="Hamali"
                            type="number"
                            step="any"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Detention" isRequired="true" />
                          <Input
                            onChange={(e) =>{
                              GetValueforTotal(e);
                              setTransferData({
                                ...transferData,
                                Detention: e.target.value,
                              })
                            }
                            }
                            defaultValue={
                              location?.state?.data?.data?.detention
                            }
                            name="Detention"
                            type="number"
                            step="any"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Extra Charge" isRequired="true" />
                          <Input
                            onChange={(e) =>{
                              GetValueforTotal(e);
                              setTransferData({
                                ...transferData,
                                Extra_Charge: e.target.value,
                              })
                            }
                            }
                            defaultValue={
                              location?.state?.data?.data?.extraCharge
                            }
                            name="Extra_Charge"
                            type="number"
                            step="any"
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                        <Label label="Total" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Total: e.target.value,
                            })
                          }
                          defaultValue={totalCalculate}
                          name="Total"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex flex-col md:w-1/3 text-left w-full">
                        <Label label="Driver Paid Balance" isRequired="true" />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Driver_paid_bal: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.isDriverPaidBalance
                          }
                          name="Driver_paid_bal"
                          type="number"
                          step="any"
                          required
                        />
                      </div>
                      <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                        <Label
                          label="Balance Paid Bill No."
                          isRequired="true"
                        />
                        <Input
                          onChange={(e) =>
                            setTransferData({
                              ...transferData,
                              Balance_Paid_Bill_No: e.target.value,
                            })
                          }
                          defaultValue={
                            location?.state?.data?.data?.balanceInvoiceId
                          }
                          name="Balance_Paid_Bill_No"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      {/* Note */}
                      <div className=" w-full">
                        <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-4 space-y-4">
                          <div className=" flex flex-col  text-left md:w-1/2">
                            <Label label="Note" isRequired="true" />
                            <textarea
                              onChange={(e) =>
                                setTransferData({
                                  ...transferData,
                                  note: e.target.value,
                                })
                              }
                              className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              name="note"
                              id=""
                              cols="30"
                              rows="3"
                              defaultValue={location?.state?.data?.data?.note}
                              type="text"
                              required
                            ></textarea>
                          </div>
                          <div className=" flex md:w-1/2  flex-col  text-left">
                            <Label label="Bilty" isRequired="true" />
                            <Checkbox
                              defaultChecked={
                                location?.state?.data?.data?.isBiltyReceive
                              }
                              handleClick={(e) => builltyClick(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mt-4">
                  <ButtonWithBG theme="primary" label="SAVE" type="submit" />
                </div>
              </form>
            </div>
           )}
        </>
      )}
    </>
  );
}