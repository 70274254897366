import React from "react";

export default function PageHeader({ pageTitle, toggleButton, openToggle }) {

  // Default lending page

  return (
    <div className="fixed w-full z-50 bg-white shadow-md md:top-0 top-20 p-2 md:flex md:items-center md:justify-between">
      <div>
        <div className="w-full flex  items-center">
          {/* toggle button */}
          <div
            className={` lg:block md:block hidden ${
              toggleButton ? " mt-1 mr-2 " : "mt-1 mr-2"
            }   `}
          >
            <button
              onClick={openToggle}
              type="button"
              className="items-center justify-center p-2 rounded-md text-black-400  hover:text-black hover:bg-primary-kirtiroadways-hover "
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          {/* Page Name */}
          {/* <h2 className="text-2xl shrink md:p-0 pl-2 font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            <Link to="/" target="_blank">
              <span className="md:block hidden">Kirti Roadlines</span>
            </Link>
          </h2> */}
        </div>
      </div>
      <div className="flex text-2xl font-bold pt-2 md:pt-0 w-full">
      {pageTitle} 
        {/* <SearchField placeholder={SEARCH_PLACEHOLDER}/> */}
      </div>
    </div>
  );
}
