import React from "react";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../Utils/API";
import { GET_COMMISSION_TABLE } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import moment from "moment";
import Checkbox from "../UI/Checkbox";

export default function CommissionEntryTable(props) {
  // console.log(props,"for table")
  const [CommissionData, SetCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAPI(); // eslint-disable-next-line
  }, [props.searchValue]);

  const getAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMMISSION_TABLE}?search=${
          props.searchValue ? props.searchValue : ""
        }&pageNo=&pageSize=`
      )
      .then((response) => {
        const data = response?.data?.data?.commission;
        SetCommissionData(data);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const columns = [
    {
      Header: "Is Buillty Recived",
      accessor: "isBiltyReceive",
      id: "isBiltyReceive",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <Checkbox
            defaultChecked={row.row.original.isBilltyReceive}
            className="accent-cyan-600	text-white"
            onClick={(e) => {
              e.preventDefault();
              return false;
          }}
          />
        </div>
      ),
    },
    {
      Header: "Action",
      id: "Action",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <TableAction
            // isBuillty
            isLCV
            isBilling
            data={row.row.original}
            redirectPath="commission-entry/update-commission"
            redirectViewPath="commission-entry/commission-invoice"
            redirectLCV="LCV/Transporter-Loading-Slip"
            component="Commission"
            redirectBilling="commission-entry/commission-invoice"
            getAPI={getAPI}
          />
        </div>
      ),
    },
    // {
    //   Header: "DATE",
    //   accessor: (d) => {
    //     return moment(d.createdAt).format("DD-MM-YYYY");
    //   },
    // },
    {
      Header: "L. DATE	",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle NO.",
      accessor: "vehicleNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "TRANSPORTER NAME",
      accessor: "transporterName",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "VEHICLE OWNER NAME",
      accessor: "vehicleOwnerName",
      Cell: (row) => <div className="text-left">{row.value}</div>,
    },
    {
      Header: "FROM",
      accessor: "from",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "DRIVER PAID BALANCE",
      accessor: "balancePaidBillNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "BILLTY RECEIVED OR NOT",
    //   // accessor: "isBilltyReceive",
    //   accessor: (d) => {
    //     if (d.isBilltyReceive === true) {
    //       return <span>true</span>;
    //     }
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BOOKING Amount",
    //   accessor: "bookingAmount",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "LR NO.",
    //   accessor: "lrNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Freight",
    //   accessor: "transporterFreigth",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Advance",
    //   accessor: "transporterAdvance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Balance",
    //   accessor: "transporterBalance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Hamali",
    //   accessor: "transporterHamali",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Detention",
    //   accessor: "transporterDetention",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Extra Charge",
    //   accessor: "transporterExtracharge",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Total",
    //   accessor: "total",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "FALCO",
    //   accessor: "falko",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },

    // {
    //   Header: "TRANSPORTER BALANCE",
    //   accessor: "transporterBalance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BALANCE BILL NO.",
    //   accessor: "balanceBillNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },

    // {
    //   Header: "Note",
    //   accessor: "note",
    // },
  ];

  return (
    <section>
      <div className="mt-4 ">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={CommissionData} columns={columns} />
        )}
      </div>
    </section>
  );
}
