import React from "react";
import Scrollbars from "react-custom-scrollbars-2";

import {
  //   useFilters,
  usePagination,
  useTable,
  //   useSortBy,
  //   useGlobalFilter,
} from "react-table";
const themes = ({ theme, type, tight, isLink }) => {
  const classes = {
    common: {
      td: `break-normal  break-words flex-nowrap  border-y py-1 border-slate-300 space-y-2 text-sm  text-center
      } `,
      th: `py-3 ${
        tight ? "px-2" : "px-6"
      } text-center text-xs  uppercase sticky `,
      thead: `z-70 sticky top-0`,
      footer: `z-70 bg-white sticky bottom-0 `
    },
    primary: {
      thead: "bg-green-clearbox top-0 relative ",
      th: `text-grey `,
    },
    secondary: {
      th: "font-bold",
      td: "w-max-content break-normal ",
      thead: "bg-white font-bold  bg-opacity-100 border-b-2  z-30",
    },
    light: {
      thead: "bg-green-500 bg-opacity-20 ",
      th: "text-gray-500",
    },
    dark: {
      thead: "bg-white  bg-opacity-100 border-b-2  z-30",
      th: "text-sm font-bold text-black-700 normal-case z-30 ",
    },
    dark_aligntop: {
      thead: "bg-white  bg-opacity-100 border-b-2 ",
      th: "text-sm font-bold text-black-700 normal-case ",
      td: "align-top break-normal ",
    },
  };

  return classes["common"][type] + " " + classes[theme][type];
};
export default function TablePagination({
  columns,
  data,
  getAPI,
  maxHeight=500,
  theme = "secondary",
  noPagination,
  isFooter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize:!noPagination? 10 :data.length },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <div>
      <Scrollbars
        universal
        autoHeight
        autoHeightMax={maxHeight}
        className=" shadow border-b border-gray-200 rounded-lg w-full"
      >
        <table
          className="overflow-x-auto table-fixed w-full break-normal border border-black-400 divide-gray-200 "
          {...getTableProps()}
        >
          <thead className={themes({ theme, type: "thead" })}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={themes({ theme, type: "th" })}
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={` hover:bg-primary-kirtiroadways-hover`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={themes({ theme, type: "td" })}
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {isFooter && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr className={themes({ theme, type: "footer" })} {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td  {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </Scrollbars>
      {!noPagination && <div className="w-full flex  mt-5 md:flex-row justify-between items-center  ">
        <div className="no-wrap md:w-1/2 flex md:flex-row flex-col  md:space-x-2  md:items-center">
          <div className="text-left">
            Page{" "}
            <span className="font-semibold">
              {pageIndex + 1} of {pageOptions.length}
            </span>{" "}
          </div>
          <div className="flex space-x-2 no-wrap items-center text-left">
            {/* <span> | Go to page:{" "}</span>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }} */}
            {/* /> */}
            <span>| Show </span>
            <select
              className=" rounded-md border border-gray-300 bg-white py-1 pl-3 pr-3 text-left shadow-sm focus:border-kirtiroadways-500 focus:outline-none focus:ring-1 focus:ring-kirtiroadways-500 sm:text-sm"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 15, 50].map((pageSize) => (
                <option
                  className=" hover:bg-primary-kirtiroadways-hover"
                  key={pageSize}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="  space-x-0">
          <button
            className="  rounded-l-md border border-gray-300 bg-white md:px-2 p-1 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className=" relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            className="relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-1 md:px-2 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </div>}
      
    </div>
  );
}
