import React, { useState } from "react";
// import Button from "../../Components/UI/Button/Button";
// import { useHistory } from "react-router-dom";
// import ButtonWithBG from "../../Components/UI/ButtonMain";
// import Divider from "../../Components/UI/Divider";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import BiltyTable from "../../Components/DataTables/BiltyTable";
// import { setting_svg } from "../../Utils/SVGListing";

const Billty = () => {
  // let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  // To redirect to add page
  // const redirectToAdd = () => {
  //   history.push({
  //     pathname: "/bilty/add-edit-billty",
  //     state: { label: "Add" },
  //   });
  // };

  return (
    <DashBoardLayout pageTitle="Billty">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Bilty"/> */}
        {/* Search and setting */}
        <div className="flex flex-row justify-between">
          <div>
            <SearchField
              placeholder="Searching By Date"
              handleChange={setSearchValue}
              value={searchValue}
            />
          </div>
          {/* <div className="flex flex-row space-x-2">
            <Button svg={setting_svg}></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Billty"
            />
          </div> */}
        </div>
      </div>
      <BiltyTable searchValue={searchValue} />
    </DashBoardLayout>
  );
};

export default Billty;
