import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import {
  GET_COMPANY_LEDGER,
  GET_LEDGER_TRANSPORTER,
  GET_VEHICLEOWNER_LEDGER,
} from "../../Utils/ApiGlobal";
// import { Lendger } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
import moment from "moment";
import Loader from "../UI/Loader";

export default function LendgerTable(props) {
  // for Search
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerData, SetLedgerData] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalCredit, setCredit] = useState(0);
  const [totalDebit, setDebit] = useState(0);
  const [error, setError] = useState(false);
  // const filterLedger = ({ Date }) => {
  //   return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };
  const columns = [
    {
      Header: "#",
      accessor: (d, index) => {
        return index + 1;
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "Particular	",
    //   accessor: "note",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: "Date",
      accessor: (d) => {
        return moment(d.date).format("DD-MM-YYYY");
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left">Total Amount:</span>,
    },
    {
      Header: "Debit",
      accessor: (d) => {
        if (d.creditOrDebit === "Debit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalDebit}</span>,
    },
    {
      Header: "Credit",
      accessor: (d) => {
        if (d.creditOrDebit === "Credit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalCredit}</span>,
    },
    {
      Header: "Balance",
      accessor: "amount",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalPayment} Dr</span>,
    },
  ];

  const getVehicleOwnerLedger = async (location) => {
    setIsLoading(true);
    let API_CALL;
    if (localStorage.getItem("component") === "Vehicle Owner") {
      API_CALL = `${GET_VEHICLEOWNER_LEDGER}?vehicleOwnerId=${localStorage.getItem(
        "id"
      )}`;
    } else if (localStorage.getItem("component") === "Company") {
      API_CALL = `${GET_COMPANY_LEDGER}?companyId=${localStorage.getItem(
        "id"
      )}`;
    } else if (localStorage.getItem("component") === "Transporter") {
      API_CALL = `${GET_LEDGER_TRANSPORTER}?transporterId=${localStorage.getItem(
        "id"
      )}`;
    }
    await axiosInstance
      .get(API_CALL)
      .then((response) => {
        // console.log(response);
        if (localStorage.getItem("component") === "Vehicle Owner") {
          if (response?.data?.data?.payments.length > 0) {
            const data = response?.data?.data?.payments;
            // console.log(response?.data?.data);
            SetLedgerData(data);
          }
        } else if (localStorage.getItem("component") === "Company") {
          if (response?.data?.data?.ledgers.length > 0) {
            const data = response?.data?.data?.ledgers;
            // console.log(response?.data?.data);
            SetLedgerData(data);
          } else if (localStorage.getItem("component") === "Tranporter") {
            if (response?.data?.data?.transporterLedger.length > 0) {
              const data = response?.data?.data?.transporterLedger;
              // console.log(response?.data?.data);
              SetLedgerData(data);
            }
          }
        }
        setTotalPayment(response?.data?.data?.total);
        setCredit(response?.data?.data?.totalCredit);
        setDebit(response?.data?.data?.totalDebit);
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
        return;
      });
  };

  useEffect(() => {
    getVehicleOwnerLedger(location);
    // console.log(location?.state?.data?.data);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(props,"for table")
  return (
    <section>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mt-4">
          {error ? (
            <p className="text-warn-error">No data found</p>
          ) : (
            <TablePagination data={ledgerData} columns={columns} isFooter />
          )}
        </div>
      )}
    </section>
  );
}
