import React, { useEffect, useState } from "react";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_TABLE } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
// import Checkbox from "../UI/Checkbox";

export default function CompanyNameTable(props) {
  const [companyData, SetCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // for Search by Company Name.
  // const filterByCompanyName = ({ Name }) => {
  //   return Name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  // for Search by Person name
  // const filterByPersonName = ({ Contact_Person_name }) => {
  //   return Contact_Person_name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  const columns = [
    // {
    //   Header: "Payment Status",
    //   accessor: "isBalancePaid",
    //   Cell: row => <div className="text-center text-primary-kirtiroadways font-bold ">
    //     <Checkbox
    //       defaultChecked={row.value}
    //       onClick={(e) => {
    //         e.preventDefault();
    //         return false;
    //       }}
    //       className="accent-cyan-600	text-white"
    //     />
    //   </div>,
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "Action",
      id: "Action",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <TableAction
            isLedger
            isBilling
            data={row.row.original}
            redirectPath="company/add-edit-company"
            redirectLedger="Ledger-Details"
            component="Company"
            getAPI={getAPI}
            redirectBilling="company/company-invoice"
          //  redirectViewPath="billing/billing-view"
          />
        </div>
      ),
    },
    {
      Header: "Company Name",
      accessor: "name",
      Cell: row => <div className="text-left">{row.value}</div>,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      Header: "Billing Address",
      accessor: "billToAddress",
      Cell: row => <div className="text-left truncate">{row.value}</div>,
      maxWidth: 250,
      minWidth: 10,
      // width: 250,
    },
    {
      Header: "Shipping Address",
      accessor: "shipToAddress",
      Cell: row => <div className="text-left truncate w-32 ml-5">{row.value}</div>,
      maxWidth: 25,
      minWidth: 10,
      // width: 250,
    },
    {
      Header: "GST",
      accessor: "gst",
    },
    {
      Header: "Contact Person",
      accessor: "contactPersonName",
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      Header: "Contact Number",
      accessor: "contactNumber",
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
  ];
  useEffect(() => {
    getAPI();
    // eslint-disable-next-line
  }, [props.searchValue]);

  const getAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=${props.searchValue}&pageNo=&pageSize=`)
      .then((response) => {
        // console.log(response?.data?.data?.company)
        const data = response?.data?.data?.company
        SetCompanyData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }
  // console.log(props,"for table")
  return (
    <section>
      <div className="mt-4 ">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={companyData} columns={columns} />
        )}
      </div>
    </section>
  );
}
