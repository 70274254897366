import React from "react";
import { Redirect } from "react-router-dom";

// class ProtectedRoute extends React.Component {
//     render() {
//         const Component = this.props.component;
//         const isAuthenticated = localStorage.getItem('LoggedIn');

//         return isAuthenticated ? (
//             <Component />
//         ) : (
//             <Redirect to={{ pathname: '/login' }} />
//         )
//     }
// }

const ProtectedRoute = ({ component, ...rest }) => {
    const Component = component;
    const isAuthenticated = localStorage.getItem('LoggedIn');

    return isAuthenticated ? (
        <Component {...rest}  />
    ) : (
        <Redirect to={{ pathname: '/' }} />
    )
}


export default ProtectedRoute;