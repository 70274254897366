import React from "react";
import { useState, useEffect } from "react";
import Notification from "../../Components/UI/Notification";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import DatePicker from "react-datepicker";
import Checkbox from "../../Components/UI/Checkbox";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import axiosInstance from "../../Utils/API";
import { ADD_BOOKING } from "../../Utils/ApiGlobal";
import { useHistory } from "react-router-dom";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import SearchVehicleNo from "../../Components/Functionality/SearchVehicleNo";
import CompanyInformation from "../../Components/Functionality/CompanyInformation";
import moment from "moment";
import Swal from "sweetalert2";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import PayTo from "../../Components/Functionality/PayTo";
import APILoader from "../../Components/UI/APILoader";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

const error_notification = (props) => ({
  mode: "failure",
  timeout: 5000,
  top: 10,
  title: props.title,
});
export default function AddBooking() {
  const [notif, setNotif] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [notifOpts, setNotifOpts] = useState({});
  const [LRDate, setLRDate] = useState(new Date());
  const [balanceCal, setBalanceCal] = useState(0);
  const [totalCal, setTotalCal] = useState();
  // const [checked, setChecked] = useState();
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [vehicleOwnerName, setVehicleOwnerName] = useState();
  // const [newvehicleOwnerName, setNewVehicleOwnerName] = useState();
  const [vehicleNumber, setVehicleNumber] = useState();
  const [consignorName, setConsignorName] = useState("");
  const [consignorAdd, setConsignorAdd] = useState("");
  const [consignorBillAdd, setConsignorBillAdd] = useState("");
  const [consignorGST, setConsignorGST] = useState("");
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAdd, setConsigneeAdd] = useState("");
  const [consigneeBillAdd, setConsigneeBillAdd] = useState("");
  const [consigneeGST, setConsigneeGST] = useState("");
  const [payTo, setPayTo] = useState("");
  const history = useHistory();
  //Onchange input fields store data array
  const [addFormValues, setAddFormValues] = useState({
    consignorName: "",
    consignorAdd: "",
    consignorBillAdd: "",
    consignorGST: "",
    consigneeName: "",
    consigneeAdd: "",
    consigneeBillAdd: "",
    consigneeGST: "",
    From: 0,
    To: 0,
    LR_No: "",
    Bill_No: "",
    Booking_Amount: 1000,
    note: "",
    Freight: 0,
    advance: 0,
  });

  // Select Vehicle Owener
  // const handleOnChange = (e) => {
  //   setVehicleOwnerId(e.value);
  //   setVehicleOwnerName(e.label);
  // };

  // Select handle Vehicle Number
  const handleVehicleOnChange = (e) => {
    console.log(e.label)
    console.log(e.value)
    // console.log(e.ownerId)
    setVehicleNumber(e.label);
    setVehicleOwnerId(e.ownerId)
    // setNewVehicleOwnerName(e.vehicleOwner);
  };

   // Get Vehicle Owner Name By ID
   useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
      .then((response) => {
        // console.log(response)
        // console.log(response?.data?.data[0].ownerName)
        setVehicleOwnerName(response?.data?.data[0].ownerName);
        })
      },[vehicleOwnerId]);
      
  // Select handle company
  const handleOnChangeCompany = (e) => {
    if (e.type.name === "cosignor") {
      setConsignorName(e.label);
      setConsignorAdd(e.add);
      setConsignorGST(e.value);
      setConsignorBillAdd(e.billAdd);
    } else if (e.type.name === "consignee") {
      setConsigneeName(e.label);
      setConsigneeAdd(e.add);
      setConsigneeGST(e.value);
      setConsigneeBillAdd(e.billAdd);
    }
  };
  // Pay to handle on change
  const handleOnChangePayto = (e) => {
    setPayTo(e.value);
  };
  //Onchange values handleClick event
  const values_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newValue;
    if (typeof value === "string" && value.trim().length === 0) {
      newValue = 0;
    } else {
      newValue = value;
    }
    const newValues = {
      ...addFormValues,
      [name]: newValue,
    };
    setAddFormValues(newValues);
    if (name === "Freight" || name === "advance") {
      calTotal(newValues);
    }
  };

  // Total Calculation (Freight - Advance)
  const calTotal = (addFormValues) => {
    const { Freight, advance } = addFormValues;
    const balance = parseInt(Freight) - parseInt(advance);
    setBalanceCal(parseInt(balance));
    setTotalCal(balance);
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const AddBooking = (ev) => {
    ev.preventDefault();
    var obj = formValueConvertor(getFormJSON(ev.target));
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to create a new booking without buillty?",
      showCancelButton: true,
      cancelButtonColor: "rgb(148 163 184)",
      confirmButtonColor: "#179eaa",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoader({ ...loader, loading: true });
        let ADD_BOOKING_DATA = {
          LoadingDate: moment(LRDate).toDate(),
          VehicleNo: vehicleNumber.toUpperCase(),
          VehicleOwner: vehicleOwnerName,
          ConsignerName: consignorName.toUpperCase(),
          ConsignerAddress: consignorAdd.toUpperCase(),
          ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
          ConsignerGst: consignorGST.toUpperCase(),
          ConsigneeName: consigneeName.toUpperCase(),
          ConsigneeAddress: consigneeAdd.toUpperCase(),
          ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
          ConsigneeGst: consigneeGST.toUpperCase(),
          From: obj.From.toUpperCase(),
          To: obj.To.toUpperCase(),
          LrNo: obj.LR_No.toUpperCase(),
          InvoiceNo: obj.Bill_No.toUpperCase(),
          BookingAmount: parseInt(addFormValues.Booking_Amount), //Data accepts INT format
          Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
          Advance: parseInt(addFormValues.advance), //Data accepts INT format
          Balance: parseInt(balanceCal), //Data accepts INT format
          Total: totalCal,
          PaidBy: payTo,
          CreatedBy: localStorage.getItem("userId"),
        };
        axiosInstance
          .post(ADD_BOOKING, ADD_BOOKING_DATA)
          .then((response) => {
            ConfirormationModal(response.data.data.message, "success", 2000);
            history.push({
              pathname: "/pending-booking",
            });
          })
          .catch((error) => {
            ConfirormationModal(error.message, "error", 2000);
          });
      }
    });
  };
  // Builty checkbox click
  const builltyClick = (ev, path) => {
    ev.preventDefault();
    if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (consignorName === "") {
      setFormErrors({ consignorName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter Consigner Name` })
      );
      return;
    } else if (consigneeName === "") {
      setFormErrors({ consigneeName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Consignee Name` })
      );
      return;
    } else if (payTo === "") {
      setFormErrors({ payTo: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter Invoice Pay to` })
      );
      return;
    } else if (!addFormValues.From) {
      setFormErrors({ From: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please select From` }));
      return;
    } else if (!addFormValues.To) {
      setFormErrors({ To: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please select To` }));
      return;
    } else if (addFormValues.LR_No === "") {
      setFormErrors({ LR_No: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please select LR No` }));
      return;
    } else if (addFormValues.Bill_No === "") {
      setFormErrors({ Bill_No: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please select Bill No` }));
      return;
    }
    if (ev.target.checked === true) {
      setLoader({ ...loader, loading: true });
      let ADD_BOOKING_DATA = {
        LoadingDate: moment(LRDate).toDate(),
        VehicleNo: vehicleNumber.toUpperCase(),
        VehicleOwner: vehicleOwnerName,
        ConsignerName: consignorName.toUpperCase(),
        ConsignerAddress: consignorAdd.toUpperCase(),
        ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
        ConsignerGst: consignorGST.toUpperCase(),
        ConsigneeName: consigneeName.toUpperCase(),
        ConsigneeAddress: consigneeAdd.toUpperCase(),
        ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
        ConsigneeGst: consigneeGST.toUpperCase(),
        From: addFormValues.From.toUpperCase(),
        To: addFormValues.To.toUpperCase(),
        PaidBy: payTo,
        LrNo: addFormValues.LR_No.toUpperCase(),
        InvoiceNo: addFormValues.Bill_No,
        BookingAmount: parseInt(addFormValues.Booking_Amount), //Data accepts INT format
        Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
        Advance: parseInt(addFormValues.advance), //Data accepts INT format
        Balance: parseInt(balanceCal), //Data accepts INT format
        Total: totalCal,
        CreatedBy: localStorage.getItem("userId"),
      };
      // console.log(ADD_BOOKING_DATA)
      axiosInstance
        .post(ADD_BOOKING, ADD_BOOKING_DATA)
        .then((response) => {
          // console.log(response.data)
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: path,
            state: {
              bookingId: response.data.data.bookingId,
            },
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
    }
  };
  // Create blank buillty
  // const createBlanckBuillty = () => {
  //   // /bilty/billty-invoice-view
  //   let path = "/bilty/blank-buillty";
  //   history.push({
  //     pathname: path,
  //   });
  // };

  return (
    <>
      <DashBoardLayout pageTitle="Add Booking">
      <APILoader loader={loader} />
        <Notification
          show={notif}
          setShow={setNotif}
          {...notifOpts}
          supportContent={true}
        ></Notification>
        {/* {optionsComapny.length > 0 &&  */}
        <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
          <form onSubmit={AddBooking}>
            <div className="">
              <div className=" text-left space-y-6">
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="L. Date" isRequired="true" />
                    <div className="flex flex-row justify-center ">
                      {/* loadingDate */}
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                        selected={LRDate}
                        onChange={(date) => setLRDate(date)}
                        name="L_date"
                      />
                    </div>
                  </div>
                    {/* Vehicle No. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle No." isRequired="true" />
                    <SearchVehicleNo
                       defaultValue={vehicleNumber}
                      // vehicleOwnerId={vehicleOwnerId}
                       handleOnChange={handleVehicleOnChange}
                      />
                    {formErrors.vehicleNumber === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter vehicle Number
                      </p>
                    )}
                  </div>
                   {/* Vehicle Owner Name. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Owner" />
                    {/* <SearchVelicleOwner
                      newvehicleOwnerName={newvehicleOwnerName}
                      defaultValue={vehicleOwnerName}
                      handleOnChange={handleOnChange}
                    /> */}
                    <Input
                        name="vehicleOwnerName"
                        defaultValue={vehicleOwnerName}
                        required
                      />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Consignor's Name */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Name" isRequired="true" />
                    <CompanyInformation
                      name="cosignor"
                      defaultValue=""
                      handleOnChange={handleOnChangeCompany}
                    />
                    {formErrors.consignorName === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please select Consignor Name
                      </p>
                    )}
                  </div>
                  {/* Consignor's Address */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Address" isRequired="true" />
                    <Input
                      name="Consignor_Address"
                      type="text"
                      defaultValue={consignorAdd}
                      onChange={values_handler}
                      required
                    />
                  </div>
                  {/*Consignor's GST No.  */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's GST No." />
                    <Input
                      name="Consignor_GST_No"
                      type="text"
                      defaultValue={consignorGST}
                      onChange={values_handler}
                      required
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/*  Consignee's Name */}
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignee's Name" isRequired="true" />
                    <CompanyInformation
                      name="consignee"
                      defaultValue=""
                      handleOnChange={handleOnChangeCompany}
                    />
                    {formErrors.consigneeName === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please select Consignee Name
                      </p>
                    )}
                  </div>
                  {/* Consignee's Address */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Address" isRequired="true" />
                    <Input
                      name="Consignee_Address"
                      defaultValue={consigneeAdd}
                      onChange={values_handler}
                      required
                    />
                  </div>
                  {/* Consignee's GST No. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's GST No." />
                    <Input
                      name="Consignee_GST_No"
                      type="text"
                      defaultValue={consigneeGST}
                      onChange={values_handler}
                      required
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Consignor's Name */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Invoice Pay to" isRequired="true" />
                    <PayTo
                      name="payTo"
                      defaultValue=""
                      handleOnChange={handleOnChangePayto}
                    />
                    {formErrors.payTo === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please select Invoice Pay to
                      </p>
                    )}
                  </div>
                  {/* From */}
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="From" isRequired="true" />
                    <Input
                      name="From"
                      value={addFormValues.From || ""}
                      onChange={values_handler}
                      required
                    />
                    {formErrors.From === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter From
                      </p>
                    )}
                  </div>
                  {/*To Booking_Amount LR No */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="To" isRequired="true" />
                    <Input
                      name="To"
                      type="text"
                      onChange={values_handler}
                      required
                    />
                    {formErrors.To === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter To
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Booking Amount" isRequired="true" />
                    <Input
                      name="Booking_Amount"
                      type="number"
                      value={addFormValues.Booking_Amount || ""}
                      step="any"
                      onChange={values_handler}
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="LR No."/>
                    <Input
                      name="LR_No"
                      type="text"
                      onChange={values_handler}
                      // required
                    />
                    {formErrors.LR_No === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter LR Number
                      </p>
                    )}
                  </div>
                  {/* Select Bill No */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Company Invoice Number" isRequired="true" />
                    <Input
                      name="Bill_No"
                      type="text"
                      onChange={values_handler}
                      required
                    />
                    {formErrors.Bill_No === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter Bill Number
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/*Freight  */}
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Vehicle Freight" isRequired="true" />
                    <Input
                      type="number"
                      name="Freight"
                      // placeholder="Default value 0"
                      onChange={values_handler}
                      required
                    />
                  </div>
                  {/* Advance */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Advance" />
                    <Input
                      type="number"
                      name="advance"
                      // placeholder="Default value 0"
                      onChange={values_handler}
                      required
                    />
                  </div>
                  {/* Balance */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Balance" isRequired="true" />
                    <p
                      className="
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-gray-100 bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      {balanceCal}
                    </p>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Builty checkbox */}
                  {/* <div className=" flex   flex-col pt-2  text-left">
                    <Label label="Buillty" />
                    <Checkbox
                      className="accent-cyan-600	text-white"
                      onClick={(ev) => {
                        builltyClick(ev, "/bilty/add-builty");
                      }}
                    />
                  </div> */}
                  {/* Builty checkbox */}
                  <div className=" flex  flex-col pt-2  text-left">
                    <Label label="Add Buillty" />
                    <Checkbox
                      onClick={(ev) => {
                        builltyClick(ev, "/bilty/add-Multiple-builty");
                      }}
                      className="accent-cyan-600	text-white"
                    />
                  </div>
                  {/* Builty checkbox */}
                  {/* <div className=" flex  flex-col pt-2  text-left">
                    <Label label="Blank Buillty" />
                    <Checkbox
                      onClick={(ev) => {
                        createBlanckBuillty(ev);
                      }}
                      className="accent-cyan-600	text-white"
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="items-center mt-4">
              <ButtonWithBG theme="primary" label="SAVE" type="submit" />
            </div>
          </form>
        </div>
        {/* } */}
      </DashBoardLayout>
    </>
  );
}
