import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../Images/logo_green_transparent.png";
import logo_green_oposity from "../../Images/logo_green_oposity.png";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import moment from "moment";

const LCVPrint = ({ profileData, secondaryPhoneNo }) => {
  let location = useLocation();
  return (
    <>
      <div
        className="border-2 border-primary-kirtiroadways bg-center bg-no-repeat  bg-[length:65%]"
        style={{
          backgroundImage: "url(" + logo_green_oposity + ")",
        }}
      >
        <div className="">
          {/* logo and Kiti Roadlines Start */}
          <div className="flex flex-row space-x-0 mt-2 mx-2">
            <div>
              <img className="w-48 h-auto" src={Logo} alt="Transporter" />
            </div>
            <div className="flex flex-col  w-full">
              <div className="flex flex-row justify-end text-xl font-bold ">
                {/* <h1 className='ml-32'>|| श्री हरी ||</h1> */}
                <h1>Vehicle Loading Slip</h1>
              </div>
              <div className="text-5xl mt-2 text-right font-bold w-full  text-primary-kirtiroadways">
                {profileData.tagLine}
              </div>
            </div>
          </div>
          {/* logo and Kiti Roadlines End */}

          {/* LCV Start */}
          <div className="border-y-2 border-primary-kirtiroadways text-xl font-bold mt-2 text-center py-1">
            L. C. V
          </div>
          {/* LCV End */}

          {/* Address Start */}
          <div className="rounded-b-3xl rounded-t-lg font-bold p-2 w-full h-16 bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
          {profileData.address !== " " && (
            <p className="flex text-xs justify-center flex-row items-start px-2">
              <span className="text-xs w-3">{address_svg}</span>
              <span className="text-xs">{profileData.address}</span>
            </p>
          )}
            <div className="flex space-x-3 justify-center flex-row items-start ">
            {secondaryPhoneNo.length !== 0 && (
              <div className="flex space-x-1 text-xs justify-center flex-row items-center ">
                <span>{call_svg} </span>{" "}
                <span className="flex flex-row">
                  {secondaryPhoneNo.map((number, i) => (
                    <span
                      key={number}
                      className="text-sm font-semibold tracking-wider flex flex-row"
                    >
                      {(i ? ", " : "") + number}
                    </span>
                  ))}
                </span>
              </div>
            )}
            {profileData.email !== " " && (
              <div className="flex space-x-1 justify-center flex-row items-center ">
                <span>{email_svg} </span>
                <span>{profileData.email}</span>
              </div>
            )}
            </div>
          </div>
          {/* Address End */}
          {/* Main Form Start */}
          <div className="m-2 space-y-2">
            {/* No and date */}
            <div className="flex flex-row justify-between font-bold">
              <div className="flex flex-row ">
                <h1>No. :</h1>
                {/* <h1 className="ml-2">001</h1> */}
              </div>
              <div className="flex flex-row justify-center items-center space-x-2">
                <h1 className="text-lg">Date :</h1>
                {/* <h1>20-09-2022</h1> */}
                <h1 className="mt-1">
                  {moment(location?.data?.data?.loadingDate).format("DD-MM-YYYY")}
                </h1>
              </div>
            </div>
            {/* Name */}
            <div className="flex flex-row">
              <h1>M/S.</h1>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.transporterName}
              </div>
            </div>
            {/* Vehicle No */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit	text-left">
                <h1>We Are Sending Truck No.</h1>
              </div>
              <div className="w-full border-b-2 text-center border-b-primary-kirtiroadways font-bold">
                {location?.data?.data?.vehicleNo}
              </div>
            </div>
            {/* From to  */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Please Load From</h1>
              <div className="w-full border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.from}
              </div>
              <h1 className="mx-2 text-left">To,</h1>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.to}
              </div>
            </div>
            {/* goods and garantee */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">of Goods</h1>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.goods}
              </div>
              <h1 className="min-w-fit text-center">Guarantee</h1>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.guarantee}
              </div>
            </div>
            {/* full loads */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit text-left">
                Full Load has been Settled with
              </div>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.loadSettledWith}
              </div>
            </div>
            {/* rate rs and total fright */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit text-left">Rate Rs.</div>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.rates}
              </div>
              <div className="min-w-fit text-center">Total Freigth Rs.</div>
              <div className="w-full border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.transporterFreigth}
              </div>
            </div>
            {/* Adance and balance */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Advance Rs.</h1>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                {location?.data?.data?.transporterAdvance}
              </div>
              <div className="text-left min-w-fit">Balance:</div>
              <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">{location?.data?.data?.transporterBalance}
              </div>
            </div>
            {/* extra note */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Thanks</h1>
            </div>
          </div>

          {/* Note Start */}
          <div className="text-xs p-1 border-y-2 border-primary-kirtiroadways align-center text-center">
            <span className="text-xs font-bold">Note :</span> गाड़ी भरने के वक्त गाड़ी की
            R.C. औरा PAN CARD की ज़ेरॉक्स की कॉपी लेना ट्रांसपोर्ट की जिमेदारी रहेगी
          </div>
          {/* Note End */}

          {/* Signature Box Start */}
          <div className="flex items-center  mx-2 flex-row justify-between">
            <div className="text-2xl font-bold  text-primary-kirtiroadways">
              No Varai, No Hamali
            </div>
            <div className="text-lg flex flex-col items-center ">
              <p className="pt-10">Yours Faithfully</p>
              <p className="font-bold">For, Kirti Roadlines</p>
            </div>
          </div>
          {/* Signature Box End */}

          {/* Last content Start */}
          <div className="border-t-2 border-primary-kirtiroadways">
            <div className="mx-2">
              <p className="text-lg text-left font-bold">NOTE :</p>
              <div className="text-base	text-left">
                <ol className="list-decimal list-outside ml-4">
                  <li>
                    Please Check up Engine & Chassis No. R.T.O. Book C.S.T. No.
                    Etc for your Satisfaction
                  </li>
                  <li>
                    We are not Responsible of Accident, Leakage & Breakage
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* Last content End */}
        </div>
      </div>
    </>
  );
};

export default LCVPrint;
