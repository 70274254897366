import React, { useEffect, useRef } from "react";
import logo from "../../../Images/logo_green_transparent.png";
import logo_oposity from "../../../Images/logo_green_oposity.png";
import { useLocation } from "react-router-dom";
import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
  OURBANKDETAILS,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import ReactToPrint from "react-to-print";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import moment from "moment";

const TransporterInvoicePrint = (props) => {
  const location = useLocation();
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  let componentRef = useRef();
  //   const pageStyle = `@page {
  //     size: A4;
  //     margin: 10pt;
  //   }
  //   @media print {
  //     .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  // }
  //   }`;
  useEffect(() => {
    // console.log(props, "print");
  }, [props]);
  return (
    <>
      {props?.data && (
        <div className="hidden">
          <div ref={(el) => (componentRef = el)}>
            <div
              className="pageStyle  block border-double border-4 border-cyan-200 align-top m-2 text-center bg-center bg-no-repeat  bg-[length:65%]"
              style={{
                backgroundImage: "url(" + logo_oposity + ")",
              }}
            >
              <div className="mt-2">
                <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
                  <h5>Tax Invoice</h5>
                </div>
                {/* Logo and Title Start */}
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-48 ml-2">
                        <img src={logo} alt="Transporter" />
                      </div>
                      <div className=" flex flex-col ">
                        <span
                          style={{ fontSize: "70px", lineHeight: "1" }}
                          className="text-primary-kirtiroadways font-extrabold "
                        >
                          {props.profileData.tagLine}
                        </span>
                        <span className="text-xl font-serif text-black">
                          {props.profileData.subTagLine}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div className="rounded-b-3xl rounded-t-lg font-bold h-16 p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
                      {props.profileData.address !== " " && (
                        <p className="flex  text-md justify-center flex-row items-start">
                          <span className="text-xs w-3">{address_svg}</span>{" "}
                          <span className="text-xs ml-1">
                            {props.profileData.address}
                          </span>
                        </p>
                      )}
                      <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                        {props.secondaryPhoneNo.length !== 0 && (
                          <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                            <span>{call_svg} </span>{" "}
                            {props.secondaryPhoneNo.map((number, i) => (
                              <span
                                key={number}
                                className="text-sm font-semibold tracking-wider flex flex-row"
                              >
                                {(i ? ", " : "") + number}
                              </span>
                            ))}
                          </div>
                        )}
                        {props.profileData.email !== " " && (
                          <div className="flex space-x-1 justify-center flex-row items-center ">
                            <span>{email_svg} </span>
                            <span>{props.profileData.email}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Address End */}
                  </div>
                </div>
                {/* Logo and Title End */}
              </div>
              {/* Invoice  No and details*/}
              <div className="p-2 flex font-sans flex-row justify-between">
                <div className="flex flex-col text-left w-1/2">
                  <div className="uppercase font-bold text-md	py-1 mb-0 px-5 w-36 text-white rounded-lg bg-primary-kirtiroadways">
                    Invoice to
                  </div>
                  <div>
                    <h1 className="text-left font-bold uppercase font-sans text-xl	subpixel-antialiased tracking-wide mt-0	">
                      {props?.name.toUpperCase()}
                    </h1>
                  </div>
                  <div className="text-xs text-left">
                    <div className="flex  flex-row space-x-1 mt-1">
                      <p className="w-14">Address :</p>
                      <p className="w-1/2 ">{props?.address.toUpperCase()}</p>
                    </div>
                  </div>
                  {/* <div className="text-sm mt-1">
                    <div className="flex flex-row space-x-1 text-left">
                      <p className="">PAN :</p>
                      <p className="w-1/2">{props?.data?.panNo}</p>
                    </div>
                  </div> */}
                </div>

                {/* Right side */}
                <div className="flex  flex-col text-right justify-end items-end">
                  <span className="uppercase text-md	py-1 mb-0 px-5  text-white rounded-lg bg-primary-kirtiroadways font-bold">
                    OUR Details
                  </span>
                  <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
                    <p className="flex flex-col">
                      <span>Service : {props.profileData.service}</span>
                      <span>Pan No. : {props.profileData.panNo}</span>
                      <span>State Code : {props.profileData.stateCode}</span>
                    </p>
                    {/* <p className="flex flex-col">
                      <span>Transportation</span>
                      <span>AJGPD9182R</span>
                      <span>24</span>
                    </p> */}
                  </div>

                  <div className=" uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 border-primary-kirtiroadways">
                    <div className="flex flex-row text-sm text-right space-x-12">
                      <span className="flex flex-col ">
                        <p className="text-right min-w-1/2">Invoice No : --</p>
                        {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                        <p className="text-right min-w-1/2">
                          Date : {Today_Date}
                        </p>
                        {/* <p className="text-right w-1/2">Transportation</p> */}
                      </span>
                      {/* <span className="flex flex-col "> */}
                      {/* <p className="text-left min-w-1/2">--</p> */}
                      {/* <p className="text-left min-w-1/2">--</p> */}
                      {/* <p className="text-left min-w-1/2">{Today_Date}</p> */}
                      {/* <p className="text-right w-1/2">Transportation</p> */}
                      {/* </span> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Table start*/}
              <div
                style={{ height: "280px" }}
                className="p-2 w-full border-t-0 border-neutral-900"
              >
                <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-xs font-sans">
                  {/* header */}
                  <thead style={{ maxHeight: "5px" }} className="mb-2">
                    <tr className=" border-neutral-900 text-white ">
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-slate-800	 border-neutral-900 "
                      >
                        Loading
                        <br />
                        Date
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-slate-800	 border-neutral-900"
                      >
                        From
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-slate-800	 border-neutral-900"
                      >
                        To
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-slate-800	 border-neutral-900"
                      >
                        Fright
                      </th>
                      <th className="bg-primary-kirtiroadways border-neutral-900">
                        Advance
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900"
                      >
                        Balance
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900"
                      >
                        Hamali
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900"
                      >
                        Detention
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900"
                      >
                        Extra
                        <br />
                        Charge
                      </th>
                      {/* <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900 pr-2"
                      >
                        Total
                      </th> */}
                    </tr>
                  </thead>
                  {/* Rows map function */}
                  <tbody className="text-xs font-bold align-top text-center bg-bottom bg-no-repeat bg-[length:80%_90%]">
                    {props?.data?.commission.map((data, i) => (
                      <tr height="20px" className="border-neutral-900 " key={i}>
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 items-center w-28"
                        >
                          <span className="mt-2">
                            {moment(data?.loadingDate).format("DD-MM-YYYY")}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className=" border-r-2 border-gray-300 w-32"
                        >
                          <span className="mt-2">
                            {data?.from.toUpperCase()}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className=" border-r-2 border-gray-300 w-32"
                        >
                          <span className="mt-2">{data?.to.toUpperCase()}</span>
                        </td>
                        <td
                          height="20px"
                          className=" border-r-2 border-gray-300 w-28"
                        >
                          <span className="ml-2 mt-2 text-center">
                            {data?.transporterFreigth
                              ? data?.transporterFreigth
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 w-28"
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterAdvance
                              ? data?.transporterAdvance
                              : 0}
                          </span>
                        </td>
                        <td height="20px" className="w-28">
                          <span className="mt-2 text-center">
                            {" "}
                            {data?.transporterBalance
                              ? data?.transporterBalance
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 w-28"
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterHamali
                              ? data?.transporterHamali
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 w-28"
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterDetention
                              ? data?.transporterDetention
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 w-28 mr-2"
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterExtraCharge
                              ? data?.transporterExtraCharge
                              : 0}
                          </span>
                        </td>
                        {/* <td height="20px" className=" w-28 mr-2">
                          <span className="mt-2 text-center">
                            {data?.transporterTotal
                              ? data?.transporterTotal
                              : 0}
                          </span>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Total Calculation */}
              {/* <table className="  w-full font-bold   font-sans">
                <thead style={{ maxHeight: "5px" }}>
                  <tr height="20px" className="border-neutral-900 ">
                    <td height="20px" className="w-20"></td>
                    <td height="20px" className="w-20"></td>
                    <td height="20px" className="w-20"></td>
                    <td height="20px" className="w-24"></td>
                    <td height="20px" className="w-24"></td>

                    <td height="20px" className="w-24">
                      <h1 className="font-bold  text-center text-white bg-primary-kirtiroadways py-1">
                        Total
                      </h1>
                    </td>
                    <td height="20px" className="w-24">
                      <h1 className="text-center font-bold text-white bg-primary-kirtiroadways py-1">
                        {props?.data?.totalBalance
                          ? props?.data?.totalBalance
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-24">
                      <h1 className="text-center font-bold text-white bg-primary-kirtiroadways py-1">
                        {props?.data?.totalHamali
                          ? props?.data?.totalHamali
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-24">
                      <h1 className="text-center font-bold text-white bg-primary-kirtiroadways py-1">
                        {props?.data?.totalDetention
                          ? props?.data?.totalDetention
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-24">
                      <h1 className="text-center font-bold text-white bg-primary-kirtiroadways py-1">
                        {props?.data?.totalExtraCharge
                          ? props?.data?.totalExtraCharge
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-24">
                      <h1 className="text-center font-bold text-white bg-primary-kirtiroadways py-1">
                        {props?.data?.grandTotal ? props?.data?.grandTotal : 0}
                      </h1>
                    </td>
                  </tr>
                </thead>
              </table> */}

              {/* Bannk Detail Start */}
              <div className="flex flex-row justify-between font-sans font-semibold text-left text-xs mx-2">
                <div className="mt-4 ">
                  <div className="flex flex-row space-x-2 ">
                    <h5 className="px-5 py-2 tracking-wide text-sm rounded-lg uppercase bg-primary-kirtiroadways text-white">
                      {OURBANKDETAILS}
                    </h5>
                  </div>
                  <div className="flex flex-row space-x-1 mt-2">
                    <div className="space-y-1">
                      <h5>Bank Name</h5>
                      <h5>Branch</h5>
                      <h5>A/c. No</h5>
                      <h5>IFSC Code</h5>
                    </div>
                    {props?.Dropdown === false ? (
                      <div className="space-y-1">
                        <h5>: State Bank of India</h5>
                        <h5>: Prahlad Nagar Ahmedabad</h5>
                        <h5>: 005167983000351</h5>
                        <h5>: SBIT0CKMNSB (FIFT CHARACTER IS ZERO)</h5>
                      </div>
                    ) : (
                      <div className="space-y-1">
                        <h5>: {props?.bankBranch?.bank}</h5>
                        <h5>: {props?.bankBranch?.branch}</h5>
                        <h5>: {props?.bankBranch?.accNo}</h5>
                        <h5>: {props?.bankBranch?.ifsc}</h5>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row  justify-end mt-4 border-t-2 w-1/3 border-primary-kirtiroadways">
                  <div className="space-y-1  mt-2  text-right w-32">
                    <h5 className="pr-2">TOTAL :</h5>
                    <div className="h-14">
                      {location?.data?.totalHamali > 0 && (
                        <h5 className=" pr-2">Hamali :</h5>
                      )}
                      {location?.data?.totalDetention > 0 && (
                        <h5 className="pr-2">Detention :</h5>
                      )}
                      {location?.data?.totalExtraCharge > 0 && (
                        <h5 className=" pr-2">Extra Charge :</h5>
                      )}
                    </div>
                    <h5 className="pr-2 text-sm	 px-2 py-2 tracking-wide  w-full rounded-l-lg uppercase bg-primary-kirtiroadways text-white">
                      Grand Total
                    </h5>
                  </div>
                  <div className="space-y-1 mt-2 text-right w-28">
                    <h5>
                      &#8377;{" "}
                      {location?.data?.totalBalance
                        ? location?.data?.totalBalance
                        : 0}
                    </h5>
                    <div className="h-14">
                      {location?.data?.totalHamali > 0 && (
                        <h5>
                          &#8377;{" "}
                          {location?.data?.totalHamali
                            ? location?.data?.totalHamali
                            : 0}
                        </h5>
                      )}
                      {location?.data?.totalDetention > 0 && (
                        <h5>
                          &#8377;{" "}
                          {location?.data?.totalDetention
                            ? location?.data?.totalDetention
                            : 0}
                        </h5>
                      )}
                      {location?.data?.totalExtraCharge > 0 && (
                        <h5>
                          {" "}
                          &#8377;{" "}
                          {location?.data?.totalExtraCharge
                            ? location?.data?.totalExtraCharge
                            : 0}
                        </h5>
                      )}
                    </div>
                    <h5 className="border-l-2 text-sm  border-white pr-2 px-5 py-2 tracking-wide b-r-2 w-full rounded-r-lg uppercase bg-primary-kirtiroadways text-white">
                      &#8377;{" "}
                      {location?.data?.grandTotal
                        ? location?.data?.grandTotal
                        : 0}
                    </h5>
                  </div>
                </div>
              </div>

              {/* Extra Information */}
              <div className="flex flex-col justify-start items-start font-sans border-t-2 mx-2 border-primary-kirtiroadways mt-2">
                <p>Amount Chargeable (in words)</p>
                <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                  {/* Four Thousand Four Hundred Fifty */}
                  {props?.data?.grandTotalInWords}
                  {""}ONLY
                </p>
              </div>
              {/* terms and condition */}
              <div className="flex pb-4 flex-row justify-between m-2 font-sans">
                <div className="text-left flex flex-col">
                  <p className="border-black border-b-4 w-36 pb-1">
                    Terms & Conditions
                  </p>
                  <p>{InvoicSubject}</p>
                </div>
                <div className="text-left text-sm">E. & O.E.</div>
                <div className="text-left text-sm ">FOR,</div>
                <div className="text-lg font-bold text-right ">
                  {props.profileData.tagLine}
                </div>
              </div>

              {/* Note */}
              {/* <div className="flex border-black border-b-2 flex-row mt-5 justify-between items-start m-2 font-sans text-sm">
                <div className="text-left flex flex-col w-1/2 ">
                  {props?.note && (
                    <p className="border-black border-x-2 border-t-2 p-2">
                      {props?.note}
                    </p>
                  )}
                </div>
                <div className="text-center">{KIRTIROADLINES}</div>
                <div className="text-right  font-bold ">
                  <h5>{AuthorizedSignatory}</h5>
                </div>
              </div> */}

              <div className="flex border-black border-b-2 flex-row mt-5 justify-between items-start mb-2 mx-2 font-sans text-xs">
                <div className="text-left flex flex-col">
                  <p>{PaymentbypayeeCchequeDrafs}</p>
                </div>
                <div className="text-center">{props.profileData.tagLine}</div>
                <div className="text-right  font-bold ">
                  <h5>{AuthorizedSignatory}</h5>
                </div>
              </div>

              <h2 className="text-xs mt-0 mb-0 text-center font-bold bg-primary-kirtiroadways rounded-md py-2 text-white font-sans">
                {computerGeneratedInvoice}
              </h2>
            </div>
          </div>
        </div>
      )}
      {/* printing */}
      {/* <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
        <style>{pageStyle}</style>
        <ReactToPrint
          documentTitle={`Transporter Invoice of ` + props?.data?.ownerName}
          trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
          content={() => componentRef}
        />
      </div> */}
      <div className="mb-5 flex flex-row justify-center items-center space-x-5 mt-10">
        <ReactToPrint
          // pageStyle = {pageStyle}
          documentTitle={`Transporter Invoice of ` + props?.data?.ownerName}
          trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
          content={() => componentRef}
        />
      </div>
    </>
  );
};

export default TransporterInvoicePrint;
