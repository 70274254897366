import React from "react";
// API URL
export const APIURL = "https://kirtiroadlines-dev.azurewebsites.net/";

// Dashboard side nav for user
export const DASHBOARD_SIDE_NAV = [
  {
    id: 0,
    title: "Dashboard",
    route: "/dashboard",
    main: "dashboard",
    isActive: true,
    path: "M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z",
  },
  {
    id: 1,
    title: "Booking",
    route: "/pending-booking",
    main: "Booking",
    isActive: true,
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
      />
    ),
  },
  {
    id: 2,
    title: "Transporter",
    route: "/commission",
    main: "Transporter",
    isActive: true,
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
      />
    ),
  },
  {
    id: 3,
    title: "Vehicle Owner",
    route: "/vehicle",
    main: "Vehicle Owner",
    isActive: true,
    path: "M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12",
  },
  {
    id: 4,
    title: "Ledger",
    route: "/kirtiroadline-ledger",
    main: "kirtiroadline",
    isActive: true,
    path: "M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
  },
  {
    id: 5,
    title: "Profile",
    route: "/profile",
    main: "profile",
    isActive: true,
    path: "M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z",
  },
];

// Dropdown options
export const CommissionOptions = [
  { value: "5% fix", label: "5%" },
  { value: "Manual", label: "Manual" },
  { value: "Not Applicable", label: "Not Applicable" },
];

export const PaytoOptions = [
  { value: "Consignor", label: "Consignor" },
  { value: "Consignee", label: "Consignee" },
  // { value: "Both", label: "Both" },
];

export const LedgerTypeOptions = [
  { value: "Booking", label: "Booking" },
  { value: "Commission", label: "Commission" },
  { value: "Both", label: "Both" },
];

export const LedgerTypesOptions = [
  { value: "Booking", label: "Booking" },
  { value: "Commission", label: "Commission" },
];

export const WeightUnitOptions = [
  { value: "KG", label: "KG" },
  { value: "Liter", label: "Liter" },
  { value: "Quintal", label: "Quintal" },
  { value: "Unit", label: "Unit" },
  { value: "Tan ", label: "Tan" },
];

export const SEARCH_PLACEHOLDER = "What car are you searching?";
// invoice content
export const Invoice = "INVOICE";
export const InvoicSubject = "Subject To Ahmedabad Jurisdiction";
export const InvoiceDeclartion = "GOODS CONSIGNMENT AT OWNER'S RISK";
export const InvoiceBilltyNote = "The invoice should not be paid to the driver.";
export const KIRTIROADLINES = "KIRTI ROADLINES";
export const KIRTIROADLINESTAGLINE =
  "Transport Contractor and Commission Agent";
export const OfficeNo = "Office No.";
export const OfficeAdd =
  "19 Second Floor Sarovar Arcade Opp Kesari Nandan Hotel Near Hathijan Chokdi S.P Ring Road Ahmedabad-382445";
export const Mo = "Mo.:";
export const Mo1 = "9898055419";
export const Mo2 = "9898005419";
export const Mo3 = "9998870054";
export const email = "Email:";
export const emailID = "kirtiroadlines0130@gmail.com";
export const CAUTION = "CAUTION";
export const CAUTIONContnet =
  "The Consigment will not be detained diverted, re-riuted or re-booked without consignee bank write and permission will be delivered at the destination";
export const PANNo = "PAN No. : ";
export const OwnerRiskInsurance = "AT OWNER'S RISK INSURANCE";
export const CustomerDeclaration =
  "The customer has stared that he has insured the consigment";
export const CompanyName = "Company Name";
export const receiptDeclaration =
  "The Consignment covered by the set of special Lorry receipt from shall be stored at the Transport operator and shall be delivered  to or to the order of the Consignee Bank Whose Name is mentioned in the Lorry Receipt it will under no cirumstance be delivered to any on wiyhout the written authority from the consignee bank or its order endorsed one the Consignee copy or on a separate letter of authority.";
export const CONSIGNMENTNOTE = "CONSIGNMENT NOTE";
export const ConsignorNameAdd = "Consignor's Name & Address:-";
export const ConsignorName = "Consignor's Name:-";
export const Name = "Name :";
export const ConsignorAdd = "Consignor's Address:-";
export const Address = "Address :";
export const ConsigneeNameAdd = " Consignee's Name Address:-";
export const SurveySection =
  "SURVEY NO. 615/1, 616, 617, 618P, 633, 634/1 TO 4, VILLAGE VERSAMEDI TALUKA ANJAR KATCH, GUJARAT";
export const CompanyResponsible =
  "The Company is Not Responsive for Leakage, Breakage & illegal goods.";
export const TermsandCondition =
  "TERM'S & CONDITION'S OF CARRIAGE OWNER'S RISK";
export const TermsandConditionNote =
  " Where the Goods have been lost destroyed, damaged or have deteriorated, the compensation shall not be payble by the Transport Operator. If nature calamit or any type of accident occur the both party Consignor and consignee are Liable for the goods payments.";
export const AuthorizedSignatory = "Authorized Signatory";
export const computerGeneratedInvoice = "This is a computer Generated Invoice";
export const PaymentbypayeeCchequeDrafs =
  "Payment by payee's A/C cheque / Drafs only in favour of the";
export const AmountChargeableInWords = "Amount Chargeable (in Words)";
export const OURBANKDETAILS = "OUR BANK DETAILS";
export const SetAnotherBankDetails = "Set Another Bank Details";
export const THANKYOUFORBUSSINESSWITHUS = "THANK YOU FOR BUSSINESS WITH US";
export const ServiceTaxLiabilityConsignor =
  "Service Tax Liability of Consignor";
export const VehicleClickDescription = "Please click on Owner's Name to redirect vehicle details.";
export const SupportContent = "Contact support for further assistance!";
export const REMOVE_CONFORM = "Are you sure you want to remove this";

// Commission Invoice 
export const TOTALPAYMENTPWNER ="Total Payment to Truck Owners"
export const LESSCOMMISSION ="Less Kirti Roadlines Commission"
export const TOTALPAIDDRIVER ="Total Amount paid to Truck Driver"
export const PAYMENTDATE ="Date of the Payment"
export const BANKNAME ="Bank Name"
export const CHECKNEFT ="Check or NEFT Name"
export const GPAYCONTENT ="We are using gpay/phonepay/paytm number is 9898005419 "
export const PAYMENTBY ="Payment by payee's A/C Cheque / Drafs only in favour of the"
// Data for Balance
export const BALANCE_DATA = [
  {
    Date: "25-08-2022",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "300",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Kamlesh Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Clear",
  },
  {
    Date: "20-07-2022",
    L_Date: "30.08.2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "600",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Arvind Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Not Clear",
  },
  {
    Date: "22-10-2021",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "300",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Himanshu Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Clear",
  },
  {
    Date: "03-01-2000",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "400",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Palak Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Not Clear",
  },
  {
    Date: "05-05-2010",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "500",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Pranjal Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Clear",
  },
  {
    Date: "10-10-2015",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "600",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Ishan Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Clear",
  },
  {
    Date: "27-08-2002",
    L_Date: "30-08-2022",
    Truck_no: "GJ3A2550",
    Company_Name: "Ahmedabad Pvt. Ltd",
    From: "Ahmedabad",
    To: "Patna",
    Booking_Amount: "45000",
    LR_No: "BR33",
    Bill_No: "12345",
    Freight: "500",
    Advance: "25000",
    Balance: "30000",
    Hamali: "---",
    Detection: "---",
    Extra_Charge: "15000",
    total: "50000",
    Vehicle_Owner_Name: "Rajveer Singh",
    Driver_Paid_Balance: "5000",
    Balance_Paid_Bill_No: "Ap12345",
    Billty_Received_Or_Not: "Yes",
    Note: "Balance Clear",
  },
];

// Data for Driver
export const DRIVER_DATA = [
  {
    Driver_Name: "Shahrukh Khan",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
  {
    Driver_Name: "Amir Khan",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
  {
    Driver_Name: "Salman Kham",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
  {
    Driver_Name: "Karan Johar",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
  {
    Driver_Name: "Rahul Gandhi",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
  {
    Driver_Name: "Ram Lal",
    Driver_Mobile: "9999999999",
    Driver_Paid_Balance: "15000",
  },
];

