import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import {
  ADD_TRANSPORTER_PAYMENT,
  TRANSPORTER_INVOICE,
  TRANSPORTER_PRINT_INVOICE,
} from "../../Utils/ApiGlobal";
// import { Lendger } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
import moment from "moment";
import Loader from "../UI/Loader";
import Checkbox from "../UI/Checkbox";
import ButtonWithBG from "../UI/ButtonMain";
import { useHistory } from "react-router-dom";
import CategoryPill from "../UI/CategoryPill";
import { ConfirormationModal } from "../UI/ConfirormationModal";

export default function TransporterInvoiceTable(props) {
  // for Search
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [transporterData, setTransporterData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [hamali, setHamali] = useState(0);
  const [detention, setDetention] = useState(0);
  const [balance, setBalance] = useState(0);
  const [total, setTotal] = useState(0);
  // const [totalPaidToOwner, setTotalPaidToOwner] = useState(0);
  const [error, setError] = useState(false);
  const [note, setNote] = useState();
  const [transporterAddress, setTransporterAddress] = useState();
  const [transporterName, setTransporterName] = useState();

  const columns = [
    {
      Header: "",
      id: "invoiceId",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <Checkbox
            onClick={(e) => {
              // var invoicePrint_array = [...invoicePrint];
              // if (e.target.checked=== true) {
              //   invoicePrint_array = [...invoicePrint, e.target.value];
              //   console.log(invoicePrint_array, " console.log(invoicePrint);");
              //   // let Id= row.row.original.id;
              //   // let Type = row.row.original.type;
              //   //   setInvoicePrint({
              //   //     ...invoicePrint,
              //   //     id: Id,
              //   //     type:Type
              //   //   })
              // } else {
              //   invoicePrint_array.splice(
              //     invoicePrint.indexOf(e.target.value),
              //     1
              //   );
              // }
              // setInvoicePrint(invoicePrint_array);
            }}
            name={row.row.original.type}
            value={row.row.original.commissionId}
            className="accent-cyan-600	text-white"
          />
        </div>
      ),
    },
    {
      Header: "Payment Status",
      id: "Payment Status",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Cell: (row) => (
        <CategoryPill
          onChangeValue={(e, id, type) => {
            UpdatePaymentMethod(e, id, type);
          }}
          Pending={row?.row?.original?.isTransporterPaid}
          id={row?.row?.original?.commissionId}
          type={row?.row?.original?.type}
        />
      ),
    },
    {
      Header: "Date",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "From",
      accessor: "from",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "To",
      accessor: "to",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "Fright",
      accessor: "transporterFreigth",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
    },
    {
      Header: "Advance",
      accessor: "transporterAdvance",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Footer: <span className="font-semibold text-left ">Total</span>,
    },
    {
      Header: "Balance",
      accessor: "transporterBalance",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">{balance}</span>,
    },
    {
      Header: "Hamali",
      accessor: (d) => {
        if (d.transporterHamali !== null) {
          return d.transporterHamali;
        } else {
          return 0;
        }
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Footer: <span className="font-semibold text-left ">{hamali}</span>,
    },
    {
      Header: "Detention",
      accessor: (d) => {
        if (d.transporterDetention !== null) {
          return d.transporterDetention;
        } else {
          return 0;
        }
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Footer: <span className="font-semibold text-left ">{detention}</span>,
    },
    {
      Header: "Extra Charge",
      accessor: (d) => {
        if (d.transporterExtraCharge !== null) {
          return d.transporterExtraCharge;
        } else {
          return 0;
        }
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Footer: <span className="font-semibold text-left ">{detention}</span>,
    },
    {
      Header: "Total",
      accessor: "transporterTotal",
      maxWidth: 110,
      minWidth: 110,
      width: 110,
      Footer: <span className="font-semibold text-left">{total} Dr</span>,
    },
  ];
  useEffect(() => {
    // setTransporterId(localStorage.getItem("id"));
    getTransporterInvoice(localStorage.getItem("id"));
  }, [props?.startDate, props?.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get API
  const getTransporterInvoice = async (FetchTransporterId) => {
    setIsLoading(true);
    let StartDate =
      props?.startDate === undefined
        ? ""
        : moment(props?.startDate).format("YYYY-MM-DD");
    let EndDate =
      props?.endDate === undefined
        ? ""
        : moment(props?.endDate).format("YYYY-MM-DD");
    await axiosInstance
      .get(
        `${TRANSPORTER_INVOICE}?transporterId=${FetchTransporterId}&startDate=${StartDate}&endDate=${EndDate}`
      )
      .then((response) => {
        // console.log(response);
        setInvoiceData(response?.data?.data);
        setTransporterData(response?.data?.data?.commission);
        setTransporterAddress(response?.data?.data?.commission[0]?.address);
        setTransporterName(
          response?.data?.data?.commission[0]?.transporterName
        );
        setHamali(response?.data?.data?.totalHamali);
        setDetention(response?.data?.data?.totalDetention);
        setBalance(response?.data?.data?.totalBalance);
        setTotal(response?.data?.data?.grandTotal);
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
        return;
      });
  };
  // Change Payment status
  const UpdatePaymentMethod = async (e, id, type) => {
    // console.log(e)
    // console.log(id)
    // console.log(type)
    // setIsLoading(true);
    // props?.ownerId
    let paymentData = {
      Id: id,
      IsPaid: true
    };
    await axiosInstance
      .post(ADD_TRANSPORTER_PAYMENT, paymentData)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };
  // Get Print Invoice API
  const PrintBtnClick = async () => {
    setIsLoading(true);
    let checkedBoxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );
    let InvoicesPara = Array.from(checkedBoxes).map((x) => {
      return x.value;
    });
    let InvoiceDetails = {
      TransporterId: localStorage.getItem("id"),
      Invoices: InvoicesPara,
    };
    // TransporterInvoiceView
    await axiosInstance
      .post(TRANSPORTER_PRINT_INVOICE, InvoiceDetails)
      .then((response) => {
        // console.log(response);
        if (response?.data?.data?.grandTotal) {
          history.push({
            pathname: "/transporter/transporter-invoice-view",
            data: response?.data?.data,
            address: { transporterAddress },
            name: { transporterName },
            note: { note },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
      });
  };
  return (
    <section>
      {isLoading && invoiceData ? (
        <Loader />
      ) : (
        <div className="mt-4">
          {error ? (
            <p className="text-warn-error">No data found</p>
          ) : (
            <>
              {/* Table call */}
              <TablePagination
                noPagination
                data={transporterData}
                columns={columns}
                isFooter
              />
              {/* Calculation of  Total Amount paid to Truck Driver*/}
              <div className="flex flex-row justify-between mt-4">
                {/* Note input to show information in invoice print */}
                <div className="flex flex row space-x-2 w-1/2">
                  <p className="text-sm">Note:</p>
                  <textarea
                    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                    name="Note"
                    id=""
                    cols="30"
                    rows="1"
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    type="text"
                  ></textarea>
                </div>
              </div>
              <div className="flex mt-10 flex-row space-x-2 justify-center items-center">
                {/* {invoiceData?.details && ( */}
                <ButtonWithBG
                  label="Genrate Invoice"
                  handleClick={PrintBtnClick}
                ></ButtonWithBG>

                {/* <ButtonWithBG
                  theme="secondary"
                  label="Invoice Paid"
                  onClick={(e) => {
                    PrintBtnClick();
                  }}
                ></ButtonWithBG> */}
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
}
