import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TransporterTable from "../../Components/DataTables/TransporterTable";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { setting_svg } from "../../Utils/SVGListing";

export default function Transporter() {
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/transporter/add-edit-transporter",
      state: { label: "Add" },
    });
  };
  return (
    <DashBoardLayout pageTitle="Transporter">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex flex-row justify-between ">
          <div>
            <SearchField
              setSearchValue={setSearchValue}
              placeholder="Search BY Transporter Name"
              handleChange={setSearchValue}
              value={searchValue}
            />
          </div>
          <div className="flex flex-row space-x-2  items-start">
            <Button svg={setting_svg}></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Transporter"
            />
          </div>
        </div>
      </div>
      <TransporterTable searchValue={searchValue} />
    </DashBoardLayout>
  );
}
