import React, { useState, useEffect } from 'react'
import DashBoardLayout from '../../Layouts/private/DashboardLayout';
import Card from './Card';
// import SearchField from '../../Components/UI/SearchField';
import LineChart from './LineChart';
import { SVG_PENDING, BILLTY_SVG, Billing_Svg, commison_svg } from '../../Utils/SVGListing';
import axiosInstance from "../../Utils/API";
import { GET_DASHBOARD_LIST } from '../../Utils/ApiGlobal';
import Loader from "../../Components/UI/Loader"


export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _GET_DASHBOARD_DATA();
    // eslint-disable-next-line
  }, []);
  // GET API
  const _GET_DASHBOARD_DATA = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_DASHBOARD_LIST}`
      )
      .then((response) => {
        // console.log(response?.data?.data);
        const data = response?.data?.data;
        setDashboardData(data);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
        <DashBoardLayout pageTitle="Dashboard">
        {isLoading ? (<Loader />) : (
          <div>
            {/* <div className='md:w-80 w-full'>
              <SearchField
                placeholder="Search"
              handleChange={setSearchValue}
              value={searchValue}
              />
            </div> */}
            <section className="text-gray-700 body-font">
              <div className="container px-5 py-10 mx-auto">
                <div className="flex flex-wrap -m-4 text-center">
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card title="Booking" svg={SVG_PENDING} countData={dashboardData.booking} path="/pending-booking"/>
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card title="Vehicle Owner" svg={BILLTY_SVG} countData={dashboardData.vehicleowner} path="/vehicle"/>
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card title="Commission" svg={Billing_Svg} countData={dashboardData.comission} path="/commission-entry"/>
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card title="Company" svg={commison_svg} countData={dashboardData.company} path="/company"/>
                  </div>
                </div>
              </div>
            </section>
            <div className='flex justify-center mb-10'>
              <div className='w-full'>
                <LineChart chartData={dashboardData} />
              </div>
            </div>
          </div>
           )}
        </DashBoardLayout>
    </>
  )
}
