import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import logo from "../../Images/logo_green_transparent.png";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  // ConsigneeNameAdd,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  // email,
  emailID,
  InvoiceDeclartion,
  InvoicSubject,
  KIRTIROADLINES,
  InvoiceBilltyNote,
  KIRTIROADLINESTAGLINE,
  // Mo,
  Mo1,
  Mo2,
  Mo3,
  OfficeAdd,
  OfficeNo,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  // ConsignorName,
  // Name,
  Address,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import ButtonWithBG from "./ButtonMain";
// import InvoicePrint from "../../Components/UI/InvoicePrint";

const PrintBlankInvoice = () => {
  const pageStyle = `@page {
        size: A4;
        margin: 10pt;
      }
      @media print {
        .pagebreak { page-break-before: always; } /* page-break-after works, as well */
    }
      }
    `;
  let componentRef = useRef();

  const invoiceFor = ["CONSIGNOR", "CONSIGNEE", "DRIVER"];
  return (
    <>
      <div className="hidden">
        <div ref={(el) => (componentRef = el)}>
          {invoiceFor.map((copyFor, index) => (
            <div
              key={index}
              style={{ minHeight: "100%" }}
              className=" block border-double border-4 border-cyan-200 py-1"
            >
              <div className="">
                <div className="flex  flex-row justify-between font-bold mx-2 mb-3 sm:text-sm text-xs">
                  <h5>{InvoicSubject}</h5>
                  <h5>{InvoiceDeclartion}</h5>
                </div>
                {/* Logo and Title Start */}
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-48 ml-2">
                        <img src={logo} alt="Kirti Roadlines" />
                      </div>
                      <div className=" flex flex-col ">
                        <span
                          style={{ fontSize: "55px", lineHeight: "1" }}
                          className="text-primary-kirtiroadways font-extrabold "
                        >
                          {KIRTIROADLINES}
                        </span>
                        <span className="text-xl font-serif text-black">
                          {KIRTIROADLINESTAGLINE}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div className="rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
                      <p className="flex  text-md justify-start md:flex-row items-start">
                        <span className="text-xs w-3">{address_svg}</span>{" "}
                        <span className="text-xs ml-1">
                          {OfficeNo}
                          {OfficeAdd}
                        </span>
                      </p>
                      <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                        <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                          <span>{call_svg} </span>{" "}
                          <span>
                            {Mo1}, {Mo2}, {Mo3}
                          </span>
                        </div>
                        <div className="flex space-x-1 justify-center flex-row items-center ">
                          <span>{email_svg} </span>
                          <span>{emailID}</span>
                        </div>
                      </div>
                    </div>
                    {/* Address End */}
                  </div>
                </div>
                {/* Logo and Title End */}
              </div>

              <div className="font-md flex flex-row p-1 mt-2">
                <div
                  className="
            shadow-sm 
            text-sm 
            border
            flex
            flex-row rounded-x-md divide-x border-neutral-500 divide-slate-500"
                >
                  <div className="w-1/3">
                    <h1 className="font-bold underline text-center text-md">
                      {CAUTION}
                    </h1>
                    <ul className="text-justify font-serif text-xs ml-1 mr-1 list-outside">
                      <li className="mt-2">{CAUTIONContnet}</li>
                      <li>{receiptDeclaration}</li>
                    </ul>
                  </div>

                  <div className="flex flex-col divide-y border-neutral-500 divide-slate-500 w-2/3">
                    <div className="h-2/5 flex flex-row divide-x border-neutral-500 divide-slate-500">
                      <div className="self-center w-1/2 font-bold text-lg text-center">
                        {copyFor} COPY
                      </div>
                      <div className="w-1/2">
                        <h1 className="font-bold text-md underline text-center mt-2">
                          {OwnerRiskInsurance}
                        </h1>
                        <h4 className="text-xs text-center">
                          {CustomerDeclaration}
                        </h4>
                      </div>
                    </div>

                    <div className="h-4/5 flex flex-row divide-x  border-neutral-500 divide-slate-500">
                      <div className="w-1/2">
                        <div className="pt-3 w-full ">
                          <h1 className="underline font-bold text-md text-center text-primary-kirtiroadways">
                            {CONSIGNMENTNOTE}
                          </h1>
                          <table className="mt-3  border-collapse text-base border-t border-slate-400 w-full">
                            <thead>
                              <tr>
                                <th
                                  className="border-y border-slate-500 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Delivery At. :
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-xs">
                              <tr>
                                <td className="w-1/2 border-y border-r border-slate-500 px-2 py-3">
                                  <span className="float-left">LR No. :</span>
                                </td>
                                <td className="border-t  border-slate-500">
                                  <span className="float-left ml-2">
                                    LR Date :
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/2 border-t border-b-0  border-r border-slate-500 px-2 py-3">
                                  <span className="float-left">
                                    Fin. Year :
                                  </span>
                                </td>
                                <td className="border-t  border-slate-500 p-2 border-b-0 ">
                                  <span className="float-left">
                                    Lorry No. :
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="w-1/2">
                        <div className="pt-3 w-full ">
                          <h1 className="underline font-bold text-md text-center">
                            {PANNo}
                          </h1>
                          <table className="mt-3 border-collapse text-base border-t border-slate-400 w-full">
                            <thead>
                              <tr>
                                <th
                                  className="border-y border-slate-500 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    {CompanyName} :
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-xs">
                              <tr>
                                <td
                                  className="border-y border-slate-500 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Policy No. :
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className="w-1/2 border-t border-r border-slate-500 px-2 py-3">
                                  <span className="float-left">Date : </span>
                                </td>
                                <td className="border-t  border-slate-500 p-2">
                                  <span className="float-left">Amount :</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Consignore and consignee */}
              <div
                className="my-2 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                 flex flex-col "
              >
                <table className="border-collapse text-base  ">
                  <tbody>
                    <tr>
                      <td className="md:w-1/2  border-b border-r border-neutral-400 p-1">
                        <span className="float-left font-bold">
                          <span className="text-primary-kirtiroadways font-bold ">
                            Consignor's Name :
                          </span>{" "}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold border-b border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            Consignee's Name :
                          </span>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/2 border-r border-neutral-400 p-1">
                        <div
                          className="float-left flex flex-col space-y-2 text-left mx-0"
                          style={{ width: "-webkit-fill-available" }}
                        >
                          <div>
                            <span className="min-w-fit self-start">
                              {Address}
                            </span>{" "}
                          </div>
                          <div className="text-justify border-t border-neutral-400 py-1">
                            <span className="min-w-fit">GST No :</span>{" "}
                          </div>
                        </div>
                      </td>
                      <td className="w-1/2  border-neutral-400 p-1">
                        <span className="float-left flex flex-row space-x-1 mb-10">
                          <span className="min-w-fit">{Address}</span>
                        </span>
                      </td>
                    </tr>
                    {/* <tr>
              <td className="w-1/2 border-t border-r border-slate-500 p-3">
                <span className="float-left"></span>
              </td>
              <td className="border-t  border-slate-500 p-2">
                <span className="float-left"></span>
              </td>
            </tr> */}
                  </tbody>
                </table>
              </div>
              {/* from and to*/}
              <div
                className="mb-4 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                            flex flex-col "
              >
                <table className="border-collapse text-base  border-neutral-400 ">
                  <tbody>
                    <tr>
                      <td className="w-1/2 font-bold border-r  border-neutral-400 p-2">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            From:-
                          </span>{" "}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold  border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            To:-
                          </span>{" "}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Table */}

              <div className="mt-4">
                <table className="overflow-x-scroll w-full">
                  <thead className="text-sm">
                    <tr className="border">
                      <th className="border-2 border-neutral-300">Packages</th>
                      <th className="border-2 border-neutral-300">
                        Description (said to contains)
                      </th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Weight
                        </h1>
                        <div className="w-full flex flex-row justify-evenly text-center">
                          <h1>Actual</h1>
                          <h1>Charged</h1>
                        </div>
                      </th>
                      <th className="border-2 border-neutral-300">Rate</th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Amount to Pay/Paid
                        </h1>
                        <h1>Rs.</h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-top font-light text-center text-xs">
                    <tr className="border-2 font-light border-neutral-300">
                      <td
                        rowSpan="2"
                        className="border-2 font-semibold border-neutral-300 items-center"
                      ></td>
                      <td className="font-semibold align-bottom">
                        <h1 className="font-semibold">AS PER INVOICE</h1>
                      </td>
                      <td className="border-2 border-neutral-300">
                        <div className="flex flex-col space-y-12 ">
                          <div className="flex flex-row justify-evenly"></div>
                          <div className="font-semibold">
                            <h1 className="font-semibold border-y-2 border-neutral-300">
                              Company Invoice No.
                            </h1>
                            <div className="font-semibold"></div>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-col space-y-4">
                        <h1 className="font-semibold">Freight</h1>
                        <h1 className="font-semibold">Sur. Ch.</h1>
                        <h1 className="font-semibold">St. Ch.</h1>
                        <h1 className="font-semibold">Risk Ch.</h1>
                      </td>
                      <td className="border-2 border-neutral-300 align-bottom">
                        <h1 className="uppercase font-semibold">TO PAY</h1>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b-2 border-neutral-300">
                        <h1 className="font-semibold text-left ml-2">
                          Private Marks:
                        </h1>
                      </td>
                      <td className="border-x-2 border-b-2 border-neutral-300"></td>
                      <td className="border-r-2 border-b-2 border-neutral-300 text-md font-mono  text-center justify-center bg-primary-kirtiroadways font-bold">
                        <h1>TOTAL</h1>
                      </td>
                      <td className="border-r-2 border-b-2 border-neutral-300 font-mono  text-center justify-center bg-primary-kirtiroadways font-bold"></td>
                    </tr>
                  </tbody>
                </table>
                <div className="w-full flex flex-row justify-center mt-2">
                  <div className="font-bold text-xs border-2 w-full border-black items-center text-center">
                    <span className="text-center mr-20">To Pay :</span>
                  </div>
                </div>
                <div className="font-bold text-xs border border-neutral-400 text-center">
                  <h1>{InvoiceBilltyNote}</h1>
                  <h1>{CompanyResponsible}</h1>
                </div>
              </div>

              {/* Table End */}

              <div className="mx-4 mt-4">
                {/* bottom left and right start */}
                <div className="flex flex-row space-y-4 justify-between text-left">
                  <div className="max-w-md">
                    <h1 className="font-bold underline border-neutral-400  text-sm">
                      {TermsandCondition}
                    </h1>
                    <h6 className="	text-xs text-justify">
                      {TermsandConditionNote}
                    </h6>
                  </div>
                  <div className="font-semibold text-sm text-right">
                    <h3>Value: AS PER INVOICE</h3>
                    <h3>{ServiceTaxLiabilityConsignor}</h3>
                    <h3 className="mt-10 pt-4">For {KIRTIROADLINES}</h3>
                  </div>
                </div>
                {/* bottom left and right end */}

                {/* <div className="relative">
            <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
      </div> */}
              </div>

              <div className="border-t-0 border-neutral-400 font-serif	bg-primary-kirtiroadways py-1 text-white text-center  w-full mt-2 font-bold rounded-md">
                <h6>{THANKYOUFORBUSSINESSWITHUS}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
        <ReactToPrint
          pageStyle={pageStyle}
          documentTitle={
               "Blank Billty invoice" 
          }
          trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
          content={() => componentRef}
        />
      </div>
    </>
  );
};

export default PrintBlankInvoice;
