import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { PaytoOptions } from "../../Utils/Constants";


const animatedComponents = makeAnimated();

export default function PayTo({ handleOnChange, name,defaultValue }) {
  return (
    <>
        <Select
          components={animatedComponents}
          defaultValue={{ label: defaultValue, value: defaultValue }}
          options={PaytoOptions}
          name={name}
          onChange={handleOnChange}
          placeholder="Select Vehicle Owner"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#8cd7de",
              primary: "#179eaa",
            },
          })}
        />
    </>
  );
}
