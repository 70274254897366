import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axiosInstance from "../../Utils/API";
import { ADD_BUILTY, GETVIEWBOOKINGBYID, UPDATE_BUILTY } from "../../Utils/ApiGlobal";
import Notification from "../../Components/UI/Notification";
import Loader from "../../Components/UI/Loader";
import moment from "moment";
import { WeightUnitOptions } from "../../Utils/Constants";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditBillty(update) {
  // console.log(update.update)
  const location = useLocation();
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(
    moment(location?.state?.data?.data?.lrDate).toDate()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  const [weightUnit, setWeightUnit] = useState();
  const [onChangeWeight, SetonChangeWeight] = useState();
  const [dataBybookingId, setDataByBookingId] = useState([])
  // To fetch Data from previous route
  useEffect(() => {
    if(location?.state?.builty === "NA"){
      BuiltyById(location?.state?.data?.BookingId)
    }
    else if(location?.state?.label === "Add"){
      const Id =location?.state?.bookindData?.bookingId
      // console.log(Id)
      BuiltyById(Id)
    }
    else{
      BuiltyById(location?.state?.data?.data?.bookingId)
    }
  }, [location]);

  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Billty");
    } else {
      setHeading("Edit Billty");
    }
    // console.log(dataBybookingId)
  }, [location]);

  const handleChangeWight = (e) => {
    setWeightUnit(e.value);
  };
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const BuiltyById = async(bookingId)=>{
   setIsLoading(true);
    await axiosInstance
    .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
    .then((response) => {
      const data= response?.data?.data;
      setDataByBookingId(data)
       setIsLoading(false);
      return;
    })
    .catch((error) => {
      return;
    });
  }
  //   Form Submit click event
  const FormSubmitHandler = async (ev) => {
    ev.preventDefault();
    const obj = formValueConvertor(getFormJSON(ev.target));
    if (location?.state?.label === "Add" ||location?.state?.builty === "NA" || dataBybookingId?.billtyDetails === null ) {
      const ADD_BILLTY_DATA = {
        BookingId: dataBybookingId?.bookingDetails?.bookingId,
        FinancialYear: obj.Year,
        LrNo: obj.LR_No,
        LrDate: startDate,
        VehicleNo: obj.Vehicle_No,
        DeliveryAt: obj.Delivery_At,
        ConsignerName: obj.Consignor_Name,
        ConsignerGST: obj.Consignor_GST_No,
        ConsignerAddress:obj.Consignor_Address,
        ConsigneeName: obj.Consignee_Name,
        ConsigneeGst: obj.Consignee_GST_No,
        ConsigneeAddress: obj.Consignee_Address,
        From: obj.From,
        To: obj.To,
        Packages: obj.Pakages,
        Description: obj.Description,
        ActualWeight: +obj.Actual_Weight,
        ChargedWeight: +obj.Charged_Weight,
        WeightType: weightUnit,
        Fright: location?.state?.bookindData?.fright,
        Advance: location?.state?.bookindData?.advance,
        Balance: location?.state?.bookindData?.balance,
        Detention: location?.state?.bookindData?.detention,
        DriverPaidBalance: location?.state?.bookindData?.driverPaidBalance,
        ExtraCharge: location?.state?.bookindData?.extraCharge,
        Hamali: location?.state?.bookindData?.hamali,
        Note: location?.state?.bookindData?.extraCharge,
        PrivateMarks: "",
        BilltyNo: "",
        AmountToPayOrPaid: +obj.Amount_To_Pay,
        Total: parseInt(obj.Total),
        Createdby: localStorage.getItem("userId"),
      };
      await axiosInstance
        .post(ADD_BUILTY, ADD_BILLTY_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          history.push({
            pathname: "/bilty",
            state: { data: obj },
          });
          setIsLoading(true);
          return;
        })
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          return;
        });
    } else {
      const UPDATE_BILLTY_DATA = {
        BilltyId: dataBybookingId?.billtyDetails?.billtyId,
        BookingId:  dataBybookingId?.billtyDetails?.bookingId,
        FinancialYear: obj.Year,
        LrNo: obj.LR_No,
        LrDate: startDate,
        VehicleNo: obj.Vehicle_No,
        DeliveryAt: obj.Delivery_At,
        ConsignerName: obj.Consignor_Name,
        ConsignerGST: obj.Consignor_GST_No,
        ConsignerAddress:obj.Consignor_Address,
        ConsigneeName: obj.Consignee_Name,
        ConsigneeGst: obj.Consignee_GST_No,
        ConsigneeAddress: obj.Consignee_Address,
        From: obj.From,
        To: obj.To,
        Packages: obj.Pakages,
        Description: obj.Description,
        ActualWeight: +obj.Actual_Weight,
        ChargedWeight: +obj.Charged_Weight,
        WeightType: weightUnit,
        Fright: location?.state?.bookindData?.fright,
        Advance: location?.state?.bookindData?.advance,
        Balance: location?.state?.bookindData?.balance,
        Detention: location?.state?.bookindData?.detention,
        DriverPaidBalance: location?.state?.bookindData?.driverPaidBalance,
        ExtraCharge: location?.state?.bookindData?.extraCharge,
        Hamali: location?.state?.bookindData?.hamali,
        Note: location?.state?.bookindData?.extraCharge,
        PrivateMarks: "",
        BilltyNo: "",
        AmountToPayOrPaid: +obj.Amount_To_Pay,
        Total: parseInt(obj.Total),
        Createdby: localStorage.getItem("userId"),
      };
     await axiosInstance
        .post(UPDATE_BUILTY, UPDATE_BILLTY_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000, 
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          history.push({
            pathname: "/bilty",
            state: { data: obj },
          });
          setIsLoading(true);
          return;
        })
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          return;
        });
    }
  };

  return (
    <>
      {!update.update ? (
        <DashBoardLayout pageTitle={heading}>
          <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
          {isLoading ? (
            <Loader />
          ) : (
            <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
              <form onSubmit={FormSubmitHandler}>
                <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" w-full">
                    <div className=" text-left space-y-6">
                      {/* Fin year,Lr no, lr date*/}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Fin. Year" isRequired="true" />
                          <Input
                            value={year}
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                            type="text"
                            defaultValue={location?.state?.data?.data?.Year}
                            name="Year"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="LR No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.lrNo ||
                              location?.state?.data?.LrNo
                            }
                            name="LR_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="LR Date" isRequired="true" />
                          <div className="flex flex-row justify-center ">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Delievery at,Vehicle_No, consignor name */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Delivery At" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.to ||
                              location?.state?.data?.To
                            }
                            name="Delivery_At"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Vehicle No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.vehicleNo ||
                              location?.state?.data?.VehicleNo
                            }
                            name="Vehicle_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Consignor's Name" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerName ||
                              location?.state?.data?.ConsignerName
                            }
                            name="Consignor_Name"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {/* Consignor Address,Gst,consignee name */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label
                            label="Consignor's Address"
                            isRequired="true"
                          />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerAddress ||
                              location?.state?.data?.ConsignerAddress
                            }
                            name="Consignor_Address"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Consignor's GST" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerGst ||
                              location?.state?.data?.ConsignerGst
                            }
                            name="Consignor_GST_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Consignee's Name" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeName ||
                              location?.state?.data?.ConsigneeName
                            }
                            name="Consignee_Name"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {/* Consignee_add,consignee gst from */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label
                            label="Consignee's Address"
                            isRequired="true"
                          />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeAddress ||
                              location?.state?.data?.ConsigneeAddress
                            }
                            name="Consignee_Address"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Consignee's GST" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeGst ||
                              location?.state?.data?.ConsignerGst
                            }
                            name="Consignee_GST_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="From" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.from ||
                              location?.state?.data?.From
                            }
                            name="From"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      {/* To, Pakages ,description*/}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="To" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.to ||
                              location?.state?.data?.To
                            }
                            name="To"
                            //  required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Packages" isRequired="true" />
                          <Input
                            type="text"
                            name="Pakages"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.packages ||
                              location?.state?.data?.Pakages
                            }
                          />
                        </div>
                        <div className=" flex flex-col text-left w-1/2">
                          <Label label="Description"  />
                          <Input
                            type="text"
                            className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            name="Description"
                            id=""
                            cols="30"
                            rows="5"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.description ||
                              location?.state?.data?.data?.Description
                            }
                          />
                        </div>
                      </div>
                      {/*Actual weight, weight unit and charged weight*/}
                      <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Actual Weight" isRequired="true" />
                          <Input
                            type="number"
                            step="any"
                            name="Actual_Weight"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.actualWeight ||
                              location?.state?.data?.data?.Actual_Weight
                            }
                            onChange={(e) => {
                              SetonChangeWeight(e.target.value);
                            }}
                            // SetonChangeWeight
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Weight Unit" isRequired="true" />
                          <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#8cd7de',
                            primary: '#179eaa',
                            },
                          })}
                            onChange={handleChangeWight}
                            options={WeightUnitOptions}
                            defaultValue={{
                              label: location?.state?.data?.data?.weightType,
                              value: location?.state?.data?.data?.weightType,
                            }}
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Charged Weight" isRequired="true" />
                          <Input
                            type="number"
                            step="any"
                            name="Charged_Weight"
                            // required
                            autoComplete="off"
                            defaultValue={
                              onChangeWeight ||
                              location?.state?.data?.data?.chargedWeight ||
                              location?.state?.data?.data?.Charged_Weight
                            }
                          />
                        </div>
                      </div>

                      {/*Actual weight, weight unit and charged weight*/}
                      <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Invoice No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.Invoice_No ||
                              location?.state?.data?.Bill_No
                            }
                            name="Invoice_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label
                            label="Amount To Pay Or Paid"
                            isRequired="true"
                          />
                          <Input
                            defaultValue={
                              dataBybookingId?.billtyDetails?.amountToPayOrPaid ||
                              location?.state?.data?.Booking_Amount
                            }
                            name="Amount_To_Pay"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Total" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.total ||
                              location?.state?.data?.Total
                            }
                            name="Total"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="items-ceter mt-4">
                  <ButtonWithBG theme="primary" label="SAVE" type="submit" />
                </div>
              </form>
            </div>
          )}
        </DashBoardLayout>
      ) : (
        <>
          <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
          {isLoading ? (
            <Loader />
          ) : (
            <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
              <form onSubmit={FormSubmitHandler}>
                <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" w-full">
                    <div className=" text-left space-y-6">
                      {/* Fin year,Lr no, lr date*/}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Fin. Year" isRequired="true" />
                          <Input
                            value={year}
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                            type="text"
                            defaultValue={location?.state?.data?.data?.Year}
                            name="Year"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="LR No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.lrNo ||
                              location?.state?.data?.LR_No
                            }
                            name="LR_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="LR Date" isRequired="true" />
                          <div className="flex flex-row justify-center ">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Delievery at,Vehicle_No, consignor name */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Delivery At" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.to ||
                              location?.state?.data?.To
                            }
                            name="Delivery_At"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Vehicle No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.vehicleNo ||
                              location?.state?.data?.Vehicle_No
                            }
                            name="Vehicle_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Consignor's Name" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerName ||
                              location?.state?.data?.Consignor_Name
                            }
                            name="Consignor_Name"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {/* Consignor Address,Gst,consignee name */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label
                            label="Consignor's Address"
                            isRequired="true"
                          />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerAddress ||
                              location?.state?.data?.Consignor_Address
                            }
                            name="Consignor_Address"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Consignor's GST" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consignerGst ||
                              location?.state?.data?.Consignor_GST_No
                            }
                            name="Consignor_GST_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Consignee's Name" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeName ||
                              location?.state?.data?.Consignee_Name
                            }
                            name="Consignee_Name"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {/* Consignee_add,consignee gst from */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label
                            label="Consignee's Address"
                            isRequired="true"
                          />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeAddress ||
                              location?.state?.data?.Consignee_Address
                            }
                            name="Consignee_Address"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Consignee's GST" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.consigneeGst ||
                              location?.state?.data?.Consignee_GST_No
                            }
                            name="Consignee_GST_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="From" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.from ||
                              location?.state?.data?.From
                            }
                            name="From"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      {/* To, Pakages ,description*/}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="To" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.to ||
                              location?.state?.data?.To
                            }
                            name="To"
                            //  required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Packages" isRequired="true" />
                          <Input
                            type="text"
                            name="Pakages"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.packages ||
                              location?.state?.data?.Pakages
                            }
                          />
                        </div>
                        <div className=" flex flex-col text-left w-1/2">
                          <Label label="Description"  />
                          <Input
                            type="text"
                            className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            name="Description"
                            id=""
                            cols="30"
                            rows="5"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.note ||
                              location?.state?.data?.data?.Description
                            }
                          />
                        </div>
                      </div>
                      {/*Actual weight, weight unit and charged weight*/}
                      <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Actual Weight" isRequired="true" />
                          <Input
                            type="number"
                            step="any"
                            name="Actual_Weight"
                            // required
                            autoComplete="off"
                            defaultValue={
                              location?.state?.data?.data?.actualWeight ||
                              location?.state?.data?.data?.Actual_Weight
                            }
                            onChange={(e) => {
                              SetonChangeWeight(e.target.value);
                            }}
                            // SetonChangeWeight
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Weight Unit" isRequired="true" />
                          <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#8cd7de',
                            primary: '#179eaa',
                            },
                          })}
                            onChange={handleChangeWight}
                            options={WeightUnitOptions}
                            defaultValue={{
                              label: location?.state?.data?.data?.weightType,
                              value: location?.state?.data?.data?.weightType,
                            }}
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Charged Weight" isRequired="true" />
                          <Input
                            type="number"
                            step="any"
                            name="Charged_Weight"
                            // required
                            autoComplete="off"
                            defaultValue={
                              onChangeWeight ||
                              location?.state?.data?.data?.chargedWeight ||
                              location?.state?.data?.data?.Charged_Weight
                            }
                          />
                        </div>
                      </div>

                      {/*Actual weight, weight unit and charged weight*/}
                      <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Invoice No." isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.Invoice_No ||
                              location?.state?.data?.Bill_No
                            }
                            name="Invoice_No"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label
                            label="Amount To Pay Or Paid"
                            isRequired="true"
                          />
                          <Input
                            defaultValue={
                              dataBybookingId?.billtyDetails?.amountToPayOrPaid ||
                              location?.state?.data?.Booking_Amount
                            }
                            name="Amount_To_Pay"
                            // required
                            autoComplete="off"
                          />
                        </div>
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Total" isRequired="true" />
                          <Input
                            type="text"
                            defaultValue={
                              location?.state?.data?.data?.total ||
                              location?.state?.data?.Total
                            }
                            name="Total"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="items-ceter mt-4">
                  <ButtonWithBG theme="primary" label="SAVE" type="submit" />
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
}
