import React, { useRef } from "react";
import logo from "../../Images/logo_green_transparent.png";
import logo_oposity from "../../Images/logo_green_oposity.png";
// import table_bg from "../../Images/table_bg.png";
// import Scrollbars from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
  // AmountChargeableInWords,
  OURBANKDETAILS,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";

const BillingInvoicePrint = ({
  sentInvoice,
  ShowHeader,
  BankDetails,
  profileData,
  secondaryPhoneNo,
  bankBranch,
  Dropdown,
  Rate
}) => {
  let componentRef = useRef();
  const location = useLocation();
  var loadingDate = location?.data?.data?.lrDate;
  // console.log(sentInvoice);
  var Loading_Date = moment(loadingDate).format("DD-MM-YYYY");
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  return (
    <>
      <div className="hidden">
        <div ref={(el) => (componentRef = el)}>
          <div
            className="pageStyle m-2 block border-double border-4 mx-2 mt-3 border-cyan-200 align-top text-center bg-center bg-no-repeat  bg-[length:65%]"
            style={{
              backgroundImage: "url(" + logo_oposity + ")",
            }}
          >
            {ShowHeader && (
              <div className="mt-1">
                <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
                  <h5>Tax Invoice</h5>
                </div>
                {/* Logo and Title Start */}
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-40 ml-2">
                        <img src={logo} alt="Kirti Roadlines" />
                      </div>
                      <div className=" flex flex-col ">
                        <span
                          style={{ fontSize: "70px", lineHeight: "1" }}
                          className="text-primary-kirtiroadways font-extrabold "
                        >
                          {profileData.tagLine}
                        </span>
                        <span className="text-xl font-serif text-black">
                          {profileData.subTagLine}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
                 { profileData.address !== " " &&   <p className="flex  text-md justify-center flex-row items-start">
                        <span className="text-xs w-3">{address_svg}</span>{" "}
                        <span className="text-xs ml-1">
                          {profileData.address}
                        </span>
                      </p>}
                      <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                        {(secondaryPhoneNo.length !== 0) && 
                         (<div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                          <span>{call_svg} </span>{" "}
                          <span className="flex flex-row">
                            {secondaryPhoneNo.map((number, i) => (
                              <span
                                key={number}
                                className="text-sm font-semibold tracking-wider flex flex-row"
                              >
                                {(i ? ", " : "") + number}
                              </span>
                            ))}
                          </span>
                        </div>)}
                      { profileData.email !== " " &&  
                        (<div className="flex space-x-1 justify-center flex-row items-center ">
                          <span>{email_svg} </span>
                          <span>{profileData.email}</span>
                        </div>)}
                      </div>
                    </div>
                    {/* Address End */}
                  </div>
                </div>
                {/* Logo and Title End */}
              </div>
            )}
            {/* Invoice  No and details*/}
            <div className="p-2 flex font-sans flex-row justify-between">
              <div className="flex flex-col text-left w-1/2">
                <div className="font-bold uppercase text-md	py-1 mb-0 px-5 w-36 text-white rounded-lg bg-primary-kirtiroadways">
                  Invoice to
                </div>
                <div>
                  <h1 className="text-left font-bold uppercase font-sans text-xl	subpixel-antialiased tracking-wide mt-0	">
                    {sentInvoice.name}
                  </h1>
                </div>
                <div className="text-sm text-left">
                  <div className="mt-1">
                    <p className="">Address : {sentInvoice.address}</p>
                    {/* <p className="w-1/2 ">{sentInvoice.address}</p> */}
                  </div>
                </div>
                <div className="text-sm mt-1">
                  <div className="flex flex-row space-x-1 text-left">
                    <p className="">GST : {sentInvoice.gst}</p>
                    {/* <p className="w-1/2">{sentInvoice.gst}</p> */}
                  </div>
                </div>
              </div>

              {/* Right side */}
              <div className="flex  flex-col text-right justify-end items-end">
                <div className="font-bold uppercase text-base	py-1 mb-0 px-5 w-34 text-white rounded-lg bg-primary-kirtiroadways">
                  OUR Details
                </div>
                <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
                  <p className="flex flex-col text-md">
                    <span>Service : {profileData.service}</span>
                    <span>Pan No. : {profileData.panNo}</span>
                    <span>State Code : {profileData.stateCode}</span>
                  </p>
                  {/* <p className="flex flex-col">
                    <span>Transportation</span>
                    <span>AJGPD9182R</span>
                    <span>24</span>
                  </p> */}
                </div>

                <div className=" uppercase font-semibold	pt-1 mt-2 border-t-2 border-primary-kirtiroadways">
                  <div className="flex flex-row text-xs text-right space-x-12">
                    <span className="flex flex-col ">
                      <p className="text-right min-w-1/2">
                        Invoice No : {location?.data?.data?.invoiceNo}
                      </p>
                      <p className="text-right min-w-1/2">
                        Bill OF Loading : 065/RE/2022-23
                      </p>
                      <p className="text-right min-w-1/2">
                        Date : {Today_Date}
                      </p>
                    </span>
                    {/* <span className="flex flex-col ">
                      <p className="text-left min-w-1/2">
                        {location?.data?.data?.invoiceNo}
                      </p>
                      <p className="text-left min-w-1/2">065/RE/2022-23</p>
                      <p className="text-left min-w-1/2">{Today_Date}</p>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Table start*/}

            <div className="p-2 pb-0 w-full mb-2 border-t-0 border-neutral-900">
              <table className="border-spacing-y-2 border-separate w-full border-neutral-900 text-xs font-sans">
                <thead className="mb-2">
                  <tr className=" border-neutral-900 text-white ">
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800 border-neutral-900 py-3"
                    >
                      Loading Date
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800 border-neutral-900"
                    >
                      LR No.
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800 border-neutral-900"
                    >
                      Vehicle No.
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800 border-neutral-900"
                    >
                      From
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800 pt-0"
                    >
                      To
                    </th>
                    {/* <th
                    className=" bg-no-repeat"
                    style={{
                      backgroundImage: "url(" + table_bg + ")",
                    }}
                  ></th> */}
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-primary-kirtiroadways border-neutral-900"
                      // style={{
                      // backgroundImage: "url(" + table_whitered + ")",
                      // }}
                    >
                      Weight
                    </th>
                    {/* <th className="bg-primary-kirtiroadways border-neutral-900">
                      Charged Weight
                    </th> */}
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-primary-kirtiroadways border-neutral-900"
                    >
                      Rate
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-primary-kirtiroadways border-neutral-900"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>

                <tbody
                  className="align-top font-bold text-center bg-bottom bg-no-repeat bg-[length:80%_90%]"
                  // style={{
                  //   backgroundImage: "url(" + logo_oposity + ")",
                  // }}
                >
                  <tr className="mt-6  border-neutral-900">
                    <td className="items-center w-24 border-r-2 border-gray-300">
                      <h1 className="mt-2">{Loading_Date}</h1>
                    </td>
                    <td className="border-r-2 border-gray-300 w-20">
                      <h1 className="mt-2">{location?.data?.data?.lrNo}</h1>
                    </td>
                    <td className="border-r-2 border-gray-300 w-24">
                      <h1 className="mt-2">
                        {location?.data?.data?.vehicleNo}
                      </h1>
                    </td>
                    <td className="border-r-2 border-gray-300 w-28">
                      <h1 className="mt-2">{location?.data?.data?.from}</h1>
                    </td>
                    <td className="border-r-2 border-gray-300 w-28">
                      <h1 className="ml-2 mt-2 text-center">
                        {location?.data?.data?.to}
                      </h1>
                    </td>
                    <td className="border-r-2 border-gray-300 w-24">
                      <h1 className="mt-2 text-center">
                        {location?.data?.data?.actualWeight}{" "}{" "}{location?.data?.data?.weightType}
                      </h1>
                    </td>
                    {/* <td className="border-l border-neutral-900 w-24">
                      <h1 className="mt-2 text-center">
                        {location?.data?.data?.chargedWeight}
                      </h1>
                    </td> */}
                    <td className="border-r-2 border-gray-300 w-16">
                      <h1 className="mt-2 text-center">{Rate}</h1>
                    </td>
                    <td className="">
                      <h1 className="mt-2 text-center">
                        {location?.data?.data?.balance}
                      </h1>
                    </td>
                  </tr>
                  <tr style={{ height: "220px" }}></tr>
                </tbody>
              </table>

              {/* Bannk Detail Start */}
              <div className="flex flex-row justify-between font-sans font-semibold text-left text-xs">
                <div className="mt-4 ">
                  <div className="flex flex-row space-x-2 ">
                    <h5 className="px-5 py-2 tracking-wide text-sm rounded-lg uppercase bg-primary-kirtiroadways text-white">
                      {OURBANKDETAILS}
                    </h5>
                  </div>
                  <div className="flex flex-row space-x-1 mt-2">
                    <div className="space-y-1">
                      <h5>Bank Name</h5>
                      <h5>Branch</h5>
                      <h5>A/c. No</h5>
                      <h5>IFSC Code</h5>
                    </div>
                    {Dropdown === false ? (
                      <div className="space-y-1">
                        <h5>: State Bank of India</h5>
                        <h5>: Prahlad Nagar Ahmedabad</h5>
                        <h5>: 005167983000351</h5>
                        <h5>: SBIT0CKMNSB (FIFT CHARACTER IS ZERO)</h5>
                      </div>
                    ) : (
                      <div className="space-y-1">
                        <h5>: {bankBranch.bank}</h5>
                        <h5>: {bankBranch.branch}</h5>
                        <h5>: {bankBranch.accNo}</h5>
                        <h5>: {bankBranch.ifsc}</h5>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row  justify-end mt-4 border-t-2 w-1/3 border-primary-kirtiroadways">
                  <div className="space-y-1  mt-2  text-right w-32">
                    <h5 className="pr-2">TOTAL :</h5>
                    <div className="h-14">
                      {location?.data?.data?.hamali > 0 && (
                        <h5 className=" pr-2">Hamali :</h5>
                      )}
                      {location?.data?.data?.detention > 0 && (
                        <h5 className="pr-2">Detention :</h5>
                      )}
                      {location?.data?.data?.extraCharge > 0 && (
                        <h5 className=" pr-2">Extra Charge :</h5>
                      )}
                    </div>
                    <h5 className="pr-2 text-sm	 px-2 py-2 tracking-wide  w-full rounded-l-lg uppercase bg-primary-kirtiroadways text-white">
                      Grand Total
                    </h5>
                  </div>
                  <div className="space-y-1 mt-2 text-right w-28">
                    <h5>&#8377; {location?.data?.data?.balance}</h5>
                    <div className="h-14">
                      {location?.data?.data?.hamali > 0 && (
                        <h5>&#8377; {location?.data?.data?.hamali}</h5>
                      )}
                      {location?.data?.data?.detention > 0 && (
                        <h5>&#8377; {location?.data?.data?.detention}</h5>
                      )}
                      {location?.data?.data?.detention > 0 && (
                        <h5> &#8377; {location?.data?.data?.extraCharge}</h5>
                      )}
                    </div>
                    <h5 className="border-l-2 text-sm  border-white pr-2 px-5 py-2 tracking-wide b-r-2 w-full rounded-r-lg uppercase bg-primary-kirtiroadways text-white">
                      &#8377; {location?.data?.data?.total}
                    </h5>
                  </div>
                </div>
              </div>
              {/* Bannk Detail End */}

              {/* Extra Information */}
              <div className="flex flex-col justify-start items-start font-sans border-t-2 border-primary-kirtiroadways mt-2">
                <p>Amount Chargeable (in words)</p>
                <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                  {/* Four Thousand Four Hundred Fifty */}
                  {location?.data?.data?.totalInWords}{" "}ONLY
                </p>
              </div>
              {/* terms and condition */}
              <div className="flex pb-4 flex-row justify-between mb-2 font-sans text-xs">
                <div className="text-left flex flex-col">
                  <p className="border-black border-b-2 w-36 pb-1">
                    Terms & Conditions
                  </p>
                  <p>{InvoicSubject}</p>
                </div>
                <div className="text-left text-xs">E. & O.E.</div>
                <div className="text-left text-xs ">FOR,</div>
                <div className="text-lg font-bold text-right ">
                  {profileData.tagLine}
                </div>
              </div>

              {/* last line */}
              <div className="flex border-black border-b-2 flex-row justify-between items-start mt-2 mb-2 font-sans text-xs">
                <div className="text-left flex flex-col">
                  <p>{PaymentbypayeeCchequeDrafs}</p>
                </div>
                <div className="text-center">{profileData.tagLine}</div>
                <div className="text-right  font-bold ">
                  <h5>{AuthorizedSignatory}</h5>
                </div>
              </div>

              {/* printing */}
              <h2 className="text-xs mt-0 mb-0 text-center font-bold bg-primary-kirtiroadways rounded-md py-2 text-white font-sans">
                {computerGeneratedInvoice}
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* printing */}
      <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
        <ReactToPrint
          documentTitle={"Billing Invoice of " + sentInvoice.name}
          trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
          content={() => componentRef}
        />
      </div>
    </>
  );
};

export default BillingInvoicePrint;
