import React, { useRef } from "react";
import logo_green_oposity from "../../Images/logo_green_oposity.png";
import { useLocation } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import Logo from "../../Images/logo_green_transparent.png";
import ReactToPrint from "react-to-print";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import LCVPrint from "./LCVPrint";
import { useEffect, useState } from "react";
import Loader from "../../Components/UI/Loader";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_INFORMATION_DATA } from "../../Utils/ApiGlobal";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import moment from "moment";




const TransporterLoadingSlip = () => {
  let location = useLocation();
  let componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const pageStyle = `@page {
    size: A5;
    margin: 10pt;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }`;
 
  useEffect(() => {
    _FetchProfileAPI();
  }, []);
  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        const arr = response?.data?.data?.secondaryPhoneNo;
        const noEmptyStrings = arr.filter((str) => str !== '');
        setsecondaryPhoneNo(noEmptyStrings);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  return (
    <>
      <DashBoardLayout pageTitle="Transporter Loading Slip">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="min-w-[600px]">
            <div
              className="border-2 border-primary-kirtiroadways md:m-10 bg-center bg-no-repeat  bg-[length:65%]"
              style={{
                backgroundImage: "url(" + logo_green_oposity + ")",
              }}
            >
              <div className="">
                {/* logo and Kiti Roadlines Start */}
                <div className="flex flex-row space-x-0 mt-2 mx-2">
                  <div>
                    <img
                      className="lg:w-96 md:w-56 sm:w-56 w-36"
                      src={Logo}
                      alt="Transporter"
                    />
                  </div>
                  <div className="flex flex-col  w-full">
                    <div className="flex flex-row justify-end lg:text-xl md:text-md sm:text-sm text-xs font-bold ">
                      {/* <h1 className="xl:ml-52 lg:ml-24 md:ml-12  sm:ml-24 ml-8">|| श्री हरी ||</h1> */}
                      {/* <h1 className="xl:ml-40 lg:ml-16 md:ml-20 sm:ml-20 ml-10">|| श्री हरी ||</h1> */}
                      {/* <h1>|| श्री मुंडेश्वरी माँ ||</h1> */}
                      <h1>Vehicle Loading Slip</h1>
                    </div>
                    <div className="xl:text-7xl text-right lg:text-6xl text-5xl font-bold  w-full text-primary-kirtiroadways">
                      {profileData.tagLine}
                    </div>
                  </div>
                </div>
                {/* logo and Kiti Roadlines End */}

                {/* LCV Start */}
                <div className="border-y-2 border-primary-kirtiroadways lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold mt-2 text-center py-1">
                  L. C. V
                </div>
                {/* LCV End */}

                {/* Address Start */}
                <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                {profileData.address !== " " && (
                  <p className="flex  text-md justify-center md:flex-row items-start">
                    <span className="text-md w-5">{address_svg}</span>{" "}
                    <span className="text-md ml-1">
                       {profileData.address}
                    </span>
                  </p>
                )}
                  <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                  {secondaryPhoneNo.length !== 0 && (
                    <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                      <span>{call_svg} </span>{" "}
                      <span className="flex flex-row">
                          {secondaryPhoneNo.map((number, i) => (
                            <span
                              key={number}
                              className="text-sm font-semibold tracking-wider flex flex-row"
                            >
                              {(i ? ", " : "") + number}
                            </span>
                          ))}
                        </span>
                    </div>
                  )}
                  {profileData.email !== " " && (
                    <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                      <span>{email_svg} </span>
                      <span>{profileData.email}</span>
                    </div>
                  )}
                  </div>
                </div>
                {/* Address End */}

                {/* Main Form Start */}
                <div className="m-2 space-y-2">
                  {/* No and date */}
                  <div className="flex flex-row justify-between font-bold">
                    <div className="flex text-2xl flex-row ">
                      No. :{/* <h1 className="ml-2">001</h1> */}
                    </div>
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <h1 className="text-2xl">Date :</h1>
                      {/* <h1>20-09-2022</h1> */}
                      <h1 className="mt-1">
                      {moment(location?.data?.data?.loadingDate).format("DD-MM-YYYY")}
                      </h1>
                    </div>
                  </div>
                  {/* Name */}
                  <div className="flex flex-row">
                    <p className="text-lg">M/S.</p>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.transporterName}
                    </div>
                  </div>
                  {/* Vehicle No */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit	text-left">
                      <h1>We Are Sending Truck No.</h1>
                    </div>
                    <div className="w-full border-b-2 text-center border-b-primary-kirtiroadways font-bold">
                      {location?.data?.data?.vehicleNo}
                    </div>
                  </div>
                  {/* From to  */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Please Load From</h1>
                    <div className="w-full border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.from}
                    </div>
                    <h1 className="mx-2 text-left">To,</h1>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.to}
                    </div>
                  </div>
                  {/* goods and garantee */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">of Goods</h1>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.goods}
                    </div>
                    <h1 className="min-w-fit text-center">Guarantee</h1>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.guarantee}
                    </div>
                  </div>
                  {/* full loads */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit text-left">
                      Full Load has been Settled with
                    </div>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.loadSettledWith}
                    </div>
                  </div>
                  {/* rate rs and total fright */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit text-left">Rate Rs.</div>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.rates}
                    </div>
                    <div className="min-w-fit text-center">
                      Total Freigth Rs.
                    </div>
                    <div className="w-full border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.transporterFreigth}
                    </div>
                  </div>
                  {/* Adance and balance */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Advance Rs.</h1>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                      {location?.data?.data?.transporterAdvance}
                    </div>
                    <div className="text-left min-w-fit">Balance:</div>
                    <div className="w-full ml-1 border-b-2 border-b-primary-kirtiroadways text-center font-bold">
                    {location?.data?.data?.transporterBalance}
                    </div>
                  </div>
                  {/* extra note */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Thanks</h1>
                  </div>
                </div>
                {/* Main Form End */}

                {/* Note Start */}
                <div className="text-xl p-1 border-y-2 border-primary-kirtiroadways align-center  text-center">
                  <span className="text-2xl font-bold">Note :</span> गाड़ी भरने के वक्त
                  गाड़ी की R.C. औरा PAN CARD की ज़ेरॉक्स की कॉपी लेना ट्रांसपोर्ट की
                  जिमेदारी रहेगी
                </div>
                {/* Note End */}

                {/* Signature Box Start */}
                <div className="flex flex-row justify-between m-4">
                  <div className="lg:text-5xl md:text-3xl sm:text-2xl text-md font-bold mt-4 text-primary-kirtiroadways">
                    No Varai, No Hamali
                  </div>
                  <div className="mt-8 lg:text-2xl md:text-xl sm:text-lg text-md">
                    <p>Your Faithfully</p>
                    <h1 className="font-bold">For, {profileData.tagLine}</h1>
                  </div>
                </div>
                {/* Signature Box End */}

                {/* Last content Start */}
                <div className="border-t-2 border-primary-kirtiroadways">
                  <div className="m-4">
                    <p className="text-3xl text-left font-bold">NOTE :</p>
                    <ol className="list-decimal text-xl list-outside ml-6 text-left">
                      <li>
                        Please Check up Engine & Chassis No. R.T.O. Book C.S.T.
                        No. Etc for your Satisfaction
                      </li>
                      <li>
                        We are not Responsible of Accident, Leakage & Breakage
                      </li>
                    </ol>
                  </div>
                </div>
                {/* Last content End */}
              </div>
            </div>
            {/* Printing Data Start*/}
            <div className="hidden">
              <div ref={(el) => (componentRef = el)}>
                <style>{pageStyle}</style>
                <LCVPrint  
                  profileData = {profileData}
                  secondaryPhoneNo = {secondaryPhoneNo}/>
              </div>
            </div>
            {/* Print Buttom */}
            <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
              <ReactToPrint
                trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
                content={() => componentRef}
              />
            </div>
            {/* <button onClick={()=> console.log(location?.data?.data)}>Click</button> */}
            {/* Printing Data End*/}
          </div>
        )}
      </DashBoardLayout>
    </>
  );
};

export default TransporterLoadingSlip;
