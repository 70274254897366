import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import axiosInstance from "../../Utils/API";
import Loader from "../../Components/UI/Loader";
import APILoader from "../../Components/UI/APILoader";
import { POST_TRANSPORTER, POST_UPDATE_TRANSPORTER } from "../../Utils/ApiGlobal";
const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
export default function AddEditTransporter() {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [heading, setHeading] = useState("");
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Transporter");
    } else {
      setHeading("Edit Transporter");
    }
  }, [location]);

   // For Loader 
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, [])

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  const submitForms = async(ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    var obj = formValueConvertor(getFormJSON(ev.target));
    if(location?.state?.label === "Add"){
      let TRANSPORTER_DATA = {
        TransporterName: obj?.TransporterName.toUpperCase(),
        Address: obj.Address.toUpperCase(),
        MobileNumber: obj.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
      };
      await axiosInstance
      .post(POST_TRANSPORTER, TRANSPORTER_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/transporter",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
    }
    else{
      let TRANSPORTER_UPDATE_DATA = {
        TransporterId:location?.state?.data?.data?.transporterId,
        TransporterName: obj?.TransporterName.toUpperCase(),
        Address: obj.Address.toUpperCase(),
        MobileNumber: obj.Contact_No,
      };
       await axiosInstance
      .post(POST_UPDATE_TRANSPORTER, TRANSPORTER_UPDATE_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/transporter",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
    }
  };
  return (
    <DashBoardLayout pageTitle={heading}>
      <APILoader loader={loader} />
         {isLoading ? (
          <Loader/>
        ) : (
      <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md h-full">
        <form onSubmit={submitForms}>
          <div className="h-full">
            <div className="flex flex-row space-x-6">
              <div className=" flex md:w-1/2 flex-col text-left space-y-6 ">
                {/* Owner Name */}
                <div className=" flex flex-col   text-left ">
                  <Label label="Transporter Name" isRequired="true" />
                  <Input
                    defaultValue={location?.state?.data?.data?.transporterName}
                    name="TransporterName"
                    required
                  />
                </div>
                <div className=" flex  flex-col  text-left h-full">
                  <Label label="Contact No." isRequired="true" />
                  <Input
                    defaultValue={location?.state?.data?.data?.mobileNumber}
                    name="Contact_No"
                    required
                  />
                </div>
              </div>
              <div className=" flex md:w-1/2  flex-col  text-left h-full">
                <Label label="Address" isRequired="true" />
                <textarea
                  className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                  name="Address"
                  rows="4"
                  defaultValue={location?.state?.data?.data?.address}
                  type="text"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div className="items-ceter mt-4">
            <ButtonWithBG theme="primary" label="SAVE" type="submit" />
          </div>
        </form>
      </div>
         )}
    </DashBoardLayout>
  );
}
