import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../Utils/Constants";
import moment from "moment";
// import { BOOKING_PENDING_DATA } from "../../Utils/Constants";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import Loader from "../UI/Loader";
import Checkbox from "../UI/Checkbox";
// import { UPDATE_BUILTY_AVAILABLE } from "../../Utils/ApiGlobal";
// import axiosInstance from "../../Utils/API";
// import { ConfirormationModal } from "../UI/ConfirormationModal";

export default function PendingBooking(props) {
  const [ApiData, SetApiData] = useState([]);
  const [ApiDataCount, SetApiDataCout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // var Today_Date = new Date();

  useEffect(() => {
    getAPI();
    // eslint-disable-next-line
  }, [props.searchValue, ApiDataCount]);

  // GET API
  const getAPI = () => {
    setIsLoading(true);
    axios
      .get(
        `${APIURL}/api/Booking/ViewBooking?search=${props.searchValue}&pageNo=&pageSize=`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        SetApiDataCout(response.data.data.count);
        SetApiData(response.data.data.booking);
        // console.log(response.data.data.booking)
        setIsLoading(false);
      });
  };

  // Builtty checkbox APIS
  // const postBuilltyRecive = (bookingId, builltyValue) => {
  //   let builltyParams = {
  //     BookingId: bookingId,
  //     IsBilltyAvailable: builltyValue,
  //     billtyReceiveDate: Today_Date,
  //   };
  //   setIsLoading(true);
  //   axiosInstance
  //     .post(UPDATE_BUILTY_AVAILABLE, builltyParams)
  //     .then((response) => {
  //       ConfirormationModal(response.data.data.message, "success", 2000);
  //       getAPI();
  //     })
  //     .catch((error) => {
  //       ConfirormationModal(error.message, "error", 2000);
  //     });
  // };
  // Columns
  const columns = [
    {
      Header: "IS Buillty Recived",
      accessor: "isBiltyReceive",
      id: "isBiltyReceive",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <Checkbox
            defaultChecked={row.row.original.isBiltyReceive}
            onClick={(e) => {
                e.preventDefault();
                return false;
            }}
            className="accent-cyan-600	text-white"
          />
        </div>
      ),
    },
    {
      Header: "Action",
      id: "Action",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <TableAction
            isBilling
            isBuillty
            data={row.row.original}
            redirectPath="pending-booking/update-booking"
            redirectViewPath="pending-booking/booking-data-view"
            redirectBuillty="bilty/billty-invoice-view"
            redirectBilling="billing/billing-view"
            component="Booking"
            getAPI={getAPI}
          />
        </div>
        
      ),
    },
    {
      Header: "L. Date",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle No.",
      accessor: "vehicleNo",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    // {
    //   Header: "Company Name",
    //   accessor: "Company_Name",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Address",
    //   accessor: "Address",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "GST No.",
    //   accessor: "Gst_No",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: "Consignor's Name",
      accessor: "consignerName",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    // {
    //   Header: "Consignor's Address",
    //   accessor: "consignerAddress",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Consignor's GST No.",
    //   accessor: "consignerGst",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: "Consignee's Name",
      accessor: "consigneeName",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    // {
    //   Header: "Consignee's Address",
    //   accessor: "consigneeAddress",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Consignee's GST No.",
    //   accessor: "consigneeGst",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Packages",
    //   accessor: "packages",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Actual Weight",
    //   accessor: "actualWeight",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Charged Weight",
    //   accessor: "chargedWeight",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Rate",
    //   accessor: "Rate",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "From",
      accessor: "from",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "Delivery At",
    //   accessor: (d) => {
    //     return d.to;
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "BOOKING AMOUNT",
      accessor: "bookingAmount",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "LR No.",
      accessor: "lrNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "Bill No.",
    //   accessor: "billNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Fright",
    //   accessor: "freigth",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Advance",
    //   accessor: "advance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Balance",
    //   accessor: "balance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Hamali",
    //   accessor: "hamali",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Detention",
    //   accessor: "detention",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Extra Charge",
    //   accessor: "extraCharge",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Total",
    //   accessor: "total",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Vehicle Owner",
    //   accessor: "Vehicle_Owner",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "DRIVER PAID BALANCE",
    //   accessor: "isDriverPaidBalance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BALANCE PAID BILL NO.",
    //   accessor: "balanceInvoiceId",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BILLTY RECEVIED OR NOT",
    //   // isBiltyReceive
    //   accessor: (d) => {
    //     if (d.isBiltyReceive === true) {
    //       return "Yes";
    //     } else {
    //       return "No";
    //     }
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Note",
    //   accessor: "note",
    //   style: {
    //     fontWeight: "bolder",
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Description",
    //   accessor: "Description",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          <div className="mt-4 ">
            <TablePagination
              data={ApiData}
              columns={columns}
            />
          </div>
        </section>
      )}
    </>
  );
}
