import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../Utils/API";
import { GET_VEHICLE, 
        // GET_VEHICLE_OWNERBYID 
      } from "../../Utils/ApiGlobal";
const animatedComponents = makeAnimated();

export default function SearchVehicleNo({
  // vehicleOwnerId,
  handleOnChange,
  defaultValue,
}) {
  const [optionsVehicleNo, setOptions] = useState({});

  // useEffect(() => {
  //   console.log(vehicleOwnerId);
  //   if (vehicleOwnerId !== undefined) {
  //     axiosInstance
  //       .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
  //       .then((response) => {
  //         console.log(response?.data?.data)
  //         if (response?.data?.data.length > 0) {
  //           const options = response?.data?.data.map(
  //             ({ vehicleNo, vehicleId }, index) => ({ vehicleNo, vehicleId })
  //           );
  //           setOptions(options);
  //         }
  //       });
  //   } else {
  //     axiosInstance.get(`${GET_VEHICLE}`).then((response) => {
  //       console.log(response?.data?.data);
  //       if (response?.data?.data.vehicles.length > 0) {
  //         const options = response?.data?.data.vehicles.map(
  //           ({ vehicleNo, vehicleId,ownerName }, index) => ({ vehicleNo, vehicleId,ownerName })
  //         );
  //         setOptions(options);
  //       }
  //     });
  //   }
  // }, [vehicleOwnerId, defaultValue]);

  // return (
  //   <>
  //     {optionsVehicleNo.length > 0 ? (
  //       <Select
  //         components={animatedComponents}
  //         options={optionsVehicleNo.map((el) => ({
  //           label: el.vehicleNo,
  //           value: el.vehicleId,
  //           vehicleOwner:el.ownerName
  //         }))}
  //         onChange={handleOnChange}
  //         placeholder="Select Vehicle Number"
  //         theme={(theme) => ({
  //           ...theme,
  //           borderRadius: 5,
  //           colors: {
  //             ...theme.colors,
  //             primary25: "#8cd7de",
  //             primary: "#179eaa",
  //           },
  //         })}
  //       />
  //     ) : (
  //       <Select
  //         defaultValue={{ label: defaultValue, value: defaultValue }}
  //         onChange={handleOnChange}
  //         placeholder="Select Vehicle Number"
  //         theme={(theme) => ({
  //           ...theme,
  //           borderRadius: 5,
  //           colors: {
  //             ...theme.colors,
  //             primary25: "#8cd7de",
  //             primary: "#179eaa",
  //           },
  //         })}
  //       />
  //     )}
  //   </>
  // );

  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        console.log(response?.data?.data)
        if (response?.data?.data?.vehicles.length > 0) {
          const options = response?.data?.data?.vehicles.map(
            ({ vehicleNo, vehicleId, ownerId, ownerName, ownerCommissionType, ownerCommissionAmount}, index) => ({
              vehicleNo,
              vehicleId,
              ownerId,
              ownerName,
              ownerCommissionType,
              ownerCommissionAmount,
            })
          );
          setOptions(options);
        }
      });
  }, []);

  useEffect(() => {
    setOptions([]);
    // console.log(newvehicleOwnerName, "newvehicleOwnerName");
  }, []);

  return (
    <>
      {optionsVehicleNo.length > 0  ?(
        <Select
          options={optionsVehicleNo.map((el) => ({
            label: el.vehicleNo,
            value: el.vehicleId,
            ownerName: el.ownerName,
            ownerId: el.ownerId,
            ownerCommissionType: el.ownerCommissionType,
            ownerCommissionAmount: el.ownerCommissionAmount,
          }))}
          defaultValue={{
            label: defaultValue,
            value: defaultValue,
          }}
          placeholder="Select Vehicle Owner"
          components={animatedComponents}
          onChange={handleOnChange}
          // options={options}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#8cd7de",
              primary: "#179eaa",
            },
          })}
        />
      ): (
        <p
        className="
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-gray-100 bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      >
        Select Vehicle No.
      </p>
      )}
    </>
  );

}
