import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axiosInstance from "../../Utils/API";
import { UPDATE_BUILTY } from "../../Utils/ApiGlobal";
import moment from "moment";
import { WeightUnitOptions } from "../../Utils/Constants";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
function isNumber(str) {
  return !isNaN(str);
}
export default function UpdateBuillty(data) {
  const history = useHistory();
  const [year] = useState(new Date().getFullYear());
  const [bookingData, setBookingData] = useState();
  const [startDate, setStartDate] = useState(
    moment(bookingData?.lrDate).toDate()
  );
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [showHamali, setShowHamali] = useState(true);
  const [weightUnit, setWeightUnit] = useState();
  const [onChangeWeight, SetonChangeWeight] = useState(
    bookingData?.actualWeight ? bookingData?.actualWeight : ""
  );
  // To fetch Data from previous route
  useEffect(() => {
    setBookingData(data?.data);
  }, [data?.data]);
 // Window Back btn path set
 useEffect(() => {
  return () => {
      if (history.action === "POP") {
        history.location.pathname="/pending-booking"
        history.replace("/pending-booking");
      }
  }
})
  const handleChangeWight = (e) => {
    setWeightUnit(e.value);
  };
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const FormSubmitHandler = async (e, path) => {
    let btnValue = e.nativeEvent.submitter.value;
    if (btnValue === "save") {
      path = "/bilty/billty-invoice-view";
    } else if (btnValue === "saveAndaddBuillty") {
      path = "/bilty/add-Multiple-builty";
    }
    e.preventDefault();
    setLoader({ ...loader, loading: true });
    const obj = formValueConvertor(getFormJSON(e.target));
    const ADD_BILLTY_DATA = {
      BookingId: bookingData?.bookingId,
      BilltyId: bookingData?.billtyId,
      FinancialYear: obj.Year,
      LrNo: obj.LR_No,
      LrDate: startDate,
      VehicleNo: obj.Vehicle_No,
      DeliveryAt: obj.Delivery_At,
      ConsignerName: obj.Consignor_Name,
      ConsignerGST: obj.Consignor_GST_No,
      ConsignerAddress: obj.Consignor_Address,
      ConsigneeName: obj.Consignee_Name,
      ConsigneeGst: obj.Consignee_GST_No,
      ConsigneeAddress: obj.Consignee_Address,
      From: obj.From,
      To: obj.To,
      Packages: obj.Pakages,
      Detention: obj.detention === " " ? 0: obj.detention,
      ExtraCharge:
        obj.extraCharge === " " ? 0 : obj.extraCharge ,
      Hamali: obj.hamali === " " ? 0 : obj.hamali ,
      Description: obj.Description,
      ActualWeight: obj.Actual_Weight,
      ChargedWeight: onChangeWeight,
      WeightType:
        onChangeWeight.toLowerCase() === "fix" ||
        !onChangeWeight ||
        !isNumber(onChangeWeight)
          ? "NA"
          : weightUnit,
      Createdby: localStorage.getItem("userId"),
    };
    //  console.log(ADD_BILLTY_DATA)
    await axiosInstance
      .post(UPDATE_BUILTY, ADD_BILLTY_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: path,
          state: { bookingId: bookingData?.bookingId },
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  return (
    <>
      {/* <DashBoardLayout pageTitle="Update Buillty"> */}
      <APILoader loader={loader} />
      <div className="   ">
        <form onSubmit={FormSubmitHandler}>
          <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
            <div className=" w-full">
              <div className=" text-left space-y-6">
                {/* Fin year,Lr no, lr date*/}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Fin. Year" isRequired="true" />
                    <Input type="text" name="Year" defaultValue={year} />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="LR No." isRequired="true" />
                    <Input
                      type="text"
                      name="LR_No"
                      defaultValue={bookingData?.lrNo}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="LR Date" isRequired="true" />
                    <div className="flex flex-row justify-center ">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                {/* Delievery at,Vehicle_No, consignor name */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Delivery At"  />
                    <Input
                      type="text"
                      name="Delivery_At"
                      defaultValue={bookingData?.from}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Vehicle No." isRequired="true" />
                    <Input
                      type="text"
                      name="Vehicle_No"
                      defaultValue={bookingData?.vehicleNo}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignor's Name" />
                    <Input
                      type="text"
                      name="Consignor_Name"
                      defaultValue={bookingData?.consignerName}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {/* Consignor Address,Gst,consignee name */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Address" />
                    <Input
                      type="text"
                      name="Consignor_Address"
                      defaultValue={bookingData?.consignerAddress}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignor's GST" />
                    <Input
                      type="text"
                      name="Consignor_GST_No"
                      defaultValue={bookingData?.consignerGst}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Name" />
                    <Input
                      type="text"
                      name="Consignee_Name"
                      defaultValue={bookingData?.consigneeName}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {/* Consignee_add,consignee gst from */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Address"  />
                    <Input
                      type="text"
                      name="Consignee_Address"
                      defaultValue={bookingData?.consigneeAddress}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's GST" />
                    <Input
                      type="text"
                      name="Consignee_GST_No"
                      defaultValue={bookingData?.consigneeGst}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="From"/>
                    <Input
                      type="text"
                      name="From"
                      defaultValue={bookingData?.from}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* To, Pakages ,description*/}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="To"  />
                    <Input
                      type="text"
                      name="To"
                      defaultValue={bookingData?.to}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Packages"  />
                    <Input
                      type="text"
                      name="Pakages"
                      defaultValue={bookingData?.packages}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col text-left w-1/2">
                    <Label label="Description" />
                    <Input
                      type="text"
                      className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      name="Description"
                      cols="30"
                      rows="5"
                      defaultValue={bookingData?.description}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {/*Actual weight, weight unit and charged weight*/}
                <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Actual Weight"  />
                    <Input
                      name="Actual_Weight"
                      // required
                      autoComplete="off"
                      defaultValue={bookingData?.actualWeight}
                      onChange={(e) => {
                        SetonChangeWeight(e.target.value);
                      }}
                      // SetonChangeWeight
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Weight Unit"/>
                    {onChangeWeight.toLowerCase() === "fix" ||
                    !isNumber(onChangeWeight) ||
                    !isNumber(data?.data?.actualWeight) ? (
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        NA
                      </p>
                    ) : (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "#8cd7de",
                            primary: "#179eaa",
                          },
                        })}
                        defaultValue={{
                          label: data?.data?.weightType,
                          value: data?.data?.weightType,
                        }}
                        onChange={handleChangeWight}
                        options={WeightUnitOptions}
                      />
                    )}
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Charged Weight" />
                    <Input
                      defaultValue={
                        onChangeWeight || bookingData?.chargedWeight
                      }
                      type="number"
                      step="any"
                      name="Charged_Weight"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div>
                  <div className=" flex flex-row space-x-5 text-left ">
                    <button
                      className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md text-white "
                      type="button"
                      onClick={() => {
                        setShowHamali(true);
                      }}
                    >
                      +
                    </button>
                    <button
                      className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md text-white "
                      type="button"
                      onClick={() => {
                        setShowHamali(false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
                {showHamali && (
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Hamali"  />
                      <Input
                        defaultValue={bookingData?.hamali}
                        //   onChange={values_handler}
                        name="hamali"
                        type="number"
                        step="any"
                      />
                    </div>
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Detention" />
                      <Input
                        //   onChange={values_handler}
                        defaultValue={bookingData?.hamali}
                        name="detention"
                        type="number"
                        step="any"
                      />
                    </div>
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Extra Charge"  />
                      <Input
                        defaultValue={bookingData?.extraCharge}
                        name="extraCharge"
                        type="number"
                        step="any"
                      />
                    </div>
                  </div>
                )}
                {/* <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Invoice No." isRequired="true" />
                        <Input
                          type="text"
                          name="Invoice_No"
                          // required
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label
                          label="Amount To Pay Or Paid"
                          isRequired="true"
                        />
                        <Input
                          name="Amount_To_Pay"
                          // required
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Total" isRequired="true" />
                        <Input
                          type="text"
                          name="Total"
                          // required
                          autoComplete="off"
                        />
                      </div>
                    </div> */}
              </div>
            </div>
          </div>

          <div className="items-ceter mt-4 space-x-2">
            <ButtonWithBG
              value="save"
              theme="primary"
              label="SAVE"
              type="submit"
            />
            <ButtonWithBG
              type="submit"
              value="saveAndaddBuillty"
              theme="secondary"
              label="Save and add buillty"
            />
          </div>
        </form>
      </div>
      {/* </DashBoardLayout> */}
    </>
  );
}
