import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { useEffect } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import { GETVIEWBOOKINGBYID } from "../../Utils/ApiGlobal";
import UpdateBuillty from "./UpdateBuillty";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Buillty() {
  const location = useLocation();
  const [builltyData, setBuilltyData] = useState([]);

  useEffect(() => {
    const BookingId = location?.state?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);
  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        setBuilltyData(response?.data?.data?.billtyDetails);
      });
  };
  return (
    <DashBoardLayout pageTitle="Edit Buillty">
      <div className="w-full  px-1  sm:px-0">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            {builltyData.map((subData, i) => (
              <Tab
                key={i}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-1 text-sm font-medium leading-5 text-black-700",
                    "ring-primary-kirtiroadways-hover ring-opacity-60 ring-offset-2 ring-offset-kirtiroadways-hover focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow text-primary-kirtiroadways font-bold"
                      : "text-primary-kirtiroadways hover:bg-primary-kirtiroadways/[0.12] "
                  )
                }
              >
                Buillty: {i + 1}
                <br />
                From: {subData.from} - To: {subData.to}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {builltyData.map((subData, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  "rounded-xl bg-white p-3",
                )}
              >
                <UpdateBuillty data={subData}/>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </DashBoardLayout>
  );
}
