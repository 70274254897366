import React, { useState, useEffect } from "react";
// import { Vehicle } from "../../Utils/Constants";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import { GET_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";

export default function VehicleTable(props) {
  let history = useHistory();
  const [VehicleWonerData, SetVehicleWonerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToAddVehicleDetails = (event, data) => {
    // console.log(event);
    // console.log(data);
    history.push({
      pathname: "/vehicle/add-vehicle-details",
      state: { data: VehicleWonerData[data] },
    });
    // setVehicleOwnertableIndex(VehicleWonerData[data])
  };

  // for Searching By Owner Name
  const filterOwnerNames = ({ name }) => {
    return (
      name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1
    );
  };

  const columns = [
    {
      Header: "Action",
      id: "Action",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <TableAction
            isLedger
            isBilling
            component="Vehicle Owner"
            data={row.row.original}
            redirectBilling="vehicle/vehicle-invoice"
            redirectPath="vehicle/add-edit-vehicle"
            redirectViewPath="vehicle/vehicle-owner-view"
            redirectLedger="Ledger-Details"
            getAPI={_fetchVehicleData}
          />
        </div>
      ),
    },
    {
      Header: "Owner Name",
      accessor: "name",
      maxWidth: 200,
      minWidth: 200,
      width: 100,
      Cell: ({ cell }) => (
        <div className="flex justify-start ml-10">
          <button
            className="border-b-2 border-primary-kirtiroadways text-left"
            value={cell.row.values.name}
            onClick={(event) =>
              redirectToAddVehicleDetails(event, cell.row.id)
            }
          >
            {cell.row.values.name}
          </button>
        </div>
      ),
    },
    {
      Header: "Pan Number",
      accessor: "panNo",
      // Cell:row => <div className="text-left">{row.value}</div>,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      // id: 'Number',
      // Cell: ({ cell }) => (
      //   <div>
      //     <button
      //       value={cell.row.values.panNo}
      //       onClick={(event) =>
      //         redirectToAddVehicleDetails(event, cell.row.id)
      //       }
      //     >
      //       {cell.row.values.panNo}
      //     </button>
      //   </div>
      // ),
    },
    // {
    //   Header: "Contact Person",
    //   accessor: "contactPerson",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: "Contact NUMBER",
      accessor: "contactNo",
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
  ];

  useEffect(() => {
    _fetchVehicleData();
  }, []);

  const _fetchVehicleData = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GET_VEHICLE_OWNER}?search=&pageNo=1&pageSize=`)
      .then((response) => {
        const data = response?.data?.data?.owners;
        SetVehicleWonerData(data);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
      // For Delete Table list Data start
      // const DeleteVehicleOwnerHandler = async(event) => {
      //   let DELETE_VEHICLE_OWNER_DATA = { 
      //     OwnerId: VehicleWonerData,
      //   };
      //   console.log(DELETE_VEHICLE_OWNER_DATA )
      //   await axiosInstance
      //     .post(DELETE_VEHICLE_OWNER  , DELETE_VEHICLE_OWNER_DATA )
      //     .then((response) => {
      //       ConfirormationModal(response.data.data.message, "success", 2000);
      //       window.location.reload(false);
      //     })
      //     .catch((error) => {
      //       ConfirormationModal(error.message, "error", 2000);
      //     });
      //   }
      // For Delete Table list Data end

  // console.log(props,"for table")
  return (
    <section>
      <div className="mt-2 ">
        {isLoading ?
          (<Loader />)
          :
          (<TablePagination 
            data={VehicleWonerData.filter(filterOwnerNames)}
            columns={columns} />)
        }
      </div>
    </section>
  );
}
