import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../Utils/API";
import { GET_VEHICLE_OWNERBYID, GET_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
// import Loader from "../UI/Loader";
const animatedComponents = makeAnimated();

export default function SearchVelicleOwner({
  newvehicleOwnerName,
  handleOnChange,
  defaultValue,
  vehicleOwnerId, 
}) {
  const [optionsOwner, setOptions] = useState({});
  
  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE_OWNER}?search=&pageNo=&pageSize=`)
      .then((response) => {
        if (response?.data?.data?.owners.length > 0) {
          const options = response?.data?.data?.owners.map(
            ({ name, ownerId, commissionType, amount }, index) => ({
              name,
              ownerId,
              amount,
              commissionType,
            })
          );
          setOptions(options);
        }
      });
  }, []);

  useEffect(() => {
    setOptions([]);
    // console.log(newvehicleOwnerName, "newvehicleOwnerName");
  }, [newvehicleOwnerName]);

  return (
    <>
      {optionsOwner.length > 0 && !newvehicleOwnerName  ?(
        <Select
          options={optionsOwner.map((el) => ({
            label: el.name,
            value: el.ownerId,
            commissionType: el.commissionType,
            amount: el.amount,
          }))}
          defaultValue={{
            label: defaultValue,
            value: defaultValue,
          }}
          placeholder="Select Vehicle Owner"
          components={animatedComponents}
          onChange={handleOnChange}
          // options={options}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#8cd7de",
              primary: "#179eaa",
            },
          })}
        />
      ): (
        <p
        className="
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-gray-100 bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      >
        {newvehicleOwnerName}
      </p>
      )}
    </>
  );
  


// useEffect(() => {
//     console.log(vehicleOwnerId);
//     if (vehicleOwnerId  !== undefined) {
//       axiosInstance
//         .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
//         .then((response) => {
//           console.log(response?.data?.data)
//           if (response?.data?.data.length > 0) {
//             const options = response?.data?.data.map(
//               ({ vehicleNo, ownerName ,ownerId, ownerCommissionType, vehicleId }, index) => ({ 
//                 vehicleNo,
//                 vehicleId,
//                 ownerName,
//                 ownerId,
//                 ownerCommissionType,
//                })
//             );
//             setOptions(options);
//           }
//         });
//     } else {
//       axiosInstance.get(`${GET_VEHICLE_OWNER}`).then((response) => {
//         console.log(response?.data?.data);
//         if (response?.data?.data.vehicles.length > 0) {
//           const options = response?.data?.data.vehicles.map(
//             ({ vehicleNo, vehicleId,ownerName }, index) => ({ vehicleNo, vehicleId,ownerName })
//           );
//           setOptions(options);
//         }
//       });
//     }
//   }, [vehicleOwnerId, defaultValue]);

//   return (
//     <>
//       {optionsOwner.length > 0 ? (
//         <Select
//           components={animatedComponents}
//           options={optionsOwner.map((el) => ({
//             label: el.ownerName,
//             value: el.vehicleId,
//             ownerId: el.ownerId,
//             vehicleNo: el.vehicleNo,
//             ownerCommissionType: el.ownerCommissionType
//           }))}
//           onChange={handleOnChange}
//           placeholder="Select Vehicle Owner"
//           theme={(theme) => ({
//             ...theme,
//             borderRadius: 5,
//             colors: {
//               ...theme.colors,
//               primary25: "#8cd7de",
//               primary: "#179eaa",
//             },
//           })}
//         />
//       ) : (
//         <Select
//           defaultValue={{ label: defaultValue, value: defaultValue }}
//           onChange={handleOnChange}
//           placeholder="Select Vehicle Owner"
//           theme={(theme) => ({
//             ...theme,
//             borderRadius: 5,
//             colors: {
//               ...theme.colors,
//               primary25: "#8cd7de",
//               primary: "#179eaa",
//             },
//           })}
//         />
//       )}
//     </>
//   );

}
