import React, { useState, useEffect } from "react";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import Loader from "../../Components/UI/Loader";
import axiosInstance from "../../Utils/API";
import {
  GET_COMPANY_INFORMATION_DATA,
  UPDATE_PROFILE_DETAILS,
} from "../../Utils/ApiGlobal";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import { add_svg, delete_svg } from "../../Utils/SVGListing";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-base"
    {...props}
  />
);
const LabelDisplay = (props) => (
  <p className="flex font-semibold underline flex-row text-gray-500 text-sm  ">
    {props.label} :
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-base font-medium ">
          *
        </span>
      </>
    )}
  </p>
);
const Label = (props) => (
  <p className="flex flex-row text-primary-kirtiroadways text-base font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-base font-medium ">
          *
        </span>
      </>
    )}
  </p>
);

export const CompanyInformation = () => {
  const [showInput, setShowInput] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [vehicileArr, setVehicleArr] = useState();
  //   const [notif, setNotif] = useState(false);
  //   const [notifOpts, setNotifOpts] = useState({});
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  useEffect(() => {
    getProfileAPI();
  }, []);

  const getProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        // console.log(response?.data?.data?.secondaryPhoneNo);
        const data = response?.data?.data;
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
        setVehicleArr(response?.data?.data?.secondaryPhoneNo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...vehicileArr];
    list[index] = value;
    setVehicleArr(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...vehicileArr];
    list.splice(index, 1);
    setVehicleArr(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setVehicleArr([...vehicileArr, ""]);
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const FormSubmitHandler = async (ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    var obj = formValueConvertor(getFormJSON(ev.target));
    // console.log(obj);
    let UPDATE_PROFILE_DATA = {
      UserId: profileData.userId,
      OwnerName: obj.OwnerName.toUpperCase(),
      Email: obj.Email,
      Address: obj.address,
      Service: obj.Service.toUpperCase(),
      PanNo: obj.PanNo.toUpperCase(),
      SecondaryPhoneNo: vehicileArr,
      StateCode: obj.StateCode,
      CompanyName: obj.CompanyName.toUpperCase(),
      TagLine: obj.TagLine.toUpperCase(),
      SubTagLine: obj.SubTagLine,
      PolicyNo: obj.PolicyNo.toUpperCase(),
    };
    await axiosInstance
      .post(UPDATE_PROFILE_DETAILS, UPDATE_PROFILE_DATA)
      .then((response) => {
        setLoader({ ...loader, loading: true });
        ConfirormationModal(response.data.data.message, "success", 2000);
        setLoader({
          loading: false,
          message: "",
          icon: "",
        });
        setShowInput(true);
        getProfileAPI();
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  return (
    <>
      <APILoader loader={loader} />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {/*Edit Button */}
          <div className="flex flex-row justify-end items-center mt-5">
            <div>
              <ButtonWithBG
                handleClick={() => {
                  setShowInput(false);
                }}
                theme="primary"
                label="Edit Profile"
              />
            </div>
          </div>

          <div className="">
            {!showInput ? (
              <form onSubmit={FormSubmitHandler}>
                <div className=" flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-2">
                  <div className=" w-full">
                    <div className=" text-left space-y-6">
                      <div className="flex md:flex-row  flex-col md:space-x-4 md:space-y-0 space-y-2">
                        {/* Tag Line Input field */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Tag Line" />
                          <Input
                            type="text"
                            name="TagLine"
                            defaultValue={profileData.tagLine}
                          />
                        </div>
                        {/* Sub tag line Input field */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Sub Tag Line" />
                          <Input
                            type="text"
                            name="SubTagLine"
                            defaultValue={profileData.subTagLine}
                          />
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                        {/* Email Input field  */}
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Email" />
                          <Input
                            type="text"
                            name="Email"
                            defaultValue={profileData.email}
                            autoComplete="off"
                          />
                        </div>

                        <div className=" flex flex-col  text-left md:w-1/2">
                          <div className="flex flex-row justify-betweem">
                            {/* Mobilr phone Number add delete functionallity  */}
                            <Label label="Mobile Number" />
                          </div>
                          {vehicileArr.map((x, i) => {
                            return (
                              <div className="flex flex-row" key={i}>
                                <input
                                  name="vehicleNo"
                                  className="border-gray-300
                                  form-control
                                  block
                                  w-full
                                  px-3
                                  py-1.5
                                  text-base
                                  font-normal
                                  bg-white bg-clip-padding
                                  transition
                                  ease-in-out
                                  m-0
                                  rounded-l-md
                                  border-t  border-b border-l
                                  focus:text-gray-700 focus:bg-white  focus:outline-none"
                                  placeholder="Enter Phone No"
                                  value={x}
                                  type = "number"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                                {/* Add and delete button for vehicle No */}
                                <div className="flex flex-row space-x-2 -ml-px relative inline-flex items-center space-x pr-2 border-y border-r border-gray-300 text-sm font-medium rounded-r-md text-primary-kirtiroadways">
                                  {vehicileArr.length - 1 === i && (
                                    <button
                                      type="button"
                                      onClick={handleAddClick}
                                    >
                                      {add_svg}
                                    </button>
                                  )}
                                  {vehicileArr.length !== 1 && (
                                    <button
                                      type="button"
                                      className="mr10 text-red-500"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      {delete_svg}
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {/* <p className="text-base font-semibold tracking-widest	">
                            {profileData.userName}
                          </p> */}
                        </div>
                      </div>

                      {/* Address  Input field */}
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Address" />
                          <textarea
                            className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-base"
                            name="address"
                            rows="2"
                            cols="4"
                            defaultValue={profileData.address}
                            autoComplete="off"
                          ></textarea>
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                        {/* Company Name Input field  */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Company Name" />
                          <Input
                            type="text"
                            name="CompanyName"
                            defaultValue={profileData.companyName}
                          />
                        </div>
                        {/* Owner Name Input Field */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Owner Name" />
                          <Input
                            type="text"
                            name="OwnerName"
                            defaultValue={profileData.ownerName}
                            autoComplete="off"
                          />
                        </div>
                        {/* Pan No Input Field  */}
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Pan No." />
                          <Input
                            type="text"
                            name="PanNo"
                            defaultValue={profileData.panNo}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                        {/* Service Input Field  */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="Service" />
                          <Input
                            type="text"
                            name="Service"
                            defaultValue={profileData.service}
                            autoComplete="off"
                          />
                        </div>
                        {/* Policy No Input Field  */}
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Policy No." />
                          <Input
                            type="text"
                            name="PolicyNo"
                            defaultValue={profileData.policyNo}
                            autoComplete="off"
                          />
                        </div>
                        {/* State Code Input Field  */}
                        <div className=" flex md:w-1/2  flex-col  text-left">
                          <Label label="State Code" />
                          <Input
                            type="text"
                            name="StateCode"
                            defaultValue={profileData.stateCode}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                        {/* Form Submit Button */}
                      <div className="flex flex-row justify-center">
                        {!showInput && (
                          <ButtonWithBG
                            theme="primary"
                            label="Save"
                            type="submit"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="p-5 rounded-lg border-2 border-gray-200 mt-2 bg-white shadow flex flex-col md:flex-row  md:space-y-0 space-y-1">
                <div className=" w-full text-left space-y-4">
                  <div className="flex md:flex-row  flex-col md:space-x-4 md:space-y-0 space-y-2">
                    {/* Tag Line view */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="Tag Line" />
                      <p className="text-sm font-semibold tracking-wider	">
                        {" "}
                        {profileData.tagLine}
                      </p>
                    </div>
                    {/* Subtagline view */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="Sub Tag Line" />
                      <p className="text-sm font-semibold tracking-widerr	">
                        {" "}
                        {profileData.subTagLine}
                      </p>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      {/* Email View */}
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <LabelDisplay label="Email" />
                        <p className="text-sm font-semibold tracking-wider	">
                          {profileData.email}
                        </p>
                      </div>
                    </div>
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      {/* Phone Number view */}
                      <LabelDisplay label="Phone No." />
                      <div className="flex flex-row">
                        {secondaryPhoneNo.map((number, i) => (
                          <p
                            key={number}
                            className="text-sm font-semibold tracking-wider flex flex-row"
                          >
                            {(i ? ", " : "") + number}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Address */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="Address" />
                      <p className="text-sm font-semibold tracking-wider	">
                        {profileData.address}
                      </p>
                    </div>
                  </div>

                  <hr className="my-2" />

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                    {/* Company Name */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <LabelDisplay label="Company Name" />
                      <p className="text-sm font-semibold tracking-wider">
                        {" "}
                        {profileData.companyName}
                      </p>
                    </div>{" "}
                    {/* Owner Name */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="Owner Name" />
                      <p className="text-sm font-semibold tracking-wider">
                        {profileData.ownerName}
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                    {/* Pan No */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <LabelDisplay label="Pan No." />
                      <p className="text-sm font-semibold tracking-wider	">
                        {profileData.panNo}
                      </p>
                    </div>
                    {/* Service */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="Service" />
                      <p className="text-sm font-semibold tracking-wider">
                        {profileData.service}
                      </p>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-2">
                    {/* Policy No */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <LabelDisplay label="Policy No." />
                      <p className="text-sm font-semibold tracking-wider">
                        {profileData.policyNo}
                      </p>
                    </div>
                    {/* State Code */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <LabelDisplay label="State Code" />
                      <p className="text-sm font-semibold tracking-wider">
                        {profileData.stateCode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
