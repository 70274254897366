import React, { useEffect, useState } from "react";
import LendgerTable from "../../Components/DataTables/LendgerTable";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import AddEditLedger from "./AddEditLedger";
// import Button from "../../Components/UI/Button/Button";
// import { setting_svg } from "../../Utils/SVGListing";
import { useLocation } from "react-router-dom";

export default function LedgerDetails() {
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  useEffect(() => {
  }, [location]);

  const [pageTitle] = useState(
    `${location?.data?.data.name|| location?.data?.data?.transporterName} Ledger Details`
  );
  return (
    <DashBoardLayout pageTitle={pageTitle}>
      <div className="flex flex-row justify-between ">
        <div>
          <SearchField
            placeholder="Searching BY Owner Name"
            handleChange={setSearchValue}
            value={searchValue}
          />
        </div>
        {/* <div className="flex flex-row space-x-2  items-start">
          <Button svg={setting_svg}></Button>
          <AddEditLedger
            companyName={location?.data?.data?.name}
            companyNumber={location?.data?.data?.contactNumber}
            component={location?.component}
          />
        </div> */}
      </div>
      <LendgerTable component={location?.component} searchValue={searchValue} />
    </DashBoardLayout>
  );
}
