import React from 'react';
import { useHistory } from 'react-router-dom';

const Card = (props) => {
    let history = useHistory ();
    return (
        <div className='cursor-pointer'
            onClick={()=>{history.push (props.path)}}>
            <div className="border-2 border-primary-kirtiroadways px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                <div className='flex flex-row justify-center mt-0 mb-3 text-primary-kirtiroadways'>
                  {props.svg}
                </div>
                <h6 className="title-font font-medium text-3xl text-gray-900">{props.countData}</h6>
                <h1 className="leading-relaxed">{props.title}</h1> 
            </div>
        </div>
    )
}

export default Card;
