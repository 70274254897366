import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../../Utils/API";
import { ADD_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import { UPDATE_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import Select from "react-select";
import Loader from "../../Components/UI/Loader";
import { CommissionOptions } from "../../Utils/Constants";
import Notification from "../../Components/UI/Notification";
import APILoader from "../../Components/UI/APILoader";
const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditVehicle({ props }) {
  const location = useLocation();
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [commission, setCommission] = useState(false);
  const [commissionType, setCommissionType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  const SelectHandler = (e) => {
    setCommissionType(e.value);
    if (e.value === "Manual") {
      setCommission(true);
    } else {
      setCommission(false);
    }
  };

  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Vehicle Owner");
    } else {
      setHeading("Edit Vehicle Owner");
    }
    if (location?.state?.data?.data?.commissionType === "Manual") {
      setCommission(true);
    }
  }, [location]);

  // For Loading
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, []);

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  //   Form Submit click event
  const submitForms = async (ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    var obj = formValueConvertor(getFormJSON(ev.target));
    localStorage.setItem("objData", JSON.stringify({ ...obj }));
    // console.log(obj);

    if (location?.state?.label === "Add") {
      let VEHICLE_OWNER_DATA = {
        Name: obj.Owner_Name.toUpperCase(),
        PanNo: obj.Pan_No === " " ? "NA" : obj.Pan_No.toUpperCase(),
        ContactNo: obj.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
        CommissionType: commissionType,
        Amount: parseInt(obj.manualCommission),
      };
      await axiosInstance
        .post(ADD_VEHICLE_OWNER, VEHICLE_OWNER_DATA)
        .then((response) => {
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/vehicle",
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
    } else {
      let EDIT_VEHICLE_OWNER_DATA = {
        OwnerId: location?.state?.data?.data?.ownerId,
        Name: obj.Owner_Name.toUpperCase(),
        PanNo: obj.Pan_No === " " ? "NA" : obj.Pan_No,
        ContactNo: obj.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
        CommissionType: commissionType,
        Amount: parseInt(obj.manualCommission),
      };
      await axiosInstance
        .post(UPDATE_VEHICLE_OWNER, EDIT_VEHICLE_OWNER_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/vehicle",
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
    }
  };
  return (
    <DashBoardLayout pageTitle={heading}>
      <APILoader loader={loader} />
       <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md h-full pb-16">
          <form onSubmit={submitForms}>
            <div className="h-full">
              <div className=" text-left space-y-8 h-full">
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Owner Name */}
                  <div className=" flex flex-col  text-left md:w-1/2 h-full">
                    <Label label="Owner Name" isRequired="true" />
                    <Input
                      defaultValue={location?.state?.data?.data?.name}
                      name="Owner_Name"
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left h-full">
                    <Label label="Pan No." />
                    <Input
                      defaultValue={location?.state?.data?.data?.panNo}
                      name="Pan_No"
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left w-full">
                    <Label label="Contact No." isRequired="true" />
                    <Input
                      defaultValue={location?.state?.data?.data?.contactNo}
                      name="Contact_No"
                      required
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 ">
                  <div className="w-1/2">
                    <Label label="Commission Type" />
                    <Select
                      defaultValue={{
                        label: location?.state?.data?.data?.commissionType,
                        value: location?.state?.data?.data?.commissionType,
                      }}
                      onChange={SelectHandler}
                      options={CommissionOptions}
                      placeholder="Select Commission Type"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "#8cd7de",
                          primary: "#179eaa",
                        },
                      })}
                    />
                  </div>
                  {commission && (
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Manual Commission Amount" />
                      <Input
                        type="number"
                        name="manualCommission"
                        // required
                        autoComplete="off"
                        defaultValue={location?.state?.data?.data?.amount}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="items-ceter mt-10">
              <ButtonWithBG theme="primary" label="SAVE" type="submit" />
            </div>
          </form>
        </div>
      )}
    </DashBoardLayout>
  );
}
