import React, { useState, useEffect } from "react";
import logo from "../../../Images/logo_green_transparent.png";
import logo_oposity from "../../../Images/logo_green_oposity.png";
import { GET_BANK_LIST } from "../../../Utils/ApiGlobal";
import axiosInstance from "../../../Utils/API";
import Select from "react-select";

import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  OURBANKDETAILS,
  GPAYCONTENT,
  PAYMENTBY,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import moment from "moment";
import CommissionInvoicePrint from "./CommissionInvoicePrint";

const CommissionInvoiceView = (props) => {
  // const [bankDetails, setBankDetails] = useState(true);
  const [bankBranch, setBankBranch] = useState({
    bank: "",
    branch: "",
    accNo: "",
    ifsc: "",
  });
  const [Dropdown, setDropdown] = useState(false);
  const [optionsBank, setOptions] = useState({});
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };

  // Access Bank Details
  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        setOptions(options);
        // console.log(options);
      }
    });
  };

  return (
    <>
      {props?.data?.transporterName && (
        <div className="m-5">
          <div
            className=" block border-double border-4 min-h-screen  border-cyan-200 align-top text-center bg-center bg-no-repeat  bg-[length:65%]"
            style={{
              backgroundImage: "url(" + logo_oposity + ")",
            }}
          >
            <div className="mt-2">
              <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-sm">
                <h5>Tax Invoice</h5>
              </div>
              {/* Logo and Title Start */}
              <div className="m-2 items-center space-x-3 flex flex-row">
                <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                  <div className="flex flex-row justify-between">
                    <div className="w-80 ml-2">
                      <img src={logo} alt="Kirti Roadlines" />
                    </div>
                    <div className=" flex flex-col ">
                      <span className="text-9xl text-primary-kirtiroadways font-extrabold ">
                        {props.profileData.tagLine}
                      </span>
                      <span className="text-4xl font-serif text-black">
                        {props.profileData.subTagLine}
                      </span>
                    </div>
                  </div>
                  {/* Address Start */}
                  <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                    {props.profileData.address !== " " && (
                      <p className="flex  text-md justify-center md:flex-row items-start">
                        <span className="text-md w-5">{address_svg}</span>{" "}
                        <span className="text-md ml-1">
                          {props.profileData.address}
                        </span>
                      </p>
                    )}
                    <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                      {(props.secondaryPhoneNo.length !== 0) && (
                        <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                          <span>{call_svg} </span>{" "}
                          <span className="flex flex-row">
                            {props.secondaryPhoneNo.map((number, i) => (
                              <span
                                key={number}
                                className="text-sm font-semibold tracking-wider flex flex-row"
                              >
                                {(i ? ", " : "") + number}
                              </span>
                            ))}
                          </span>
                        </div>
                      )}
                      {props.profileData.email !== " " && (
                        <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                          <span>{email_svg} </span>
                          <span>{props.profileData.email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Invoice  No and details*/}
            <div className="p-2 flex font-sans flex-row justify-between w-full">
              <div className="flex flex-col text-left w-1/2">
                <div className="font-bold uppercase text-md	py-1 mb-0 px-5 w-36 text-white rounded-lg bg-primary-kirtiroadways">
                  Invoice to
                </div>
                <div>
                  <span className="text-left font-bold uppercase font-sans text-2xl	subpixel-antialiased tracking-wide mt-0	">
                    {props?.data?.transporterName.toUpperCase()}
                  </span>
                </div>
                {props?.data?.address && (
                  <div className="text-sm text-left">
                    <div className="flex  flex-row space-x-1 mt-1">
                      <p>Address :</p>
                      <p className="w-1/2 ">
                        {props?.data?.address.toUpperCase()}
                      </p>
                    </div>
                  </div>
                )}
                {/* <div className="text-md mt-1">
                  <div className="flex flex-row space-x-1 text-left">
                    <p className="w-14">PAN :</p>
                    <p className="w-1/2">{props?.commissionData?.panNo}</p>
                  </div>
                </div> */}
              </div>

              {/* Right side */}
              <div className="flex  flex-col text-right justify-end items-end">
                <div className="font-bold uppercase text-base	py-1 mb-0 px-5 w-34 text-white rounded-lg bg-primary-kirtiroadways">
                  OUR Details
                </div>
                <div className="flex text-base  flex-row justify-end uppercase font-semibold pt-2">
                  <p className="flex flex-col ">
                    <span>Service : {props.profileData.service}</span>
                    <span>Pan No. : {props.profileData.panNo}</span>
                    <span>State Code : {props.profileData.stateCode}</span>
                  </p>
                  {/* <p className="flex flex-col">
                    <span>Transportation</span>
                    <span>AJGPD9182R</span>
                    <span>24</span>
                  </p> */}
                </div>

                <div className=" uppercase font-semibold mt-1  border-t-2 border-primary-kirtiroadways">
                  <div className="flex flex-row text-sm text-right space-x-2">
                    <span className="flex flex-col ">
                      <p className="text-right ">Invoice No :</p>
                      {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                      <p className="text-right ">Date :</p>
                      {/* <p className="text-right w-1/2">Transportation</p> */}
                    </span>
                    <span className="flex flex-col ">
                      <p className="text-left min-w-1/2">--</p>
                      {/* <p className="text-left min-w-1/2">--</p> */}
                      <p className="text-left min-w-1/2">{Today_Date}</p>
                      {/* <p className="text-right w-1/2">Transportation</p> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Table start*/}
            <div style={{ height: "390px" }} className="px-2 w-full">
              <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-base font-sans">
                <thead style={{ maxHeight: "5px" }} className="mb-2">
                  <tr className=" border-neutral-900 text-white ">
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800	 border-neutral-900 pl-2 w-20"
                    >
                      Loading
                      <br />
                      Date
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800		 border-neutral-900 w-24"
                    >
                      Truck
                      <br />
                      No.
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800	 border-neutral-900 w-24"
                    >
                      From
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-slate-800	 border-neutral-900 w-20"
                    >
                      To
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-kirtiroadways-900  border-neutral-900 w-20"
                    >
                      Fright
                    </th>
                    <th className="bg-primary-kirtiroadways border-neutral-900 w-20">
                      Advance
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-primary-kirtiroadways border-neutral-900 w-20"
                    >
                      Balance
                    </th>
                    {props?.data?.transporterHamali > 0 &&
                      props?.data?.transporterHamali !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900 w-24"
                        >
                          Hamali
                        </th>
                      )}
                    {props?.data?.transporterDetention > 0 &&
                      props?.data?.transporterDetention !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900 w-24"
                        >
                          Detention
                        </th>
                      )}
                    {props?.data?.transporterExtraCharge > 0 &&
                      props?.data?.transporterExtraCharge !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900 w-24"
                        >
                          Extra Charge
                        </th>
                      )}
                  </tr>
                </thead>
                {/* Tr data */}
                <tbody className="font-bold text-base	align-top text-center w-full">
                  <tr height="20px">
                    <td
                      style={{ maxHeight: "5px" }}
                      height="5px"
                      className="border-r-2 border-gray-300 items-center w-28"
                    >
                      <span className="mt-2">
                        {moment(props?.data?.loadingDate).format("DD-MM-YYYY")}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className=" border-r-2 border-gray-300 w-40"
                    >
                      <span className="mt-2">
                        {props?.data?.vehicleNo.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className="border-r-2 border-gray-300 w-40"
                    >
                      <span className="mt-2">
                        {props?.data?.from.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className="border-r-2 border-gray-300 w-40"
                    >
                      <span className="mt-2">
                        {props?.data?.to.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className="border-r-2 border-gray-300 w-24"
                    >
                      <span className="ml-2 mt-2 text-center">
                        {props?.data?.transporterFreigth}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className="border-r-2 border-gray-300 w-24"
                    >
                      <span className="mt-2 text-center">
                        {props?.data?.transporterAdvance}
                      </span>
                    </td>
                    <td height="20px" className="w-24">
                      <span className="mt-2 text-center">
                        {props?.data?.transporterBalance}
                      </span>
                    </td>
                    {props?.data?.transporterHamali > 0 &&
                      props?.data?.transporterHamali !== null && (
                        <td
                          height="20px"
                          className="border-x-2 border-gray-300 w-24"
                        >
                          <span className="mt-2 text-center">
                            {props?.data?.transporterHamali}
                          </span>
                        </td>
                      )}
                    {props?.data?.transporterDetention > 0 &&
                      props?.data?.transporterDetention !== null && (
                        <td
                          height="20px"
                          className="border-r-2 border-gray-300 w-24"
                        >
                          <span className="mt-2 text-center">
                            {props?.data?.transporterDetention}
                          </span>
                        </td>
                      )}
                    {props?.data?.transporterExtraCharge > 0 &&
                      props?.data?.transporterExtraCharge !== null && (
                        <td height="20px" className="w-24">
                          <span className="mt-2 text-center">
                            {props?.data?.transporterExtraCharge}
                          </span>
                        </td>
                      )}
                  </tr>
                  <tr style={{ height: "250px" }}></tr>
                </tbody>

                {/* Total data */}
                <thead className="mb-2">
                  <tr className=" border-neutral-900 text-white ">
                    <th
                      style={{ minHeight: "15px" }}
                      colSpan="5"
                      className="w-20"
                    ></th>
                    <th
                      style={{ minHeight: "15px" }}
                      className="w-16 text-center border-r-2  py-2 tracking-wide self-center font-bold uppercase bg-primary-kirtiroadways "
                    >
                      Total
                    </th>
                    <th
                      style={{ maxHeight: "5px" }}
                      className="bg-primary-kirtiroadways border-r-2 w-20"
                    >
                      {props?.data?.transporterBalance}
                    </th>
                    {props?.data?.transporterHamali > 0 &&
                      props?.data?.transporterHamali !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-r-2 w-24"
                        >
                          {props?.data?.transporterHamali}
                        </th>
                      )}
                    {props?.data?.transporterDetention > 0 &&
                      props?.data?.transporterDetention !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-r-2 w-24"
                        >
                          {props?.data?.transporterDetention}
                        </th>
                      )}
                    {props?.data?.transporterExtraCharge > 0 &&
                      props?.data?.transporterExtraCharge !== null && (
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways w-24"
                        >
                          {props?.data?.transporterExtraCharge}
                        </th>
                      )}
                  </tr>
                </thead>
                {/* Horizotal Line */}
                <tbody>
                  <tr className="border-t-2  border-primary-kirtiroadways min-w-full">
                    <td
                      className="border-t-2  border-primary-kirtiroadways "
                      colSpan="10"
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex flex-row mt-5  item-start justify-between p-2	px-2 font-sans border-b-2 border-primary-kirtiroadways font-semibold text-left text-sm">
              <div className="item-start  w-full">
                <div className="w-full">
                  <div className="flex flex-row justify-between w-full">
                    <div className="px-5 py-2 text-md tracking-wide  rounded-lg uppercase bg-primary-kirtiroadways text-white">
                      {OURBANKDETAILS}
                    </div>
                    {/* grand Total */}
                    <div className="flex flex-row  justify-end   h-fit ">
                      <span className="text-center  text-md whitespace-nowrap	 px-4 py-2 tracking-wide   rounded-lg uppercase bg-primary-kirtiroadways text-white">
                        Grand Total : &#8377;{" "}
                        {props?.data?.transporterTotal === null
                          ? "0"
                          : props?.data?.transporterTotal}
                      </span>
                    </div>
                  </div>
                  {/* Bannk Detail Start */}

                  <div className="flex flex-col space-x-1 mt-2">
                    {optionsBank.length > 0 && (
                      <div
                        style={{ minWidth: "350px", maxWidth: "350px" }}
                        className="flex flex-col  text-left w-1/2 z-80"
                      >
                        <Select
                          options={optionsBank.map((el) => ({
                            label: el.bankName,
                            value: el.accountNo,
                            branch: el.branch,
                            billAdd: el.billToAddress,
                            ifscCode: el.ifscCode,
                          }))}
                          onChange={(e) => {
                            setBankBranch({
                              ...bankBranch,
                              bank: e.label,
                              branch: e.branch,
                              accNo: e.value,
                              ifsc: e.ifscCode,
                            });
                            // console.log(e);
                            setDropdown(true);
                          }}
                          placeholder="Bank Branch"
                          className="z-80 "
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary25: "#8cd7de",
                              primary: "#179eaa",
                            },
                          })}
                        />
                      </div>
                    )}

                    <div className="flex flex-row space-x-1 mt-2">
                      <div className="space-y-1">
                        <h5>Bank Name</h5>
                        <h5>Branch</h5>
                        <h5>A/c. No</h5>
                        <h5>IFSC Code</h5>
                      </div>
                      {Dropdown === false ? (
                        <div className="space-y-1">
                          <h5>: State Bank of India</h5>
                          <h5>: Prahlad Nagar Ahmedabad</h5>
                          <h5>: 005167983000351</h5>
                          <h5>: SBIT0CKMNSB (FIFT CHARACTER IS ZERO)</h5>
                        </div>
                      ) : (
                        <div className="space-y-1">
                          <h5>: {bankBranch.bank}</h5>
                          <h5>: {bankBranch.branch}</h5>
                          <h5>: {bankBranch.accNo}</h5>
                          <h5>: {bankBranch.ifsc}</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Bannk Detail End */}

            {/* In word */}
            <div className="flex flex-col justify-start items-start ml-2 font-sans">
              <p>Amount Chargeable (in words)</p>
              <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                {/* Four Thousand Four Hundred Fifty */}
                {props?.data?.totalInWords} ONLY
              </p>
            </div>
            {/* terms and condition */}
            <div className="flex  flex-row text-sm	 justify-between mt-2 mx-2 font-sans">
              <div className="text-left flex flex-col space-y-1">
                <p className="border-black border-b-4 w-36 pb-1">
                  Terms & Conditions
                </p>
                <p>{InvoicSubject}</p>
              </div>
              <div className="text-left text-sm">E. & O.E.</div>
              <div className="text-left text-sm ">FOR,</div>
              {/* Extra informations */}
              <div className="text-lg font-bold text-right ">
                {props.profileData.tagLine}
              </div>
            </div>
            {/* gpay*/}
            <div className="text-left ml-2 text-sm	">
              {GPAYCONTENT} ({props.profileData.tagLine})
            </div>
            <div className="flex border-black border-b-2 flex-row justify-between items-start mx-2 mb-2 font-sans text-sm	">
              <div className="text-left ">
                <p>{PAYMENTBY}</p>
              </div>
              {/* <div className="text-center">{KIRTIROADLINES}</div> */}
              <div>
                <p className="font-bold">{AuthorizedSignatory}</p>
              </div>
            </div>

            {/* printing */}
            <h2 className="text-sm mt-0 mb-0 text-center font-bold bg-primary-kirtiroadways rounded-md p-2 text-white font-sans">
              {computerGeneratedInvoice}
            </h2>
          </div>
          <CommissionInvoicePrint
            bankBranch={bankBranch}
            Dropdown={Dropdown}
            data={props?.data}
            profileData={props.profileData}
            secondaryPhoneNo={props.secondaryPhoneNo}
          />
        </div>
      )}
    </>
  );
};

export default CommissionInvoiceView;
