import React, { useState, useEffect } from "react";
import ButtonWithBG from "../UI/ButtonMain";
import EditableTable from "../UI/Table/EditableTable";
import { useLocation } from "react-router-dom";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import APILoader from "../UI/APILoader";
import { ConfirormationModal } from "../UI/ConfirormationModal";

export default function AddVehicleDetailsTable({
  name,
  // address,
  // NoOfVehicle,
  // type,
  // Owner_Name,
}) {
  const location = useLocation();
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [rowdata, setRowData] = useState([
    // {
    //   id: 1,
    //   Name: name,
    //   date: "12/05/2022",
    //   Vehicle_No: "BR3A2425",
    //   Driver_No: "9999999999",
    //   amount: 250,
    //   credit: 50,
    // },
  ]);

  const onAddRowClick = () => {
    setRowData(
      rowdata.concat({
        id: 0,
        // Name: "",
        // date: "",
        vehicleNo: "",
        // Driver_No: "",
        // amount: "",
        // credit: "",
      })
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: (cell) => cell.row.index + 1,
        maxWidth: 10,
        minWidth: 10,
        width: 10,
      },
      // {
      //   Header: "Date",
      //   accessor: "date",
      //   maxWidth: 100,
      //   minWidth: 100,
      //   width: 100,
      // },
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
      },
      // {
      //   Header: "Driver Name",
      //   accessor: "Name",
      // },
      // {
      //   Header: "Driver No.",
      //   accessor: "Driver_No",
      // },
      // {
      //   Header: "Amount",
      //   accessor: "amount",
      //   maxWidth: 100,
      //   minWidth: 100,
      //   width: 100,
      // },
      // {
      //   Header: "Credit",
      //   accessor: "credit",
      //   maxWidth: 100,
      //   minWidth: 100,
      //   width: 100,
      // },
      // {
      //   Header: "Action",
      //   accessor: "Action",
      // Cell: (cell) => (
      //   <div className="flex flex-row space-x-4">
      //     {edittable === false ? (
      //       <button
      //         onClick={() => {
      //           setEditable(true);
      //         }}
      //         className="border-transparent  hover:bg-primary-kirtiroadways-hover hover:text-white"
      //       >
      //         {edit_svg}
      //       </button>
      //     ) : (
      //       <button
      //         onClick={() => {
      //           setEditable(false);
      //         }}
      //         className="border-transparent  hover:bg-primary-kirtiroadways-hover hover:text-white"
      //       >
      //         {save_svg}
      //       </button>
      //     )}
      //     <button
      //       onClick={() => {
      //         // ES6 Syntax use the rvalue if your data is an array.
      //         const dataCopy = [...rowdata];
      //         dataCopy.splice(cell.row.index, 1);
      //         setRowData(dataCopy);
      //       }}
      //       className="border-transparent hover:bg-primary-kirtiroadways-hover
      //       hover:text-white"
      //     >
      //       {delete_svg}
      //     </button>
      //   </div>
      // ),
      // },
    ],
    []
  );

  // get api start
  useEffect(() => {
    getData(location?.state?.data?.ownerId);
  }, [location?.state?.data?.ownerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async (ownerID) => {
    localStorage.setItem("VehicleOwnerId", location?.state?.data?.ownerId);
    setLoader({ ...loader, loading: true });
    await axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${ownerID}`)
      .then((response) => {
        const data = response?.data?.data;
        ConfirormationModal(response.data.message, "success", 2000);
        setRowData(data);
        // SetVehicleData(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setRowData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  // console.log(props,"for table")
  return (
    <section>
      <div className="flex justify-end ">
        <ButtonWithBG handleClick={onAddRowClick} theme="primary" label="Add" />
      </div>
      <div className="mt-4">
      <APILoader loader={loader} />
          <EditableTable
            data={rowdata}
            columns={columns}
            setData={setRowData}
            updateMyData={updateMyData}
            getData={getData}
            skipPageReset={skipPageReset}
          />
      </div>
    </section>
  );
}
