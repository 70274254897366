import React from "react";

const Button = (
	{ svg, route, text, className, loading = false, ...props })=>
{
	const classes =
		className ||
		`
			inline-flex
      		justify-center 
  			ml-2
  			px-4 
  			py-2 
  			border 
			border-gray-300 
			shadow-sm 
			text-sm 
			font-medium 
			rounded-md 
			hover:bg-gray-50 
			focus:outline-none 
			text-primary-kirtiroadways
  		`;

	return (
		// <a href={route}>
		<button className={classes} {...props}>
			<span>{text}</span>
			{loading && (
				<svg
					className="animate-spin h-5 w-5 ml-2 text-primary-kirtiroadways"
					fill="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
			)}
			{svg}
		</button>
		// </a>
	);
};
export default Button;
