import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import axiosInstance from "../../Utils/API";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as yup from "yup";
import {
  ADD_BUILTY,
  GETVIEWBOOKINGBYID,
  GET_COMPANY_TABLE,
} from "../../Utils/ApiGlobal";
import Loader from "../../Components/UI/Loader";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import { cancel_svg } from "../../Utils/SVGListing";
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
function isNumber(str) {
  return !isNaN(str);
}
const MultiBuillty = ({ users }) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [consignorOptions, setConsignorOptions] = useState(false);
  const [buillties, setBuillty] = useState([]);
  const [initialObject, setInitialObject] = useState(buillties);
  const [year] = useState((new Date().getFullYear()-1) + "/" + (new Date().getFullYear().toString().slice(-2)));
  const [bookingData, setBookingData] = useState({});
  const [ForBlankBuillty, setForBlankBuillty] = useState(false);
  const [GetEmptyLRDate, setGetEmptyLRDate] = useState(true);
  const [BlankBuilltyIndex, setBlankBuilltyIndex] = useState()
  const [LRDate, setLRDate] = useState(new Date());
  const [LrDate] = useState(
    moment(bookingData?.loadingDate).toDate()
  );
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // Add buillty
  const campaginPopup = (values) => {
    // e.preventDefault();
    // e.preventDefault();
    // setLoader({ ...loader, loading: true });
    values.forEach((object) => {
      // eslint-disable-line react-hooks/exhaustive-deps
      object.BookingId = location?.state?.bookingId;
      object.Createdby = localStorage.getItem("userId");
      // object.LrDate = moment(bookingData?.loadingDate).toDate();
      if (
        object.ActualWeight.toLowerCase() === "fix" ||
        !object.ActualWeight ||
        !isNumber(object.ActualWeight)
      ) {
        object.WeightType = "NA";
        // object.ChargedWeight = object.ChargedWeight
      } else {
        // object.WeightType = object.WeightType;
        object.ChargedWeight = object.ActualWeight;
      }
    });
   
    const rephaseArray = { Billties: values };
    // console.log(rephaseArray)
    // console.log(LRDate)

    for(let i = 0; i < rephaseArray.Billties.length; i++){
      if( ForBlankBuillty === false && rephaseArray.Billties[i].LrDate !== null){
            // console.log("Filled")
            // console.log(rephaseArray.Billties[i].LrDate)
             setGetEmptyLRDate(true)
             setLoader({ ...loader, loading: true });
             axiosInstance
             .post(ADD_BUILTY, rephaseArray)
             .then((response) => {
               console.log(response)
               ConfirormationModal("Buillty added successfully", "success", 2000);
               history.push({
                 pathname: "/bilty/billty-invoice-view",
                 state: { bookingId: location?.state?.bookingId},
                 openModel: true,
               });
             })
             .catch((error) => {
               ConfirormationModal(error.message, "error", 2000);
               return;
             });
      }else if(ForBlankBuillty === false && rephaseArray.Billties[0].LrDate === null){
            setGetEmptyLRDate(false)
            // console.log("Not Filled")
            alert("Please Enter LR Date")
            return;
      }else if(ForBlankBuillty === true && (rephaseArray.Billties[BlankBuilltyIndex].LrDate === "Invalid date" 
                  || rephaseArray.Billties[BlankBuilltyIndex].LrDate === null)){
            setGetEmptyLRDate(false)
            // console.log("Not Filled")
            alert("Please Enter LR Date of Blank Buillty")
            return;
      }else if(ForBlankBuillty === true && (rephaseArray.Billties[BlankBuilltyIndex].LrDate !== "Invalid date" 
           || rephaseArray.Billties[BlankBuilltyIndex].LrDate !== null)){
            setGetEmptyLRDate(true)
            // console.log("Filled")
            setLoader({ ...loader, loading: true });
            axiosInstance
            .post(ADD_BUILTY, rephaseArray)
            .then((response) => {
              console.log(response)
              ConfirormationModal("Buillty added successfully", "success", 2000);
              history.push({
                pathname: "/bilty/billty-invoice-view",
                state: { bookingId: location?.state?.bookingId},
                openModel: true,
              });
            })
            .catch((error) => {
              ConfirormationModal(error.message, "error", 2000);
              return;
            });
      }
    }
  
  };
  // Error conditions and messages using YUP
  const validationSchema = yup.object().shape({
    builltiesData: yup.array().of(
      yup.object().shape({
        // Packages: yup.string().required("Please enter Packages"),
        // ActualWeight: yup.string().required("Please enter Actual Weight"),
        // ChargedWeight: yup
        //   .string()
        //   .when("ActualWeight", (ActualWeight, schema) => {
        //     if (
        //       (ActualWeight === ActualWeight.toLowerCase()) === "fix" ||
        //       !ActualWeight ||
        //       !isNumber(ActualWeight)
        //     ) {
        //       return schema.min(1);
        //     }
        //     return schema;
        //   }),
        // offerValue: yup
        //   .number()
        //   .required("Please enter offer value")
        //   .min(0, "Value must be minimum 1"),
   
      })
    ),
  });

  // Form Empty array for array field
  const generateEmptyObj = (bookingData) => {
    return {
      FinancialYear: year,
      LrNo: bookingData.lrNo,
      LrDate: LrDate,
      DeliveryAt: bookingData?.from,
      VehicleNo: bookingData?.vehicleNo,
      ConsignerName: bookingData?.consignerName,
      ConsignerAddress: bookingData?.consignerAddress,
      ConsignerGST: bookingData?.consignerGst,
      ConsigneeName: bookingData?.consigneeName,
      ConsigneeAddress: bookingData?.consigneeAddress,
      ConsigneeGst: bookingData?.consigneeGst,
      From: bookingData?.from,
      To: bookingData?.to,
      Packages: "",
      Description: "",
      ActualWeight: "",
      ChargedWeight: "",
      WeightType: "KG",
      //   ChargedWeight: "",
    };
  };

  useEffect(() => {
    const BookingId = location?.state?.bookingId;
    GetBookingData(BookingId);
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        setLoader({ ...loader, loading: false });
        // console.log(response?.data?.data?.company);
        if (response?.data?.data?.company.length > 0) {
          const options = response?.data?.data?.company;
          setConsignorOptions(options);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Window Back btn path set
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.location.pathname = "/pending-booking"
        history.replace("/pending-booking");
      }
    }
  })
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        // console.log(data)
        setBookingData(response?.data?.data?.bookingDetails);
        setTimeout(() => {
          setInitialObject({
            builltiesData: [
              generateEmptyObj(response?.data?.data?.bookingDetails),
            ],
          });
          setIsLoading(false);
        }, 2000);
        return;
      })
      .catch((error) => {
        return;
      });
  };

  return (
    <DashBoardLayout pageTitle="Add Buillty">
      <APILoader loader={loader} />
      {isLoading ? (
        <Loader />
      ) : 
      (
        <>
          <div className="px-6 pb-6 space-y-2 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md">
            {/* Form  */}
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initialObject}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={(values) => {
                for (const item of values.builltiesData) {
                  if (item.LrDate) {
                    item.LrDate = moment(LRDate).toDate();
                  }
                } 
                setBuillty(values.builltiesData);
                // console.log(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                dirty,
                resetForm,
                isSubmitting,
              }) => (
                <Form onSubmit={campaginPopup}>
                  <FieldArray name="builltiesData">
                    <>
                      {values.builltiesData?.map((coupon, index) => {
                        const builltyValue = values.builltiesData[index] || {};

                        return (
                          <div key={index} className="card ">
                            <div className="flex mt-3 flex-row justify-between">
                              <p className=" underline mb-2 font-semibold">
                                Buillty {index + 1}
                              </p>
                              <div className="flex flex-row align-end space-x-3">
                                {values.builltiesData.length - 1 ===
                                  0 ? null : (
                                  <ButtonWithBG
                                    theme="danger"
                                    label="Remove Buillty"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      let items = [...values.builltiesData];
                                      items.splice(index, 1);
                                      setInitialObject({
                                        builltiesData: items,
                                      });
                                      setBuillty(items);
                                      // console.log(items)
                                    }}
                                  />
                                )}
                                <ButtonWithBG
                                  theme="secondary"
                                  label="Convert into Blank Buillty"
                                  type="reset"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setForBlankBuillty(true);
                                    setBlankBuilltyIndex(index)
                                    setFieldValue(
                                      `builltiesData.${index}.LrDate`,
                                       moment(LRDate).format("DD-MM-YYYY")
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.DeliveryAt`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsignerName`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsignerAddress`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsignerGST`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsigneeName`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsigneeAddress`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ConsigneeGst`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.From`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.To`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.Packages`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.Description`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ActualWeight`,
                                      ""
                                    );
                                    setFieldValue(
                                      `builltiesData.${index}.ChargedWeight`,
                                      ""
                                    );          
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4 mt-3">
                              <div className=" w-full">
                                <div className=" text-left space-y-6">
                                  {/* Fin year,Lr no,lr date */}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label
                                        label="Fin. Year"
                                        isRequired="true"
                                      />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.FinancialYear`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.FinancialYear}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.FinancialYear`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="LR No." isRequired="true" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.LrNo`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.LrNo}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.LrNo`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label
                                        label="LR Date"
                                        isRequired="true"
                                      />
                                     {/* <Field
                                        key={index}
                                        name={`builltiesData.${index}.LrDate`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.LrDate}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />  */}
                                     <div key={index}>
                                      <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                        selected={LRDate}
                                        onChange={(date) =>{
                                          setLRDate(date);
                                          setFieldValue(
                                            `builltiesData.${index}.LrDate`,
                                            date)
                                        }}
                                        name={`builltiesData.${index}.LrDate`}
                                        value={builltyValue.LrDate}
                                        
                                      />
                                      </div>
                                      {/* <ErrorMessage
                                        name={`builltiesData.${index}.LrDate`}
                                        component="div"
                                        className="invalid-feedback"
                                      /> */}
                                      {!GetEmptyLRDate && <p className="text-red-500">Please Enter LR Date</p>}
                                    </div>
                                  </div>
                                  {/* Delievery at,Vehicle_No, consignor name */}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label
                                        label="Delivery At"
                                      />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.DeliveryAt`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.DeliveryAt}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.DeliveryAt`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label
                                        label="Vehicle No."
                                        isRequired="true"
                                      />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.VehicleNo`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.VehicleNo}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.VehicleNo`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignor Name" />
                                      {/* <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsignerName`}
                                        type="text"
                                        as="select"
                                        autoComplete="off"
                                        value={builltyValue.ConsignerName}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      /> */}
                                      <div className="flex flex-row">
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsignerName`}
                                          type="text"
                                          as="select"
                                          onChange={(e) => {
                                            const selectOption = e.target;
                                            var selectedIndex =
                                              selectOption.options
                                                .selectedIndex;
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerName`,
                                              e.target.value
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerAddress`,
                                              selectOption.options[
                                                selectedIndex
                                              ].getAttribute("shiptoadd")
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerGST`,
                                              selectOption.options[
                                                selectedIndex
                                              ].getAttribute("gst")
                                            );
                                          }}
                                          autoComplete="off"
                                          value={builltyValue.ConsignerName}
                                          className="border-l rounded-l-lg
                                                form-control
                                                block
                                                w-full
                                                px-3
                                                py-2
                                                text-base
                                                font-normal
                                                bg-white bg-clip-padding
                                                transition
                                                ease-in-out
                                                m-0
                                                border-t  border-b border-r-0
                                                border border-gray-300 placeholder-gray-500  "
                                        >
                                          <option value="">
                                            {" "}
                                            -- select an option --{" "}
                                          </option>
                                          {consignorOptions.map((option) => {
                                            return (
                                              <option
                                                className="bg-white"
                                                value={option.name}
                                                id={option.name}
                                                billtoadd={option.billToAddress}
                                                shiptoadd={option.shipToAddress}
                                                gst={option.gst}
                                                key={option.companyId}
                                                label={option.name}
                                              >
                                                {option.name}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <button
                                          className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerName`,
                                              ""
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerAddress`,
                                              ""
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsignerGST`,
                                              ""
                                            );
                                          }}
                                        >
                                          {cancel_svg}
                                        </button>
                                      </div>
                                      {/* <ErrorMessage
                                        name={`builltiesData.${index}.ConsignerName`}
                                        component="div"
                                        className="invalid-feedback"
                                      /> */}
                                    </div>
                                  </div>
                                  {/* Consignor Address,Gst,consignee name */}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignor's Address" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsignerAddress`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsignerAddress}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ConsignerAddress`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignor's GST" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsignerGST`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsignerGST}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ConsignerGST`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignee's Name" />
                                      {/* <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsigneeName`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsigneeName}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      /> */}
                                      <div className="flex flex-row ">
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsigneeName`}
                                          type="text"
                                          as="select"
                                          onChange={(e) => {
                                            const selectOption = e.target;
                                            var selectedIndex =
                                              selectOption.options
                                                .selectedIndex;
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeName`,
                                              e.target.value
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeAddress`,
                                              selectOption.options[
                                                selectedIndex
                                              ].getAttribute("shiptoadd")
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeGst`,
                                              selectOption.options[
                                                selectedIndex
                                              ].getAttribute("gst")
                                            );
                                          }}
                                          autoComplete="off"
                                          value={builltyValue.ConsigneeName}
                                          className="border-l rounded-l-lg
                                          form-control
                                          block
                                          w-full
                                          px-3
                                          py-2
                                          text-base
                                          font-normal
                                          bg-white bg-clip-padding
                                          transition
                                          ease-in-out
                                          m-0
                                          border-t  border-b border-r-0
                                          border border-gray-300 placeholder-gray-500  "
                                        >
                                          <option value="">
                                            {" "}
                                            -- select an option --{" "}
                                          </option>

                                          {consignorOptions.map((option) => {
                                            return (
                                              <option
                                                className="bg-white"
                                                value={option.name}
                                                id={option.name}
                                                billtoadd={option.billToAddress}
                                                shiptoadd={option.shipToAddress}
                                                gst={option.gst}
                                                key={option.companyId}
                                                label={option.name}
                                              >
                                                {option.name}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <button
                                          className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeName`,
                                              ""
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeAddress`,
                                              ""
                                            );
                                            setFieldValue(
                                              `builltiesData.${index}.ConsigneeGst`,
                                              ""
                                            );
                                          }}
                                        >
                                          {cancel_svg}
                                        </button>
                                      </div>
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ConsigneeName`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                  {/* Consignee_add,consignee gst from */}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignee's Address" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsigneeAddress`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsigneeAddress}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ConsigneeAddress`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Consignee's GST" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsigneeGst`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsigneeGst}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ConsigneeGst`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="From" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.From`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.From}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.From`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                  {/* To, Pakages ,description*/}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="To" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.To`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.To}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Packages" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.Packages`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.Packages}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.Packages`}
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Description" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.Description`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.Description}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                  {/*Actual weight, weight unit and charged weight*/}
                                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Actual/Charged Weight" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ActualWeight`}
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ActualWeight}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ActualWeight`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      <Label label="Weight Unit" />
                                      {builltyValue.ActualWeight.toLowerCase() ===
                                        "fix" ||
                                        !builltyValue.ActualWeight ||
                                        !isNumber(builltyValue.ActualWeight) ? (
                                        <p
                                          className="
                                      block
                                      w-full
                                      px-3
                                      py-1.5
                                      text-base
                                      font-normal
                                      text-gray-700
                                      bg-gray-100 bg-clip-padding
                                      border border-solid border-gray-300
                                      rounded
                                      transition
                                      ease-in-out
                                      m-0
                                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                          NA
                                        </p>
                                      ) : (
                                        <>
                                          <Field
                                            key={index}
                                            name={`builltiesData.${index}.WeightType`}
                                            type="text"
                                            as="select"
                                            autoComplete="off"
                                            value={builltyValue.WeightType}
                                            className="peer  w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                          >
                                            <option
                                              className="bg-white"
                                              value="KG"
                                            >
                                              KG
                                            </option>
                                            <option
                                              className="bg-white "
                                              value="Liter"
                                            >
                                              LITER
                                            </option>
                                            <option
                                              className="bg-white "
                                              value="QUINTAL"
                                            >
                                              QUINTAL
                                            </option>
                                            <option
                                              className="bg-white"
                                              value="UNIT"
                                            >
                                              UNIT
                                            </option>
                                            <option
                                              className="bg-white"
                                              value="TON"
                                            >
                                              TON
                                            </option>
                                          </Field>
                                          <ErrorMessage
                                            name={`builltiesData.${index}.WeightType`}
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      {" "}
                                      <Label label="Charged Weight" />
                                      <Field
                                        key={index}
                                        name={`builltiesData.${index}.ChargedWeight`}
                                        // name={
                                        //   builltyValue.ActualWeight.toLowerCase() ===
                                        //     "fix" ||
                                        //   !builltyValue.ActualWeight ||
                                        //   !isNumber(builltyValue.ActualWeight)
                                        //     ? `builltiesData.${index}.ChargedWeight`
                                        //     : `builltiesData.${index}.ActualWeight`
                                        // }
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={
                                          builltyValue.ActualWeight.toLowerCase() ===
                                            "fix" ||
                                            !builltyValue.ActualWeight ||
                                            !isNumber(builltyValue.ActualWeight)
                                            ? builltyValue.ChargedWeight
                                            : builltyValue.ActualWeight
                                        }
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      />
                                      <ErrorMessage
                                        name={`builltiesData.${index}.ActualWeight`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </FieldArray>

                  {/* Action Buttons */}
                  <div className="items-center w-full">
                    <div className=" mt-4 space-x-2">
                      <ButtonWithBG
                        theme="secondary"
                        label="Add New Buillty"
                        type="submit"
                        // disabled={!validationSchema.isValidSync(values)}
                        onClick={(e) => {
                          e.preventDefault();
                          setInitialObject({
                            builltiesData: [
                              ...values.builltiesData,
                              generateEmptyObj(bookingData),
                            ],
                          });
                          let couponItems = values.builltiesData;
                          setBuillty(couponItems);
                          // console.log(bookingData, "test");
                          // console.log(values.builltiesData)
                        }}
                      />
                      <ButtonWithBG
                        theme="primary"
                        label="SAVE"
                        // disabled={!validationSchema.isValidSync(values)}
                        onClick={() => {
                          let builltyData = values.builltiesData;
                          campaginPopup(builltyData);
                        }}
                      />
                    </div>
                  </div>

                  {/* {active && !validationSchema.isValidSync(values) && (
                    <div
                      className={
                        "z-100 fixed inset-0 flex items-end items-start justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end top-0"
                      }
                    >
                      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="p-4">
                          <div className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                            <div className="ml-3">
                              <p>
                                Please fill required fields to enable buttons.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </Form>
              )}
            </Formik>
            {/* Create Button */}
          </div>
        </>
        )} 
    </DashBoardLayout>
  );
};

export default MultiBuillty;
