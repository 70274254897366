import React from "react";
import logo from "../../Images/logo_green_transparent.png";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  InvoiceBilltyNote,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  InvoiceDeclartion,
  InvoicSubject,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  // ConsignorName,
  // Name,
  Address,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import Loader from "./Loader";
// import InvoicePrint from "../../Components/UI/InvoicePrint";
import moment from "moment";

const InvoicePrint = (props) => {
  // console.log(props);

  return (
    <>
      {props.data.createdAt ? (
        <>
          {props.i === 0 && props.copyFor === "CONSIGNOR" ? (
            <div
              style={{ minHeight: "100%" }}
              className="border-double border-4 border-cyan-200"
            >
              <div className="">
                <div className="flex  flex-row justify-between font-bold text-xs mx-1">
                  <h5>{InvoicSubject}</h5>
                  <h5>{InvoiceDeclartion}</h5>
                </div>
                {/* Logo and Title Start */}
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-40 ml-2">
                        <img src={logo} alt="Transporter" />
                      </div>
                      <div className=" flex flex-col ">
                        <span
                          style={{ fontSize: "70px", lineHeight: "1" }}
                          className="text-primary-kirtiroadways font-extrabold "
                        >
                          {props.profileData.tagLine}
                        </span>
                        <span className="text-xl font-serif text-black">
                          {props.profileData.subTagLine}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div className="rounded-b-3xl rounded-t-lg h-14 font-bold px-1 py-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
                      {props.profileData.address !== " " && (
                        <p className="flex justify-center flex-row items-start">
                          <span className="text-xs">{address_svg}</span>
                          <span className="text-xs">
                            {props.profileData.address}
                          </span>
                        </p>
                      )}
                      <div className="flex space-x-4 justify-center md:flex-row items-start ">
                        {(props.secondaryPhoneNo.length !== 0) && (
                          <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                            <span>{call_svg} </span>{" "}
                            <span className="flex flex-row">
                              {props.secondaryPhoneNo.map((number, i) => (
                                <span
                                  key={number}
                                  className="text-sm font-semibold tracking-wider flex flex-row"
                                >
                                  {(i ? ", " : "") + number}
                                </span>
                              ))}
                            </span>
                          </div>
                        )}
                        {props.profileData.email !== " " && (
                          <div className="flex space-x-1 justify-center flex-row items-center ">
                            <span>{email_svg} </span>
                            <span>{props.profileData.email}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Address End */}
                  </div>
                </div>
                {/* Logo and Title End */}
              </div>

              <div className="flex flex-row justify-start items-center px-1">
                <div
                  className="
                shadow-sm
                border 
                flex
                flex-row rounded-x-md w-full divide-x border-neutral-400 divide-neutral-400"
                >
                  <div className="w-96 px-2 pb-1">
                    <h1 className="font-bold underline text-sm text-center">
                      {CAUTION}
                    </h1>
                    <ul className="text-justify font-serif text-xs list-outside mt-1">
                      <li className="">{CAUTIONContnet}</li>
                      <li>{receiptDeclaration}</li>
                    </ul>
                  </div>

                  <div className="flex w-full flex-col divide-y border-neutral-400 divide-neutral-400 ">
                    <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                      <div className="w-full self-center items-center font-bold text-sm">
                        <h1 className="underline text-center font-bold">
                          {PANNo} {props?.data?.pan}
                        </h1>
                        <div className="self-center text-center font-bold">
                          {props.copyFor} COPY
                        </div>
                      </div>
                      <div className="w-96 items-center py-2">
                        <h1 className="font-bold text-sm underline text-center">
                          {OwnerRiskInsurance}
                        </h1>
                        <h4 className="text-xs text-center px-1">
                          {CustomerDeclaration}
                        </h4>
                      </div>
                    </div>

                    <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                      <div className="w-full self-center font-bold">
                        <h1 className="underline font-bold text-sm text-center text-primary-kirtiroadways">
                          {CONSIGNMENTNOTE}
                        </h1>
                      </div>
                      <div className="w-96 text-left text-sm">
                        <ul className="divide-y divide-neutral-400 text-sm">
                          <li className="px-2 py-3 text-xs">
                            {CompanyName} :
                            {/* {""} {props.profileData.companyName} */}
                          </li>
                          <li className="px-2 py-3 text-xs">
                            Policy No : {""}
                            {/* {props.profileData.policyNo} */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="h-4/5 flex flex-row divide-x  border-neutral-400 divide-neutral-400 text-xs">
                      <div className="w-full">
                        <table className="border-collapse w-full text-xs mb-0 pb-0">
                          <thead>
                            <tr>
                              <td className="w-1/2 border-neutral-400 pl-1 py-3">
                                <span className="float-left">Fin. Year :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.financialYear}
                                </span>
                              </td>
                              <td className=" w-1/2 border-l pl-1 border-neutral-400 py-3">
                                <span className="float-left">LR No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.lrNo}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="w-1/2 border-t border-neutral-400 pl-1 py-3">
                                <span className="float-left">LR Date :</span>
                                <span className="font-bold pl-1">
                                  {/* {props?.data?.lrDate
                                    ? props?.data?.lrDate.split("T")[0]
                                    : props?.data?.loadingDate.split("T")[0]} */}
                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : " "}
                                </span>
                              </td>
                              <td className=" w-1/2 border-t border-l pl-1 border-neutral-400 py-3">
                                <span className="float-left">Lorry No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.vehicleNo}
                                </span>
                              </td>
                            </tr>
                            <tr className="border-neutral-400 border-t">
                              <td colSpan={2} className="py-3 pl-1 text-xs ">
                                <span className="float-left ">
                                  Delivery At. :
                                </span>
                                <span className="font-bold pl-1">
                                  {""}
                                  {props?.data?.to}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="w-96">
                        <div className="w-full ">
                          <table className="border-collapse w-full text-xs mb-0 pb-0">
                            <thead>
                              <tr>
                                <td
                                  className="border-y border-t-0 border-l-0 border-neutral-400 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Date :{" "}
                                    {/* {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]} */}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-left">
                                <td
                                  colSpan="2"
                                  className="w-1/2 border-t-0 border-l-0 border-neutral-400 px-2 py-2"
                                >
                                  <span className="float-left">
                                    Amount :{" "}
                                    {/* {props?.data?.amountToPayOrPaid === null
                                      ? ""
                                      : props?.data?.amountToPayOrPaid} */}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Consignore and consignee */}
              <div
                className="mx-1 mt-2 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                       flex flex-col"
              >
                <table className="border-collapse text-base">
                  <tbody>
                    <tr>
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1 font-bold text-sm">
                        <span className="float-left">
                          <span className="font-bold text-primary-kirtiroadways">
                            Consignor's Name :
                          </span>{" "}
                          {props?.data?.consignerName}
                        </span>
                      </td>
                      <td className="w-1/2 border-b  border-neutral-400 p-1 font-bold">
                        <span className="float-left">
                          <span className="font-bold text-primary-kirtiroadways">
                            Consignee's Name :
                          </span>{" "}
                          {props?.data?.consigneeName}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-sm h-16">
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consignerAddress}
                        </span>
                      </td>
                      <td className="w-1/2 border-b border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consigneeAddress}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-sm">
                      <td className="w-1/2  border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consignerGst === null
                            ? "NA"
                            : props?.data?.consignerGst.toUpperCase()}
                        </span>
                      </td>
                      <td className="w-1/2 p-1">
                        <span className="float-left">
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consigneeGst === null
                            ? "NA"
                            : props?.data?.consigneeGst.toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* from and to*/}
              <div
                className="mx-1 my-2 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                                flex flex-col "
              >
                <table className="border-collapse text-base">
                  <tbody>
                    <tr>
                      <td className="w-1/2 font-bold border-r border-neutral-400 p-2">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            From:-
                          </span>{" "}
                          {props?.data?.from}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold p-1">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            To:-
                          </span>{" "}
                          {props?.data?.to}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table */}
              <div className="mx-1">
                <table className="overflow-x-scroll w-full">
                  <thead className="text-sm">
                    <tr className="border">
                      <th className="border-2 border-neutral-300">Packages</th>
                      <th className="border-2  border-neutral-300">
                        Description (said to contains)
                      </th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Weight
                        </h1>
                        <div className="w-full flex flex-row justify-evenly text-center">
                          <h1>Actual</h1>
                          <h1>Charged</h1>
                        </div>
                      </th>
                      <th className="border-2 border-neutral-300">Rate</th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Amount to Pay/Paid
                        </h1>
                        <h1>Rs.</h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-top font-light text-center text-xs">
                    <tr className="border-2 font-light border-neutral-300">
                      <td
                        rowSpan="2"
                        className="border-2 font-semibold border-neutral-300 items-center"
                      >
                        {props?.data?.packages !== null && (
                          <>
                            <h1 className="">{props?.data?.packages}</h1>
                            {/* <h1 className="">NOS</h1> */}
                          </>
                        )}
                      </td>
                      <td className="font-semibold flex flex-col space-y-20 align-middle">
                        <h1 className="font-semibold">
                          {props?.data?.description}
                        </h1>
                        <h1 className="font-semibold">AS PER INVOICE</h1>
                      </td>
                      <td className="border-2 border-neutral-300">
                        <div className="flex flex-col space-y-8 ">
                          <div className="flex flex-row justify-evenly">
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.actualWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.chargedWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                          </div>
                          <div className="font-semibold space-y-6">
                            <h1 className="font-semibold border-y-2 border-neutral-300">
                              Company Invoice No.
                            </h1>
                            <div className="font-semibold mt-4">
                              {props?.data?.invoiceNo.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-col space-y-4">
                        <h1 className="font-semibold">Freight</h1>
                        <h1 className="font-semibold">Sur. Ch.</h1>
                        <h1 className="font-semibold">St. Ch.</h1>
                        <h1 className="font-semibold">Risk Ch.</h1>
                      </td>
                      <td className="border-l-2 border-neutral-300 align-center">
                        <div className=" mt-10 font-semibold text-black">
                          {props?.information.toUpperCase() ||
                            props?.data?.amountToPayOrPaid}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="flex flex-row justify-start border-b-2 border-neutral-300">
                        <h1 className="font-semibold ml-2">Private Marks:</h1>
                      </td>
                      <td className="border-x-2 border-neutral-300 border-b-2">
                        <h1 className="font-semibold">
                          {props?.data?.privateMarks}
                        </h1>
                      </td>
                      <td className="border-r-2 border-b-2 border-neutral-300 text-center justify-center text-md  font-bold bg-primary-kirtiroadways">
                        <h1>TOTAL</h1>
                      </td>
                      <td className="border-r-2 border-b-2 border-neutral-300 text-center justify-center text-md  font-bold bg-primary-kirtiroadways">
                        <h1>&#8377;{props?.data?.total}</h1>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="font-bold text-xs border border-b-0 border-neutral-400 w-full flex flex-row justify-center mt-2">
                  <p>To Pay : {props?.data?.paidBy}</p>
                </div>
                <div className="font-bold text-xs border border-neutral-400 text-center">
                  <h1>{InvoiceBilltyNote}</h1>
                  <h1>{CompanyResponsible}</h1>
                </div>
              </div>
              {/* Table End */}

              <div className="mx-4 mt-2">
                {/* bottom left and right start */}
                <div className="flex flex-row space-y-4 justify-between text-left">
                  <div className="max-w-md">
                    <h1 className="font-bold underline border-neutral-900  text-sm">
                      {TermsandCondition}
                    </h1>
                    <h6 className="	text-xs text-justify">
                      {TermsandConditionNote}
                    </h6>
                  </div>
                  <div className="font-semibold text-sm text-right">
                    <h3>Value: AS PER INVOICE</h3>
                    <h3>{ServiceTaxLiabilityConsignor}</h3>
                    <h3 className="mt-4 pt-4">
                      For {props.profileData.tagLine}
                    </h3>
                  </div>
                </div>
                {/* bottom left and right end */}

                {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
              </div>

              <div className="border-t-0 border-neutral-900 font-serif	bg-primary-kirtiroadways py-1 text-white text-center  w-full mt-1 font-bold rounded-sm">
                <h6>{THANKYOUFORBUSSINESSWITHUS}</h6>
              </div>
            </div>
          ) : (
            <div
              style={{ minHeight: "100%" }}
              className="pagebreak border-double border-4 border-cyan-200"
            >
              <div className="">
                <div className="flex flex-row justify-between font-bold text-xs mx-1">
                  <h5>{InvoicSubject}</h5>
                  <h5>{InvoiceDeclartion}</h5>
                </div>
                {/* Logo and Title Start */}
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-40 ml-2">
                        <img src={logo} alt="Transporter" />
                      </div>
                      <div className=" flex flex-col ">
                        <span
                          style={{ fontSize: "70px", lineHeight: "1" }}
                          className="text-primary-kirtiroadways font-extrabold "
                        >
                          {props.profileData.tagLine}
                        </span>
                        <span className="text-xl font-serif text-black">
                          {props.profileData.subTagLine}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div className="rounded-b-3xl rounded-t-lg h-14 font-bold px-1 py-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-xs">
                      {props.profileData.address !== " " && (
                        <p className="flex justify-center flex-row items-start">
                          <span className="text-xs">{address_svg}</span>
                          <span className="text-xs">
                            {props.profileData.address}
                          </span>
                        </p>
                      )}
                      <div className="flex space-x-4 justify-center md:flex-row items-start ">
                        {props.secondaryPhoneNo.length > 1 && (
                          <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                            <span>{call_svg} </span>{" "}
                            <span className="flex flex-row">
                              {props.secondaryPhoneNo.map((number, i) => (
                                <span
                                  key={number}
                                  className="text-sm font-semibold tracking-wider flex flex-row"
                                >
                                  {(i ? ", " : "") + number}
                                </span>
                              ))}
                            </span>
                          </div>
                        )}
                        {props.profileData.email !== " " && (
                          <div className="flex space-x-1 justify-center flex-row items-center ">
                            <span>{email_svg} </span>
                            <span>{props.profileData.email}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Address End */}
                  </div>
                </div>
                {/* Logo and Title End */}
              </div>

              <div className="flex flex-row justify-start items-center px-1">
                <div
                  className="
                shadow-sm
                border 
                flex
                flex-row rounded-x-md w-full divide-x border-neutral-400 divide-neutral-400"
                >
                  <div className="w-96 px-2 pb-1">
                    <h1 className="font-bold underline text-sm text-center">
                      {CAUTION}
                    </h1>
                    <ul className="text-justify font-serif text-xs list-outside mt-1">
                      <li className="">{CAUTIONContnet}</li>
                      <li>{receiptDeclaration}</li>
                    </ul>
                  </div>

                  <div className="flex w-full flex-col divide-y border-neutral-400 divide-neutral-400 ">
                    <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                      <div className="w-full self-center items-center font-bold text-sm">
                        <h1 className="underline text-center font-bold">
                          {PANNo} {props?.data?.pan}
                        </h1>
                        <div className="self-center text-center font-bold">
                          {props.copyFor} COPY
                        </div>
                      </div>
                      <div className="w-96 items-center py-2">
                        <h1 className="font-bold text-sm underline text-center">
                          {OwnerRiskInsurance}
                        </h1>
                        <h4 className="text-xs text-center px-1">
                          {CustomerDeclaration}
                        </h4>
                      </div>
                    </div>

                    <div className="h-2/5 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                      <div className="w-full self-center font-bold">
                        <h1 className="underline font-bold text-sm text-center text-primary-kirtiroadways">
                          {CONSIGNMENTNOTE}
                        </h1>
                      </div>
                      <div className="w-96 text-left text-sm">
                        <ul className="divide-y divide-neutral-400 text-sm">
                          <li className="px-2 py-3 text-xs">
                            {CompanyName} :
                            {/* {""} {props.profileData.companyName} */}
                          </li>
                          <li className="px-2 py-3 text-xs">
                            Policy No : {""}
                            {/* {props.profileData.policyNo} */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="h-4/5 flex flex-row divide-x  border-neutral-400 divide-neutral-400 text-xs">
                      <div className="w-full">
                        <table className="border-collapse w-full text-xs mb-0 pb-0">
                          <thead>
                            <tr>
                              <td className="w-1/2 border-neutral-400 pl-1 py-3">
                                <span className="float-left">Fin. Year :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.financialYear}
                                </span>
                              </td>
                              <td className=" w-1/2 border-l pl-1 border-neutral-400 py-3">
                                <span className="float-left">LR No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.lrNo}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="w-1/2 border-t border-neutral-400 pl-1 py-3">
                                <span className="float-left">LR Date :</span>
                                <span className="font-bold pl-1">
                                  {/* {props?.data?.lrDate
                                    ? props?.data?.lrDate.split("T")[0]
                                    : props?.data?.loadingDate.split("T")[0]} */}
                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : " "}
                                </span>
                              </td>
                              <td className=" w-1/2 border-t border-l pl-1 border-neutral-400 py-3">
                                <span className="float-left">Lorry No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.vehicleNo}
                                </span>
                              </td>
                            </tr>
                            <tr
                              className="border-neutral-400 border-t text-left"
                              //  style={{textAlign:"left"}}
                            >
                              <td colSpan={2} className="py-3 pl-1 text-xs">
                                <span className="float-left">
                                  Delivery At. :
                                </span>
                                <span className="font-bold pl-1">
                                  {props?.data?.to}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="w-96">
                        <div className="w-full ">
                          <table className="border-collapse w-full text-xs mb-0 pb-0">
                            <thead>
                              <tr>
                                <td
                                  className="border-y border-t-0 border-neutral-400 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Date :{" "}
                                    {/* {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]} */}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  colSpan="2"
                                  className="w-1/2 border-neutral-400 px-2 py-3"
                                >
                                  <span className="float-left">
                                    Amount :{" "}
                                    {/* {props?.data?.amountToPayOrPaid === null
                                      ? ""
                                      : props?.data?.amountToPayOrPaid} */}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Consignore and consignee */}
              <div
                className="mx-1 my-2 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                       flex flex-col"
              >
                <table className="border-collapse text-base">
                  <tbody>
                    <tr>
                      <td className="w-1/2  border-b border-r border-neutral-400 p-1 font-bold text-sm">
                        <span className="float-left">
                          <span className="font-bold text-primary-kirtiroadways ">
                            Consignor's Name :
                          </span>{" "}
                          {props?.data?.consignerName}
                        </span>
                      </td>
                      <td className="w-1/2 border-b  border-neutral-400 p-1 font-bold text-sm">
                        <span className="float-left">
                          <span className="font-bold text-primary-kirtiroadways">
                            Consignee's Name :
                          </span>{" "}
                          {props?.data?.consigneeName}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-sm h-16">
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consignerAddress}
                        </span>
                      </td>
                      <td className="w-1/2 border-b border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consigneeAddress}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-sm">
                      <td className="w-1/2  border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consignerGst === null
                            ? "NA"
                            : props?.data?.consignerGst.toUpperCase()}
                        </span>
                      </td>
                      <td className="w-1/2 p-1">
                        <span className="float-left">
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consigneeGst === null
                            ? "NA"
                            : props?.data?.consigneeGst.toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* from and to*/}
              <div
                className="mx-1 mb-2 border-gray-600 shadow-sm border rounded-l-md text-sm rounded-r-md
                                flex flex-col "
              >
                <table className="border-collapse text-base">
                  <tbody>
                    <tr>
                      <td className="w-1/2 font-bold border-r border-neutral-400 p-2">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            From:-
                          </span>{" "}
                          {props?.data?.from}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold p-1">
                        <span className="float-left">
                          <span className="text-primary-kirtiroadways">
                            To:-
                          </span>{" "}
                          {props?.data?.to}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table */}
              <div className=" mx-1 mt-2">
                <table className="overflow-x-scroll w-full">
                  <thead className="text-sm">
                    <tr className="border">
                      <th className="border-2 border-neutral-300">Packages</th>
                      <th className="border-2 border-neutral-300">
                        Description (said to contains)
                      </th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Weight
                        </h1>
                        <div className="w-full flex flex-row justify-evenly text-center">
                          <h1>Actual</h1>
                          <h1>Charged</h1>
                        </div>
                      </th>
                      <th className="border-2 border-neutral-300">Rate</th>
                      <th className="border-2 border-neutral-300">
                        <h1 className="border-b-2 border-neutral-300">
                          Amount to Pay/Paid
                        </h1>
                        <h1>Rs.</h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-top font-light text-center text-xs">
                    <tr className="border-2 font-light border-neutral-300">
                      <td
                        rowSpan="2"
                        className="border-2 font-semibold border-neutral-300 items-center"
                      >
                        {props?.data?.packages !== null && (
                          <>
                            <h1 className="">{props?.data?.packages}</h1>
                            {/* <h1 className="">NOS</h1> */}
                          </>
                        )}
                      </td>
                      <td className="font-semibold flex flex-col space-y-20 align-middle">
                        <h1 className="font-semibold">
                          {props?.data?.description}
                        </h1>
                        <h1 className="font-semibold">AS PER INVOICE</h1>
                      </td>
                      <td className="border-2 border-neutral-300">
                        <div className="flex flex-col space-y-8">
                          <div className="flex flex-row justify-evenly">
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.actualWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.chargedWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                          </div>
                          <div className="font-semibold space-y-8">
                            <h1 className="font-semibold border-y-2 border-neutral-300">
                              Company Invoice No.
                            </h1>
                            <div className="font-semibold mt-4">
                              {props?.data?.invoiceNo.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-col space-y-4">
                        <h1 className="font-semibold">Freight</h1>
                        <h1 className="font-semibold">Sur. Ch.</h1>
                        <h1 className="font-semibold">St. Ch.</h1>
                        <h1 className="font-semibold">Risk Ch.</h1>
                      </td>
                      <td className="font-semibold  align-center border-l-2 border-neutral-300 text-black">
                        <div className=" mt-10 font-semibold">
                          {props?.information.toUpperCase() ||
                            props?.data?.amountToPayOrPaid}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="flex flex-row justify-start border-b-2 border-neutral-300">
                        <h1 className="font-semibold ml-2">Private Marks:</h1>
                      </td>
                      <td className="border-x-2 border-b-2 border-neutral-300">
                        <h1 className="font-semibold">
                          {props?.data?.privateMarks}
                        </h1>
                      </td>
                      <td className="border-r-2 border-b-2 border-neutral-300 text-center justify-center text-md  font-bold bg-primary-kirtiroadways">
                        <h1>TOTAL</h1>
                      </td>
                      <td className="border-r-2 border-b-2 border-neutral-300 text-center justify-center text-md  font-bold bg-primary-kirtiroadways">
                        <h1>&#8377;{props?.data?.total}</h1>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="font-bold text-xs border border-b-0 border-neutral-400  w-full flex flex-row justify-center mt-2">
                  <p>To Pay : {props?.data?.paidBy}</p>
                </div>
                <div className="font-bold text-xs border border-neutral-400 text-center">
                  <h1>{InvoiceBilltyNote}</h1>
                  <h1>{CompanyResponsible}</h1>
                </div>
              </div>
              {/* Table End */}

              <div className="mx-4 mt-2">
                {/* bottom left and right start */}
                <div className="flex flex-row space-y-4 justify-between text-left">
                  <div className="max-w-md">
                    <h1 className="font-bold underline border-neutral-900  text-sm">
                      {TermsandCondition}
                    </h1>
                    <h6 className="	text-xs text-justify">
                      {TermsandConditionNote}
                    </h6>
                  </div>
                  <div className="font-semibold text-sm text-right">
                    <h3>Value: AS PER INVOICE</h3>
                    <h3>{ServiceTaxLiabilityConsignor}</h3>
                    <h3 className="mt-4 pt-4">
                      For {props.profileData.tagLine}
                    </h3>
                  </div>
                </div>
                {/* bottom left and right end */}

                {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
              </div>

              <div className="border-t-0 border-neutral-900 font-serif	bg-primary-kirtiroadways py-1 text-white text-center  w-full mt-1 font-bold rounded-sm">
                <h6>{THANKYOUFORBUSSINESSWITHUS}</h6>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoicePrint;
