import React, { useState, useEffect } from "react";
import logo from "../../../Images/logo_green_transparent.png";
import logo_oposity from "../../../Images/logo_green_oposity.png";
import Loader from "../../../Components/UI/Loader";
// import { useLocation } from "react-router-dom";
import axiosInstance from "../../../Utils/API";
import {
  GET_COMPANY_INFORMATION_DATA,
  GET_BANK_LIST,
} from "../../../Utils/ApiGlobal";
import {
  InvoicSubject,
  AuthorizedSignatory,
  OURBANKDETAILS,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
} from "../../../Utils/Constants";
import Select from "react-select";

import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import moment from "moment";
import { useLocation } from "react-router-dom";
import CompanyInvoicePrint from "./CompanyInvoicePrint";

const CompanyInvoiceView = (props) => {
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");

  useEffect(() => {
    // console.log(location, "print");
  }, [props]);

  useEffect(() => {
    _FetchProfileAPI();
  }, []);

  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(response?.data?.data?.secondaryPhoneNo);
        setProfileData(data);
        const arr = response?.data?.data?.secondaryPhoneNo;
        const noEmptyStrings = arr.filter((str) => str !== '');
        setsecondaryPhoneNo(noEmptyStrings);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };
  // const [showHeader, setShowHeader] = useState(true);
  // const [bookingData, setBookingData] = useState([]);
  // const [invoiceTo, setInvoiceTo] = useState();
  // const [otherName, setOtherName] = useState();
  // const [otherAdd, setOtherAdd] = useState();
  // const [otherGST, setOtherGST] = useState();
  // const [open, setOpen] = useState(true);
  // const [sentInvoice, setSentInvoice] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  // const [profileData, setProfileData] = useState({});
  // const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [bankBranch, setBankBranch] = useState({
    bank: "",
    branch: "",
    accNo: "",
    ifsc: "",
  });
  const [Dropdown, setDropdown] = useState(false);
  const [optionsBank, setOptions] = useState({});
  // const ref = useRef(null);
  // const location = useLocation();
  // var loadingDate = location?.data?.data?.lrDate;
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };
  // Access Bank Details
  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        setOptions(options);
        // console.log(options);
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ minWidth: "1240px" }}>
          {location?.data?.booking && (
            <div>
              <div
                className="pageStyle m-5 block border-double border-4 min-h-screen border-cyan-200 align-top text-center bg-center bg-no-repeat  bg-[length:65%]"
                style={{
                  backgroundImage: "url(" + logo_oposity + ")",
                }}
              >
                <div className="mt-2">
                  <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
                    <h5>Tax Invoice</h5>
                  </div>
                  {/* Logo and Title Start */}
                  <div className="m-2 items-center space-x-3 flex flex-row">
                    <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                      <div className="flex flex-row justify-between">
                        <div className="w-80 ml-2">
                          <img src={logo} alt="Transporter" />
                        </div>
                        <div className=" flex flex-col ">
                          <span className="text-9xl text-primary-kirtiroadways font-extrabold ">
                            {profileData.tagLine}
                          </span>
                          <span className="text-4xl font-serif text-black">
                            {profileData.subTagLine}
                          </span>
                        </div>
                      </div>
                      {/* Address Start */}
                      <div className="rounded-b-3xl rounded-t-lg font-bold h-16  p-2 w-full  bg-gradient-to-r from-cyan-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                        {profileData.address !== " " && (
                          <p className="flex  text-md justify-center flex-row items-start">
                            <span className="text-md w-5">{address_svg}</span>{" "}
                            <span className="text-md ml-1">
                              {profileData.address}
                            </span>
                          </p>
                        )}
                        <div className="  flex space-x-4 justify-center flex-row items-start ">
                          {(secondaryPhoneNo.length !== 0) && (
                            <div className="flex space-x-1 text-md justify-center flex-row items-center ">
                              <span>{call_svg} </span>
                              <span className="flex flex-row">
                                {secondaryPhoneNo.map((number, i) => (
                                  <span
                                    key={number}
                                    className="text-sm font-semibold tracking-wider flex flex-row"
                                  >
                                    {(i ? ", " : "") + number}
                                  </span>
                                ))}
                              </span>
                            </div>
                          )}
                          {profileData.email !== " " && (
                            <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                              <span>{email_svg} </span>
                              <span>{profileData.email}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Address End */}
                    </div>
                  </div>
                  {/* Logo and Title End */}
                </div>
                {/* Invoice  No and details*/}
                <div className="p-2 flex font-sans flex-row justify-between">
                  <div className="flex flex-col text-left w-1/2">
                    <div className="font-bold uppercase text-md	py-1 mb-0 px-5 w-36 text-white rounded-lg bg-primary-kirtiroadways">
                      Invoice to
                    </div>
                    <div>
                      <h1 className="text-left uppercase font-sans text-xl subpixel-antialiased tracking-wide mt-0 font-bold">
                        {location?.name?.companyName.toUpperCase()}
                      </h1>
                    </div>
                    <div className="text-xs text-left">
                      <div className="flex  flex-row space-x-1 mt-1">
                        <p>Address :</p>
                        <p className="w-1/2 ">
                          {location?.address?.companyAddress.toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="text-xs mt-1">
                      <div className="flex flex-row space-x-1 text-left">
                        <p className="">GST :</p>
                        <p className="w-1/2">
                          {location?.gst?.companyGST.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Right side */}
                  <div className="flex  flex-col text-right justify-end items-end">
                    <span className="font-bold uppercase text-md	py-1 mb-0 px-5  text-white rounded-lg bg-primary-kirtiroadways">
                      OUR Details
                    </span>
                    <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
                      <p className="flex flex-col">
                        <span>Service : {profileData.service}</span>
                        <span>Pan No. : {profileData.panNo}</span>
                        <span>State Code : {profileData.stateCode}</span>
                      </p>
                    </div>

                    <div className=" uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 border-primary-kirtiroadways">
                      <div className="flex flex-row text-sm text-right space-x-12">
                        <span className="flex flex-col ">
                          <p className="text-right min-w-1/2">
                            Invoice No : --
                          </p>
                          <p className="text-right min-w-1/2">
                            Date : {Today_Date}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Table start*/}
                <div
                  style={{ height: "450px" }}
                  className="p-2 w-full border-t-0 border-neutral-900"
                >
                  <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-sm font-sans">
                    {/* Header */}
                    <thead style={{ maxHeight: "5px" }} className="mb-2">
                      <tr className=" border-neutral-900 text-white ">
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900 "
                        >
                          Loading
                          <br />
                          Date
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          From
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          To
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-slate-800	 border-neutral-900"
                        >
                          Fright
                        </th>
                        {/* <th className="bg-primary-kirtiroadways border-neutral-900">
                          Advance
                        </th> */}
                        {/* <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900"
                        >
                          Balance
                        </th> */}
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900"
                        >
                          Hamali
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900"
                        >
                          Detention
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900"
                        >
                          Extra
                          <br />
                          Charge
                        </th>
                        {/* <th
                          style={{ maxHeight: "5px" }}
                          className="bg-primary-kirtiroadways border-neutral-900 pr-2"
                        >
                          Total
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="text-xs font-bold align-top text-center bg-bottom bg-no-repeat bg-[length:80%_90%]">
                      {/* Rows */}
                      {location?.data?.booking.map((data, i) => (
                        <tr
                          height="20px"
                          className="border-neutral-900 "
                          key={i}
                        >
                          <td
                            height="20px"
                            className="border-r-2 border-gray-300 items-center  w-28"
                          >
                            <span className="mt-2">
                              {moment(data?.loadingDate).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          <td
                            height="20px"
                            className=" border-r-2 border-gray-300 w-32"
                          >
                            <span className="mt-2">
                              {data?.from.toUpperCase()}
                            </span>
                          </td>
                          <td
                            height="20px"
                            className=" border-r-2 border-gray-300 w-32"
                          >
                            <span className="mt-2">
                              {data?.to.toUpperCase()}
                            </span>
                          </td>
                          <td
                            height="20px"
                            className="border-r-2 border-gray-300 w-28"
                          >
                            <span className="ml-2 mt-2 text-center">
                              {data?.freigth ? data.freigth : 0}
                            </span>
                          </td>
                          {/* <td
                            height="20px"
                            className="border-r-2 border-gray-300 w-28"
                          >
                            <span className="mt-2 text-center">
                              {data?.advance ? data?.advance : 0}
                            </span>
                          </td> */}
                          {/* <td
                            height="20px"
                            className="w-28"
                          >
                            <span className="mt-2 text-center">
                              {data?.balance ? data?.balance : 0}
                            </span>
                          </td> */}
                          <td
                            height="20px"
                            className="border-r-2 border-gray-300 w-28"
                          >
                            <span className="mt-2 text-center">
                              {data?.hamali ? data?.hamali : 0}
                            </span>
                          </td>
                          <td
                            height="20px"
                            className="border-r-2 border-gray-300 w-28"
                          >
                            <span className="mt-2 text-center">
                              {data?.detention ? data?.detention : 0}
                            </span>
                          </td>
                          <td
                            height="20px"
                            className="border-r-2 border-gray-300 w-28 mr-2"
                          >
                            <span className="mt-2 text-center">
                              {data?.extraCharge ? data?.extraCharge : 0}
                            </span>
                          </td>
                          {/* <td height="20px" className="w-28 mr-2">
                            <span className="mt-2 text-center">
                              {data?.total ? data?.total : 0}
                            </span>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Total Calculation */}
                {/* <table className="w-full font-bold font-sans">
                  <thead>
                    <tr height="10px" className="border-neutral-900 ">
                      <td className="w-24 font-semibold text-left text-xs pl-2"></td>
                      <td className="w-24 font-semibold text-left text-xs pl-2"></td>
                      <td className="w-24 font-semibold text-left text-xs pl-2"></td>
                      <td className="w-24 font-semibold text-left text-xs pl-2"></td>
                      <td className="w-24 font-semibold text-left text-xs pl-2"></td>
                      <td height="5px" className=" w-28  align-top">
                        <h1 className="font-bold  text-center text-white bg-primary-kirtiroadways py-2 ">
                          Total
                        </h1>
                      </td>
                      <td height="10px" className="w-28  align-top">
                        <h1 className=" text-center   text-white bg-primary-kirtiroadways py-2">
                          {location?.data?.totalBookingAmount
                            ? location?.data?.totalBookingAmount
                            : 0}
                        </h1>
                      </td>
                      <td height="10px" className="w-28  align-top">
                        <h1 className=" text-center  text-white bg-primary-kirtiroadways py-2">
                          {location?.data?.totalHamali
                            ? location?.data?.totalHamali
                            : 0}
                        </h1>
                      </td>
                      <td height="10px" className="w-28  align-top">
                        <h1 className=" text-center  text-white bg-primary-kirtiroadways py-2">
                          {location?.data?.totalDetention
                            ? location?.data?.totalDetention
                            : 0}
                        </h1>
                      </td>
                      <td height="10px" className="w-28  align-top">
                        <h1 className="  text-center text-white bg-primary-kirtiroadways py-2">
                          {location?.data?.totalExtraCharge
                            ? location?.data?.totalExtraCharge
                            : 0}
                        </h1>
                      </td>
                      <td height="10px" className="w-28  align-top">
                        <h1 className=" text-center text-white bg-primary-kirtiroadways py-2">
                          {location?.data?.grandTotal
                            ? location?.data?.grandTotal
                            : 0}
                        </h1>
                      </td>
                    </tr>
                  </thead>
                </table> */}

                {/* Bannk Detail Start */}
                <div className="flex flex-row justify-between px-2 font-semibold text-left text-sm font-sans">
                  <div className="mt-4 ">
                    <div className="flex flex-col space-x-1 ">
                      <h5 className="px-5 py-2 mb-2 text-md tracking-wide w-48 rounded-lg uppercase bg-primary-kirtiroadways text-white">
                        {OURBANKDETAILS}
                      </h5>
                      {optionsBank.length > 0 && (
                        <div
                          style={{
                            minWidth: "350px",
                            maxWidth: "350px",
                            marginLeft: "inherit",
                          }}
                          className="flex flex-col  text-left w-1/2 z-80"
                        >
                          <Select
                            options={optionsBank.map((el) => ({
                              label: el.bankName,
                              value: el.accountNo,
                              branch: el.branch,
                              billAdd: el.billToAddress,
                              ifscCode: el.ifscCode,
                            }))}
                            onChange={(e) => {
                              setBankBranch({
                                ...bankBranch,
                                bank: e.label,
                                branch: e.branch,
                                accNo: e.value,
                                ifsc: e.ifscCode,
                              });
                              // console.log(e);
                              setDropdown(true);
                            }}
                            placeholder="Bank Branch"
                            className="z-80 "
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 5,
                              colors: {
                                ...theme.colors,
                                primary25: "#8cd7de",
                                primary: "#179eaa",
                              },
                            })}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row space-x-1 mt-2">
                      <div className="space-y-1">
                        <h5>Bank Name</h5>
                        <h5>Branch</h5>
                        <h5>A/c. No</h5>
                        <h5>IFSC Code</h5>
                      </div>
                      {Dropdown === false ? (
                        <div className="space-y-1">
                          <h5>: State Bank of India</h5>
                          <h5>: Prahlad Nagar Ahmedabad</h5>
                          <h5>: 005167983000351</h5>
                          <h5>: SBIT0CKMNSB (FIFT CHARACTER IS ZERO)</h5>
                        </div>
                      ) : (
                        <div className="space-y-1">
                          <h5>: {bankBranch.bank}</h5>
                          <h5>: {bankBranch.branch}</h5>
                          <h5>: {bankBranch.accNo}</h5>
                          <h5>: {bankBranch.ifsc}</h5>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {location?.data?.booking.map((data, i) => ( */}
                  <div className="flex flex-row  justify-end mt-4 border-t-2 w-64 border-primary-kirtiroadways">
                    <div className="space-y-1  mt-2  text-right w-32">
                      <h5 className="pr-2">TOTAL :</h5>
                      <div className="h-16">
                        {location?.data?.totalHamali > 0 && (
                          <h5 className=" pr-2">Hamali :</h5>
                        )}
                        {location?.data?.totalDetention > 0 && (
                          <h5 className="pr-2">Detention :</h5>
                        )}
                        {location?.data?.totalExtraCharge > 0 && (
                          <h5 className=" pr-2">Extra Charge :</h5>
                        )}
                      </div>
                      <h5 className="pr-2 text-sm	 px-2 py-2 tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase bg-primary-kirtiroadways text-white">
                        Grand Total
                      </h5>
                    </div>
                    <div className="space-y-1 mt-2 text-right w-28">
                      <h5>
                        &#8377;{" "}
                        {location?.data?.totalBookingAmount
                          ? location?.data?.totalBookingAmount
                          : 0}
                      </h5>
                      <div className="h-16">
                        {location?.data?.totalHamali > 0 && (
                          <h5>
                            &#8377;{" "}
                            {location?.data?.totalHamali
                              ? location?.data?.totalHamali
                              : 0}
                          </h5>
                        )}
                        {location?.data?.totalDetention > 0 && (
                          <h5>
                            &#8377;{" "}
                            {location?.data?.totalDetention
                              ? location?.data?.totalDetention
                              : 0}
                          </h5>
                        )}
                        {location?.data?.totalExtraCharge > 0 && (
                          <h5>
                            {" "}
                            &#8377;{" "}
                            {location?.data?.totalExtraCharge
                              ? location?.data?.totalExtraCharge
                              : 0}
                          </h5>
                        )}
                      </div>
                      <h5 className="border-l-2 text-md whitespace-nowrap rounded-r-lg border-white pr-2 px-5 py-2 tracking-wide b-r-2 w-full uppercase bg-primary-kirtiroadways text-white">
                        &#8377;{" "}
                        {location?.data?.grandTotal
                          ? location?.data?.grandTotal
                          : 0}
                      </h5>
                    </div>
                  </div>
                  {/* ))} */}
                </div>
                {/* Bannk Detail End */}

                {/* Extra Information */}
                <div className="flex flex-col justify-start items-start font-sans border-t-2 mx-2    border-primary-kirtiroadways mt-2">
                  <p>Amount Chargeable (in words)</p>
                  <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                    {/* Four Thousand Four Hundred Fifty */}
                    {location?.data?.grandTotalInWords} ONLY
                  </p>
                </div>
                {/* terms and condition */}
                <div className="flex pb-4 flex-row justify-between m-2 font-sans">
                  <div className="text-left flex flex-col">
                    <p className="border-black border-b-4 w-36 pb-1">
                      Terms & Conditions
                    </p>
                    <p>{InvoicSubject}</p>
                  </div>
                  <div className="text-left text-sm">E. & O.E.</div>
                  <div className="text-left text-sm ">FOR,</div>
                  <div className="text-lg font-bold text-right ">
                    {profileData.tagLine}
                  </div>
                </div>

                <div className="flex border-black border-b-2 flex-row mt-6 justify-start items-start mb-2 font-sans mx-2">
                  <div className="w-2/3 text-left flex flex-col font-sans">
                    <p>{PaymentbypayeeCchequeDrafs}</p>
                  </div>
                  <div className="w-1/2 text-sm text-center ">
                    {profileData.tagLine}
                  </div>
                  <div className="w-1/2 text-right  font-bold ">
                    <h5>{AuthorizedSignatory}</h5>
                  </div>
                </div>

                {/* printing */}
                <h2 className="text-sm mt-0 mb-0 text-center font-bold bg-primary-kirtiroadways rounded-md p-2 text-white font-sans">
                  {computerGeneratedInvoice}
                </h2>
              </div>
            </div>
          )}
          {/* printing */}
          <div>
            <CompanyInvoicePrint
              data={location?.data}
              note={location?.note?.note}
              name={location?.name?.companyName}
              address={location?.address?.companyAddress}
              gst={location?.gst?.companyGST}
              profileData={profileData}
              secondaryPhoneNo={secondaryPhoneNo}
              bankBranch={bankBranch}
              Dropdown={Dropdown}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyInvoiceView;
