// --- Company API PATHS --- //
export const GET_COMPANY_TABLE = "/api/Company/ListCompany";
export const ADD_COMPANY = "/api/Company/AddCompany";
export const UPDATE_COMPANY = "/api/Company/UpdateCompany";
export const DELETE_COMPANY = "/api/Company/DeleteCompany";
export const COMPANY_INVOICE = "/api/Booking/ViewBookingByCompanyId";
export const COMPANY_PRINT_INVOICE ="/api/Booking/PrintCompanyInvoiceDetails"

// --- Booking API PATHS --- //
export const GETVIEWBOOKINGBYID = "/api/Booking/ViewBookingById"
export const GET_BOOKING_TABLE = "/api/Booking/ViewBooking"
export const ADD_BOOKING = "/api/Booking/AddBooking";
export const UPDATE_BOOKING = "/api/Booking/UpdateBooking"
export const DELETE_BOOKING = "/api/Booking/DeleteBooking"

// --- Booking Buillty API PATHS --- //
export const GET_BUILTY_TABLE = "/api/Booking/ListBillty";
export const ADD_BUILTY = "/api/Booking/AddBillty";
export const UPDATE_BUILTY = "/api/Booking/UpdateBillty"
export const UPDATE_BUILTY_AVAILABLE = "/api/Booking/UpdateIsBilltyReceive"

// --- Transporter API PATHS --- //
export const GET_TRANSPORTER_DATA = "/api/Booking/TransporterCommissionList";
export const GET_TRANSPORTERS_LIST = "/api/Booking/ListTransporterDetails";
export const POST_TRANSPORTER = "/api/Booking/AddTransporterDetails";
export const POST_UPDATE_TRANSPORTER = "/api/Booking/UpdateTransporterDetails";
export const DELETE_TRANSPORTER = "/api/Booking/DeleteTransporterDetails";
export const TRANSPORTER_INVOICE = "/api/Booking/ViewTransporterCommission";
export const TRANSPORTER_PRINT_INVOICE ="/api/Booking/PrintTranporterInvoice"
export const ADD_TRANSPORTER_PAYMENT ="/api/Booking/UpdateTransporterPaymentDetails"

// --- Commission API PATHS --- //
export const GET_COMMISSION_TABLE = "/api/Booking/TransporterCommissionList";
export const ADD_COMMISSION_DATA = "/api/Booking/AddTransporterCommission";
export const UPDATE_COMMISSION_DATA = "/api/Booking/UpdateTransporterCommission";
export const DELETE_COMMISSION = "/api/Booking/DeleteTransporterCommission"

// --- Commission LCV API PATHS --- //
export const GET_LCV_DATA = "/api/Booking/ListLcv";

// --- Vehicle Owner API PATHS --- //
export const GET_VEHICLE_OWNERBYID ="/api/Vehicle/ViewVehicleByOwner"
export const GET_VEHICLE_OWNER ="/api/Vehicle/ListVehicleOwners"
export const ADD_VEHICLE_OWNER = "/api/Vehicle/AddOwner";
export const UPDATE_VEHICLE_OWNER = "/api/Vehicle/UpdateOwner"
export const DELETE_VEHICLE_OWNER = "/api/Vehicle/DeleteOwner"
export const VEHICLE_COMMISSION_INVOICE ="/api/Booking/VehicleOwnerInvoice"
export const VEHICLE_COMMISSION_INVOICE_PRINT ="/api/Booking/PrintInvoiceDetails"
export const ADD_VEHICLEOWNER_PAYMENT ="/api/Booking/UpdatePaymentStatus"

// --- Vehicle Owners' Vehicles API PATHS --- //
export const ADD_VEHICLE = "/api/Vehicle/AddVehicle";
export const GET_VEHICLE = "/api/Vehicle/ListVehicles";
export const UPDATE_VEHICLE = "/api/Vehicle/UpdateVehicle";
export const DELETE_VEHICLE_DETAILS = "/api/Vehicle/DeleteVehicle"

// --- Ledger API PATHS --- //
export const GET_VEHICLEOWNER_LEDGER = "/api/Booking/ListVehicleOwnerPayment";
export const GET_COMPANY_LEDGER = "/api/Ledger/ListLedger";
export const ADD_LEDGER = "/api/Ledger/AddPayment";
export const GET_LEDGER_BOOKINGID = "/api/Ledger/ViewLedgerByBooking";
export const GET_LEDGER_TRANSPORTER = "/api/Booking/ListTransporterLedger";
export const GET_KIRTIRL_LEDGER ="/api/Ledger/ListKirtiRoadLinesLedger";

// --- Kirtiroadline profile Company Details API PATHS --- //
export const GET_COMPANY_INFORMATION_DATA = "/api/User/ViewUserProfile"
export const UPDATE_PROFILE_DETAILS = "/api/User/UpdateUserProfile"

// --- Kirtiroadline profile Bank Details API PATHS --- //
export const GET_BANK_LIST ="/api/User/ListBankAccount"
export const BANK_INFORMATION_LIST = "/api/User/ListBankAccount"
export const ADD_BANK_INFORMATION = "/api/User/AddBankAccount"
export const UPDATE_BANK_INFORMATION = "/api/User/UpdateBankAccount"
export const REMOVE_BANK_INFORMATION = "/api/User/DeleteBankAccount"

// --- DashBoard API PATHS --- //
export const GET_DASHBOARD_LIST = "/api/User/YearGraph"