import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_TABLE } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";

const animatedComponents = makeAnimated();

export default function CompanyInformation({
  handleOnChange,
  name,
  defaultValue,
}) {
  const [optionsComapny, setOptions] = useState({});
  useEffect(() => {
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        // console.log(response?.data?.data?.company);
        if (response?.data?.data?.company.length > 0) {
          const options = response?.data?.data?.company.map(
            ({ name, gst,companyId, shipToAddress, billToAddress }, index) => ({
              name,
              gst,
              shipToAddress,
              billToAddress,
              companyId
            })
          );
          setOptions(options);
        }
      });
  }, []);

  return (
    <>
      {optionsComapny.length > 0 ? (
        <div style={{ width: "310px" }}>
          <Select
            components={animatedComponents}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            options={optionsComapny.map((el) => ({
              label: el.name,
              value: el.gst,
              add: el.shipToAddress,
              billAdd: el.billToAddress,
              type: { name },
              CompanyId:el.companyId
            }))}
            name={name}
            onChange={handleOnChange}
            placeholder="Select Vehicle Owner"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: "#8cd7de",
                primary: "#179eaa",
              },
            })}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
