import React, { useState } from "react";
import axios from "axios";
import { APIURL } from "../../Utils/Constants";
import { useHistory } from "react-router-dom";
import logo_transparent from "../../Images/logo_green_transparent.png";
import Notification from "../../Components/UI/Notification";
import Loader from "../../Components/UI/Loader";

const baseURL = `${APIURL}/api/User/Login`;

const Login = () => {
  let history = useHistory();
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Form Submit Click Event
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("Click Submit")
    await axios
      .post(baseURL, {
        UserName: e.target.email.value,
        Password: e.target.password.value,
        Type: "Phone",
      })
      .then((response) => {
        localStorage.setItem("LoggedIn", true);
        // console.log(response);
        setIsLoading(false);
        if (response.data.data) {
          // console.log("Login SucessFull");
          history.push("/dashboard");
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("userId", response.data.data.userId);
          // let data = localStorage.getItem('token')
          // console.log(data)
        } else if (response.data.error) {
          // console.log("Number and Password Not Correct")
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error!",
            content: response.data.error.message,
          });
          setNotif(true);
          return;
        } else {
          console.log("Somthing Went Wrong");
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error!",
            content: "Login failed, please try again!",
          });
          setNotif(true);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("got Error");
        return;
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {/* Notification or Alert */}
          <Notification
            show={notif}
            setShow={setNotif}
            {...notifOpts}
            supportContent={true}
          ></Notification>
          <div className="min-h-screen flex items-center justify-center bg-black py-12 px-4 sm:px-6 lg:px-8">
            <div className="md:w-1/2 w-full space-y-8  border-2 border-gray-500 bg-white rounded-lg shadow-md md:p-16 p-4">
              <div>
                <button to="/">
                  <img
                    className="mx-auto h-auto w-40"
                    src={logo_transparent}
                    alt="transporter"
                  />
                </button>
                <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-800">
                  Sign in to your account
                </h2>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                  {/* <div className="w-full flex justify-center">
                        <RecaptchaWrapper setToken={setRe} theme="dark" />
                    </div> */}
                  {/* <input type="hidden" name="remember" value="true" /> */}
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Number
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="phone"
                        autoComplete="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-kirtiroadways focus:border-primary-kirtiroadways focus:z-10 sm:text-sm"
                        placeholder="Mobile Number *"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-kirtiroadways focus:border-primary-kirtiroadways focus:z-10 sm:text-sm"
                        placeholder="Password *"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-kirtiroadways rbox hover:bg-primary-kirtiroadways-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-kirtiroadways"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-white group-hover:text-gray-300"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      Sign in
                    </button>
                  </div>
                </form>
                <div className="w-full flex justify-center">
                  <div className="text-sm">
                    <button className="mt-4 font-medium text-gray-500 hover:text-gray-400">
                      Forgot your password?
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
